import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { FormControlComponent } from '@shared/base/form-control/form-control.component';
import { JwtAuthService } from '@shared/services/auth/jwt-auth.service';
import * as _moment from 'moment';

const moment = _moment;

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: DateInputComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: DateInputComponent
    }
  ]
})
export class DateInputComponent extends FormControlComponent implements OnInit{

  float: boolean = false;

  minDate: Date = new Date();

  maxDate: Date = new Date();

  @Input() disabled: boolean = false;
  
  @Input() placeholder: string;

  @Input() remark: string;

  @Input() error: string = "";

  holiday: string = "";

  constructor(private jwtAuthService: JwtAuthService) { 
    super()
  }

  ngOnInit(): void {
    this.jwtAuthService.getConfig().subscribe(configs => {
      this.holiday = (configs.find(config => config.attribute === "PUBLIC_HOLIDAY") || {value: ""}).value

      //start from n + 2 working day
      this.minDate.setDate(this.minDate.getDate() + 2)

      //start from n + 14 working day
      this.maxDate.setDate(this.maxDate.getDate() + 14)

      //start to n + 18 working day
      // let extraDays = 0
      // for(let i = 0; i < 18; i++) {
      //   const day = this.maxDate.getDay();
      //   const fullDate = `${this.maxDate.getFullYear()}-${("0" + (this.maxDate.getMonth() + 1)).slice(-2)}-${("0" + this.maxDate.getDate()).slice(-2)}`
      //   if(this.holiday.includes(fullDate) || day === 0 || day === 6) {
      //     //Add one date when it is holiday
      //     extraDays++
      //   }
      //   this.maxDate.setDate(this.maxDate.getDate() + 1)
      // }
      // while(extraDays != 0) {
      //   const currentExtra = extraDays
      //   extraDays = 0
      //   for(let i = 0; i < currentExtra; i++) {
      //     const day = this.maxDate.getDay();
      //     const fullDate = `${this.maxDate.getFullYear()}-${("0" + (this.maxDate.getMonth() + 1)).slice(-2)}-${("0" + this.maxDate.getDate()).slice(-2)}`
      //     if(this.holiday.includes(fullDate) || day === 0 || day === 6) {
      //       //Add one date when it is holiday
      //       extraDays++
      //     }
      //     this.maxDate.setDate(this.maxDate.getDate() + 1)
      //   }
      // }
    })
  }

  dateFilter = (d: Date): boolean => {
    const day = d.getDay();
    const fullDate = `${d.getFullYear()}-${("0" + (d.getMonth() + 1)).slice(-2)}-${("0" + d.getDate()).slice(-2)}`
    return !this.holiday.includes(fullDate) && day !== 0 && day !== 6;
  }

  addFormatEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.value = moment(event.value).format("YYYY-MM-DD")
    this.onChange(this.value)
  }

}
