import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

export class BaseComponent {
    validateAllFormFields(formGroup: FormGroup) {       //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched()
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
    }

    checkError(form: FormGroup, value, errMsg): string {
        const control = form.get(value)
        if(control.status === "INVALID" && control.touched) {
          return errMsg
        }
        return ""
    }

    checkPasswords(group: FormGroup) {
      if (!group.get('confirmPassword').dirty) {
        return null;
      }
  
      let pass = group.get('password').value;
      let confirmPass = group.get('confirmPassword').value;
  
      if (pass === confirmPass) {
        return null;
      }
  
      group.get('confirmPassword').setErrors({ notMatchPassword: true });
  
      return { notMatchPassword: true }
    }
  
}