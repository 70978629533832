<div tabindex="1" (blur)="blur()" (click)="toggle()" class="select-box" [ngClass]="{'open': open && !disabled, 'disabled': disabled}">
    <label [ngClass]="{'float': value !== ''}">{{placeholder}}</label>
    <span>
        {{selected}}
    </span>
    <div class="arrow"></div>
    <div *ngIf="open && !disabled" class="dropdown">
        <div class="option" (click)="select(option.value)" *ngFor="let option of options">
            {{option.text}}
        </div> 
    </div>
</div>
<span class="error" *ngIf="error">{{error}}</span>