import { Address } from './address.model';
import { CartItem } from "./cart-item.model";

export class ShoppingCart {
    id: string;
    key: string;
    merchantId: string;
    shippingMethod: string;
    shippingFee: string;
    currency: string;
    subTotal: string;
    discount: string;
    grandTotal: string;
    couponCode: string;
    isCouponActive: boolean;
    isCouponInvalid: boolean = false;
    status: string;
    shippingAddress: Address;
    billingAddress: Address;
    cartItems: CartItem[];
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    updatedBy: string;
    remark: string;
    points: string;

    // temp use
    regionCode: string;
    contact: string;
    email: string;
    firstname: string;
    middlename: string;
    lastname: string;

    constructor() {
        this.isCouponActive = false;
        this.shippingAddress = new Address("SHIPPING", null);
        this.billingAddress = new Address("BILLING", null);
        this.cartItems = [];
    }
}
