<div>
    <app-section-title title="Order Review"></app-section-title>
    <div class="wrapper">
        <div>
            <h5>DELIVERY METHOD</h5>
            <div>{{order.shippingMethod}}</div>
            <div>{{order.packingMethod}}</div>
            <div>With Shopping Bag: {{order.shoppingBag}}</div>
        </div>
        <div fxLayout="row wrap">
            <div class="address-container">
                <h5>BILLING ADDRESS</h5>
                <div>{{order.billingAddress.firstname}} {{order.billingAddress.lastname}}</div>
                <div>{{order.billingAddress.address | getAddressLineByLineNumber:1}}</div>
                <div>{{order.billingAddress.address | getAddressLineByLineNumber:2}}</div>
                <div>{{order.billingAddress.city}}</div>
                <div>{{order.billingAddress.country}}</div>
                <div>{{order.contact}}</div>
                <div>{{order.email}}</div>
            </div>
            <div class="address-container">
                <h5>SHIPPING ADDRESS</h5>
                <div>{{order.shippingAddress.firstname}} {{order.shippingAddress.lastname}}</div>
                <div>{{order.shippingAddress.address | getAddressLineByLineNumber:1}}</div>
                <div>{{order.shippingAddress.address | getAddressLineByLineNumber:2}}</div>
                <div>{{order.shippingAddress.city}}</div>
                <div>{{order.shippingAddress.country}}</div>
                <div>{{order.contact}}</div>
                <div>{{order.email}}</div>
            </div>
        </div>
    </div>
    <app-section-title title="Order Items"></app-section-title>
    <div class="wrapper">
        <div *ngFor="let item of order.salesOrderItems" fxLayout="row wrap" class="order-item-container">
            <img [src]="item.productSku.images | getImagePathByType:'THUMBNAIL'" alt="">
            <div>
                <h5>{{item.productSku.productSkuEavs | getValueByAttribute:'name':'value'}}</h5>
                <div>SKU: {{item.productSku.sku}}</div>
                <div>Color: {{item.productSku.productSkuEavs | getValueByAttribute:'displayColor':'value'}}</div>
                <div *ngIf="item.productSku.productSkuEavs | getValueByAttribute:'fit':'value'">Fit: {{item.productSku.productSkuEavs | getValueByAttribute:'fit':'value'}}</div>
                <div>Size: {{item.productSku.productSkuEavs | getValueByAttribute:'displaySize':'value'}} {{item.productSku.productSkuEavs | getValueByAttribute:'inseam':'value'}}</div>
                <div>Qty: {{item.quantity}}</div>
                <div *ngIf="isSalesPrice(item.productSku)" class="text-align-end deleted">$ {{item.productSku.price | number}}</div>
                <div class="text-align-end price">$ {{(item.productSku | getProductSalesPriceIfAny) | number}}</div>
            </div>
        </div>
    </div>
</div>