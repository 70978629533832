import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormControlComponent } from '@shared/base/form-control/form-control.component';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TextAreaComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: TextAreaComponent,
    },
  ],
})
export class TextAreaComponent extends FormControlComponent implements OnInit {
  float: boolean = false;

  @Input() disabled: boolean = false;

  @Input() type: string;

  @Input() placeholder: string;

  @Input() remark: string;

  @Input() remark2: string;

  @Input() error: string = '';

  constructor() {
    super();
  }

  ngOnInit(): void {}
}
