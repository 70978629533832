import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@shared/base/base.component';
import { PasswordValidator } from '@shared/base/validators';
import { Customer } from '@shared/models/customer.model';
import { ShoppingCart } from '@shared/models/shopping-cart.model';
import { AuthService } from '@shared/services/auth.service';
import { JwtAuthService } from '@shared/services/auth/jwt-auth.service';
import { CartService } from '@shared/services/cart.service';
import { CustomCookieService } from '@shared/services/cookie.service';
import { Product } from '@shared/models/product.model';
import { CommonService } from '@shared/services/common.service';
import { CartItem } from '@shared/models/cart-item.model';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss']
})
export class ShoppingCartComponent extends BaseComponent implements OnInit {

  loginForm: FormGroup;

  loginError: string = "";

  cart: ShoppingCart = new ShoppingCart()

  recentlyViewProducts: Product[] = []

  constructor(private router: Router, 
    private route: ActivatedRoute, 
    private cartService: CartService, 
    private jwtAuthService: JwtAuthService, 
    private _formBuilder: FormBuilder, 
    private authService: AuthService,
    private cookieService: CustomCookieService,
    private commonService: CommonService) { 
    super()
  }

  isLoggedIn: Boolean = false

  ngOnInit(): void {
    this.loginForm = this.createLoginForm()

    //Set recent viewed product
    this.recentlyViewProducts = this.cookieService.getRecentViewedProducts()

    this.route.data .subscribe((data: { cart: ShoppingCart }) => {
      this.cart = data.cart
      this.cartService.setCart(this.cart)
    })

    this.cartService.shoppingCart$.subscribe(cart => {
      this.cart = cart
    })

    this.isLoggedIn = this.jwtAuthService.isLoggedIn()
  }

  createLoginForm(): FormGroup {
    return this._formBuilder.group({
      email: ["", Validators.required],
      password: ["", [Validators.required, PasswordValidator()]],
      rememberMe: [false],
    });
  }

  submitLoginForm() {
    this.loginError = ""
    if(this.loginForm.valid) {
      const data = this.loginForm.getRawValue()
      const request = {email: data.email, password: data.password}
      this.authService.login(request).then((data: Customer) => {
        if(data.status !== 'ACTIVATED') {
          return
        }
        this.jwtAuthService.setUserAndToken(data.token, data, true);
        this.cartService.mergeGuestCart().subscribe(shoppingCart => {
          console.log('mergecart::success');
          this.isLoggedIn = this.jwtAuthService.isLoggedIn()
          this.router.navigate(['/cart']);
        })
      }).catch(err => {
        if(err.status === 400) {
          this.loginError = "Wrong email or password"
        }
      })
    }else {
      this.validateAllFormFields(this.loginForm)
    }
  }

  gotoCheckout() {
    this.router.navigate(["/checkout"])
  }
  
  gotoHome() {
    this.router.navigate(["/home"])
  }

  gotoAuth() {
    this.router.navigate(["/auth"])
  }

  openForgotPassword() {
    this.commonService.openForgotPasswordPopup()
  }

  isNotAvaible(cart: ShoppingCart): boolean {
    if (
      cart.cartItems.some(item => item.productSku.status !== 'AVAILABLE')
      ||
      cart.cartItems.some(item => item.productSku.stock - item.productSku.minStockLevel < item.quantity)
      ) {
      console.log("status::entered");
      return true;
    }
    return false;
  }
}
