import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductSku } from '@shared/models/product-sku.model';
import { Product } from '@shared/models/product.model';

@Component({
  selector: 'app-product-slider-item',
  templateUrl: './product-slider-item.component.html',
  styleUrls: ['./product-slider-item.component.scss']
})
export class ProductSliderItemComponent implements OnInit {

  @Input() product: Product

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  gotoDetail(id, catId) {
    //Remove url params
    this.router.navigate([`/products/${catId}/detail/${id}`])
  }

  isSalesPrice(productSku: ProductSku): boolean {
    if(productSku.salesFrom && productSku.salesTo && productSku.salesPrice) {
      let today = new Date().getTime() / 1000
      if(today > productSku.salesFrom && today < productSku.salesTo) {
        return true;
      }else {
        return false;
      }
    }else {
      return false;
    }
  }

}
