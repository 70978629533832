import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { map, take } from 'rxjs/operators';

import { ProductSkuFilterRequest } from '@shared/models/request/product-sku-filter-request';

import { HttpService } from '@shared/services/http.service';
import {SessionStoreService} from "@shared/services/session-store.service";

const GET_ALL_CONFIG = 'admin/configuration';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService{

  configurationsSource = new BehaviorSubject([])
  configurations = this.configurationsSource.asObservable()

  constructor(private httpService: HttpService, private ss: SessionStoreService) { }

  // getAllConfig(){

  //   return this.httpService.get(GET_ALL_CONFIG, {}).subscribe((rsp: any) => {
  //     console.log('configuration response', rsp)
  //     this.configurationsSource.next(rsp)
  //   });
  // }

  getAllConfig(): Observable<any> {
    return this.httpService.get(GET_ALL_CONFIG, {})
  }

}
