import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Banner from '@shared/models/Banner';
import { Product } from '@shared/models/product.model';
import Slider from '@shared/models/Slider';
import { JwtAuthService } from '@shared/services/auth/jwt-auth.service';
import { CustomCookieService } from '@shared/services/cookie.service';
import { SlidersService } from '@shared/services/sliders.service';
import { CommonService } from '@shared/services/common.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  menNewArrivalsProducts: Product[] = []

  womenNewArrivalsProducts: Product[] = []

  newArrivalSelected: string = "Men"

  slider1: Slider
  slider1M: Slider
  slider2A: Slider
  slider2B: Slider
  slider3: Slider
  slider4: Slider
  slider5A: Slider
  slider5B: Slider
  slider6: Slider
  slider6CurrentBanner: Banner = new Banner()
  slider7: Slider
  slider7CurrentBanner: Banner = new Banner()

  homeTopSliderConfig = {
    "arrows": true,
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "infinite": true,
    "speed": 500,
    "autoplay":true,
    "autoplaySpeed": 5000,
    "dots": false,
    "prevArrow": "<img style='height: 40px; width: auto; left: 5px; z-index: 50; padding-bottom: 40px' class='a-left control-c prev slick-prev' src='assets/images/icon/home-left-arrow.svg'>",
    "nextArrow": "<img style='height: 40px; width: auto; right: 5px; z-index: 50; padding-bottom: 40px' class='a-right control-c next slick-next' src='assets/images/icon/home-right-arrow.svg'>"
  }

  newArrivalProductSlideConfig = {
    "arrows": true,
    "slidesToShow": 3,
    "slidesToScroll": 1,
    "infinite": false,
    "speed": 500,
    "autoplay":false,
    "dots": false,
    "responsive": [
      {
        "breakpoint": 991,
        "settings": {
          "slidesToShow": 2,
          "slidesToScroll": 1,
        }
      },
      {
        "breakpoint": 600,
        "settings": {
          "slidesToShow": 1,
          "slidesToScroll": 1,
        }
      },
    ]
  }

  slickInit(e) {
  }

  breakpoint(e) {
  }

  afterChange(e) {
  }

  beforeChange(e) {
  }

  isLoggedIn: Boolean = false
  
  constructor(private router: Router, private sliderService: SlidersService, private jwtAuthService: JwtAuthService, private cookieService: CustomCookieService, private commonService: CommonService) { }

  ngOnInit(): void {

    this.jwtAuthService.getSlider().subscribe(sliders => {
      this.extractSliders(sliders)
    })

    this.jwtAuthService.sliders$.subscribe(sliders => {
      this.extractSliders(sliders)
    })

    this.jwtAuthService.getMenNewArrival().subscribe(products => {
      this.menNewArrivalsProducts = products
    })

    this.jwtAuthService.menNewArrivals$.subscribe(products => {
      this.menNewArrivalsProducts = products
    })

    this.jwtAuthService.getWomenNewArrival().subscribe(products => {
      this.womenNewArrivalsProducts = products
    })

    this.jwtAuthService.womenNewArrivals$.subscribe(products => {
      this.womenNewArrivalsProducts = products
    })

    this.isLoggedIn = this.jwtAuthService.isLoggedIn();
    if (!this.isLoggedIn) {
      this.commonService.openCouponPopup();
    }
  }

  extractSliders(sliders: Slider[]) {
    sliders = sliders || []
    this.slider1 = sliders.filter(slider => slider.name === "HOME SLIDER 1").length > 0 ? sliders.filter(slider => slider.name === "HOME SLIDER 1")[0] : new Slider()
    this.slider1.banners = this.slider1.banners.sort((a, b) => (a.seq > b.seq) ? -1 : 1)
    this.slider1M = sliders.filter(slider => slider.name === "HOME SLIDER 1 MOBILE").length > 0 ? sliders.filter(slider => slider.name === "HOME SLIDER 1 MOBILE")[0] : new Slider()
    this.slider1M.banners = this.slider1M.banners.sort((a, b) => (a.seq > b.seq) ? -1 : 1)
    this.slider2A = sliders.filter(slider => slider.name === "HOME SLIDER 2A").length > 0 ? sliders.filter(slider => slider.name === "HOME SLIDER 2A")[0] : new Slider()
    this.slider2B = sliders.filter(slider => slider.name === "HOME SLIDER 2B").length > 0 ? sliders.filter(slider => slider.name === "HOME SLIDER 2B")[0] : new Slider()
    this.slider3 = sliders.filter(slider => slider.name === "HOME SLIDER 3").length > 0 ? sliders.filter(slider => slider.name === "HOME SLIDER 3")[0] : new Slider()
    this.slider4 = sliders.filter(slider => slider.name === "HOME SLIDER 4").length > 0 ? sliders.filter(slider => slider.name === "HOME SLIDER 4")[0] : new Slider()
    this.slider5A = sliders.filter(slider => slider.name === "HOME SLIDER 5A").length > 0 ? sliders.filter(slider => slider.name === "HOME SLIDER 5A")[0] : new Slider()
    this.slider5B = sliders.filter(slider => slider.name === "HOME SLIDER 5B").length > 0 ? sliders.filter(slider => slider.name === "HOME SLIDER 5B")[0] : new Slider()
    this.slider6 = sliders.filter(slider => slider.name === "HOME SLIDER 6").length > 0 ? sliders.filter(slider => slider.name === "HOME SLIDER 6")[0] : new Slider()
    this.slider7 = sliders.filter(slider => slider.name === "HOME SLIDER 7").length > 0 ? sliders.filter(slider => slider.name === "HOME SLIDER 7")[0] : new Slider()
  }

  selectNewArrival(cat: string) {
    this.newArrivalSelected = cat
  }

  goToNewArrival() {
    this.router.navigateByUrl(`/products/${this.newArrivalSelected === 'Men' ? 270 : 263}`)
  }

  setSlider6Banner(banner: Banner) {
    this.slider6CurrentBanner = banner
  }

  setSlider7Banner(banner: Banner) {
    this.slider7CurrentBanner = banner
  }

  isSliderValid(slider: Slider): boolean {
    return slider && slider.banners && slider.banners.length > 0
  }

}
