import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import Category from '@shared/models/category.model';
import ProductFilterOptionResponse from '@shared/models/response/product-filter-option-response.ts'
import { ProductsService } from '@shared/services/products.service';
import { sortAlphaNum, sortSize } from '@shared/helpers/util'

@Component({
  selector: 'app-product-filter',
  templateUrl: './product-filter.component.html',
  styleUrls: ['./product-filter.component.scss']
})
export class ProductFilterComponent implements OnInit, OnChanges {

  @Input() category: Category

  @Input() parentCategory: Category

  @Output() onChangeSize = new EventEmitter<any>()

  @Output() onChangeColor = new EventEmitter<any>()

  @Output() onChangeCategory = new EventEmitter<any>()

  @Output() onClickClearFilter = new EventEmitter<any>()

  @Output() onApplyMobileFilter = new EventEmitter<any>()

  filters = [
    "PRODUCT CATEGORY",
    "SIZE",
    "COLOR",
  ]

  sortBy = [
    "New Arrivals",
    "Most Popular",
    "Price Low to High",
    "Price High to Low"
  ]

  subCategory: Category[] = []

  constructor(private productService: ProductsService, private route: ActivatedRoute) { }

  sizeList: string[] = []

  sizeMap: Map<string, boolean> = new Map()

  colorList: string[] = []
  
  colorMap: Map<string, boolean> = new Map()

  categoryIdList: number[] = []

  categoryIdMap: Map<number, boolean> = new Map()

  selectedSort: string = "Sort By"

  ngOnChangeReady: boolean = false;

  //Url params
  categoryIdParam: number;
  isSale: boolean = false;

  //mobile
  mobileCategoryList: number[] = []
  mobileSizeList: string[] = []
  mobileColorList: string[] = []
  mobileDropDownManualClose: boolean = false;

  ngOnInit(): void {
    
    this.route.paramMap.subscribe(params => {
      this.categoryIdParam = +params.get('categoryId')
    })
    
    //Get url params for filter option
    this.route.queryParamMap.subscribe(params => {
      //Check is sale
      if(params.getAll("isSale").length > 0) {
        this.isSale = true;
      }else {
        this.isSale = false;
      }

      const categoryIds = params.getAll("categoryIds").map(id => parseInt(id))
      const colors = params.getAll("colors")
      const sizes = params.getAll("sizes")
      this.categoryIdMap.clear()
      this.colorMap.clear()
      this.sizeMap.clear()
      if(categoryIds.length > 0) {
        this.categoryIdList = categoryIds
        //Save which category is selected
        categoryIds.forEach(id => {
          this.categoryIdMap.set(id, true)
        })
        this.getFilterOption()
      }else {
        if(!this.ngOnChangeReady) {
          this.ngOnChangeReady = true
        }else {
          this.categoryIdList = [this.categoryIdParam]
          this.getFilterOption()
        }
      }

      sizes.forEach(size => {
        this.sizeMap.set(size, true)
      })

      colors.forEach(color => {
        this.colorMap.set(color, true)
      })
      
    })
  }

  ngOnChanges(): void {
    //Category Id
    this.categoryIdParam = +this.route.snapshot.params.categoryId

    //Reset sorting
    this.selectedSort = "Sort By"

    //Close mobile dropdown
    this.mobileDropDownManualClose = true;
    setTimeout(() => {
      this.mobileDropDownManualClose = false;
    }, 1000)

    //Push all children
    this.subCategory = []
    this.category.children.forEach(cat => {
      if(cat.children && cat.children.length > 0) {
        this.subCategory = this.subCategory.concat(cat.children)
      }else {
        this.subCategory.push(cat)
      }
    })

    //Get Filter Option
    this.categoryIdList = []
    this.categoryIdList.push(this.categoryIdParam)

    this.getFilterOption()
  }

  onClickCategory(value) {
    if(this.categoryIdList.findIndex(id => id === this.categoryIdParam) >= 0) {
      //Remove the parent category when filtering category
      this.categoryIdList = this.categoryIdList.filter(id => id !== this.categoryIdParam)
    }
    if(this.categoryIdList.findIndex(id => id === value) < 0) {
      this.categoryIdList.push(value)
    }else {
      this.categoryIdList = this.categoryIdList.filter(id => id !== value)
      if(this.categoryIdList.length <= 0) {
        this.categoryIdList.push(this.categoryIdParam)
      }
    }
    this.onChangeCategory.emit(this.categoryIdList)
  }

  onClickSize(value: string) {
    this.onChangeSize.emit(value)
  }

  onClickColor(value) {
    this.onChangeColor.emit(value)
  }

  onClearFilter() {
    this.onClickClearFilter.emit()
  }

  getFilterOption() {
    this.productService.getProductFilterOption(this.categoryIdList).subscribe((rsp: ProductFilterOptionResponse) => {
      this.sizeList = rsp.sizeOptions.filter(val => val !== '').sort(sortSize);
      this.colorList = rsp.colorOptions.filter(color => color.length > 0).sort((a, b) => b < a ? 1 : -1)
    })
  }

  mapColorToCode(color: string) {
    color = color.toUpperCase()
    switch(color) {
        case "WHITE":
            return "#ffffff"
        case "BLACK":
            return "#000000"
        case "BLUE":
            return "#191ef4"
        case "YELLOW":
            return "#fffe5a"      
        case "GREEN":
            return "#3f7f1f"
        case "GREY":
            return "#808080"
        case "NAVY":
            return "#030a7d"
        case "PINK":
            return "#f1c2cc"
        case "PURPLE":
            return "#75197c"
        case "RED":
            return "#e03021"
        case "TAN":
            return "#cfb48e"
        case "ORANGE":
            return "#eda640"
        case "GOLD":
            return "#b98850"
        case "BROWN":
            return "#482a20"
        case "SLIVER":
            return "#dfdfe1"            
    }
  }

  //Mobile
  selectSorting(value) {
    this.selectedSort = value
  }

  onMobileChangeCategory(value) {
    this.mobileCategoryList = value
  }

  onMobileChangeSize(value) {
    this.mobileSizeList = value
  }

  onMobileChangeColor(value) {
    this.mobileColorList = value
  }

  mobileApplyFilter() {
    this.mobileDropDownManualClose = true;
    this.onApplyMobileFilter.emit({
      categoryList: this.mobileCategoryList,
      sizeList: this.mobileSizeList,
      colorList: this.mobileColorList,
      sort: this.selectedSort
    })
    setTimeout(() => {
      this.mobileDropDownManualClose = false;
    }, 1000)
  }

}
