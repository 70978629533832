export class ProductImage {

    id: number;
    merchantId: string;
    name: string;
    type: string;
    domain: string;
    path: string;
    seq: number;

    constructor(image?) {
        image= image || {};
        this.id=image.id || 0;
        this.merchantId=image.merchantId || '';
        this.name=image.name || '';
        this.type=image.type || '';
        this.domain=image.domain || '';
        this.path=image.path || '';
        this.seq=image.seq || 0;
    }

}
