<div class="need-help-block">
    <h5>NEED HELP?</h5>
    <div>7 DAYS A WEEK, 9AM - 5PM, ET</div>
    <div fxLayout="row" fxLayoutAlign="start center">
        <img src="assets/images/icon/phone-icon.svg" alt="">
        <div>1.800.274.1815 (Within the U.S.)</div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
        <img src="assets/images/icon/phone-icon.svg" alt="">
        <div>001.860.741.4800 (International)</div>
    </div>
</div>