import { getSizeValue } from '@shared/models/product-sku.model'

var reA = /[^a-zA-Z]/g;
var reN = /[^0-9]/g;

export const sortAlphaNum = (a, b) => {
  var aA = a.replace(reA, "");
  var bA = b.replace(reA, "");
  if (aA === bA) {
    var aN = parseInt(a.replace(reN, ""), 10);
    var bN = parseInt(b.replace(reN, ""), 10);
    return aN === bN ? 0 : aN > bN ? 1 : -1;
  } else {
    return getSizeValue(bA) > getSizeValue(aA) ? 1 : -1;
  }
}

export const sortSize = (a, b): number => {
    //Hard Code for one size and regular
    if(String(a).toLowerCase() === "one size" || String(a).toLowerCase() === "regular") {
        return 1;
    }

    if(String(b).toLowerCase() === "one size" || String(b).toLowerCase() === "regular") {
        return -1;
    }
    
    if(!isNaN(+a) && !isNaN(+b)) {
        //Both are number
        return parseFloat(b) < parseFloat(a) ? 1 : -1
    }else if(isNaN(+a) && isNaN(+b)) {
        var aNum= a.substring(0,2);
        var bNum=b.substring(0,2);
        //Both are partial number, e.g '46 REG'
        if(!isNaN(+aNum) && !isNaN(+bNum)) {
            return parseFloat(b) < parseFloat(a) ? 1 : -1
        }
        //Both are not number
        return getSizeValue(b) < getSizeValue(a) ? 1 : -1
    }else {
        if(!isNaN(+a)) {
            //a is number, b is not
            return 1
        }else {
            //b is number, a is not
            return -1
        }
    }
}

export const transformMenuNameToNumber = (name: string): number => {
    switch(name.toLowerCase()) {
        case "co":
        case "m":
            return 0
        case "ap":
        case "s":    
            return 1    
        case "sa":
        case "w":
            return 2
        default:
            return 0        
    }
}

export const getRandomInt = (max): number => {
    return Math.floor(Math.random() * max);
}

export const getRandomItem = (arr): any => {
    // get random index value
    const randomIndex = Math.floor(Math.random() * arr.length);

    // get random item
    const item = arr[randomIndex];
    return item;
}