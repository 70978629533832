import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpHeaders,
  HttpErrorResponse
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { environment } from "@env/environment";
import { catchError, map } from "rxjs/operators";
import { CommonService } from '@shared/services/common.service';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  constructor(
    private appService: CommonService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = localStorage.getItem("token");
    const tokenHeader = token ? { Authorization: `Bearer ${token}` } : {};

    const originalHeaders = {};
    req.headers
      .keys()
      .forEach((key) => (originalHeaders[key] = req.headers.get(key)));

    const headers: HttpHeaders = new HttpHeaders({
      // "Content-type": "application/json",
      "Merchant-Id": environment.merchantId,
      "Accept-Language": 'en-US',
      ...tokenHeader,
      ...originalHeaders
    });

    const newReq = req.clone({
      url: `${req.url}`,
      headers
    })
    
    this.appService.startLoading(req);

    return next.handle(newReq).pipe(
      map((e: HttpEvent<any>) => {
        if (e.type != 0) {
          console.log('response', e)
          this.appService.stopLoading(req);
          this.appService.closeProductDetailPopup();
          this.appService.closeSideNavBar();
          this.appService.closeSearchPopup()
        }
        return e;
      }),
      catchError((err: HttpErrorResponse) => {
        this.appService.stopLoading(req);
        this.appService.closeProductDetailPopup();
        this.appService.closeSideNavBar();
        this.appService.closeSearchPopup()

        // TODO: pass error msg to show in messageDialog
        // const dialogConfig = new MatDialogConfig();
        // dialogConfig.disableClose = true;
        // dialogConfig.autoFocus = true;

        // this.dialog.open(MessageDialogComponent, dialogConfig);
        if(err.status === 500 && !err.url.includes('/payment')) {
          alert(err.error);
        }

        return throwError(err);
      })
    );
  }
}
