<div class="pagination-container">
    <div fxLayout="row" fxLayoutAlign="center" class="button">
        <div (click)="click(currentPage - 1)" class="prev" [ngClass]="{'disabled': currentPage === 0}">
            Prev
        </div>
        <div (click)="click(currentPage + 1)" class="next" [ngClass]="{'disabled': (currentPage + 1) === totalPage}">
            Next
        </div>
    </div>
    <div class="page">
        <span>{{currentPage + 1}}</span> of <span>{{totalPage}}</span>
    </div>
</div>