import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-size-and-fit-guides',
  templateUrl: './size-and-fit-guides.component.html',
  styleUrls: ['./size-and-fit-guides.component.scss']
})
export class SizeAndFitGuidesComponent implements OnInit {

  openMen: boolean = false

  openWomen: boolean = false

  selectedCategory: string = ""

  sizeChartImageList = [
    {
      name: "Dress Shirts",
      value: "BB96-Size Chart_1_Dress Shirts.jpg",
      type: "M"
    },
    {
      name: "Sport Shirts",
      value: "BB96-Size Chart_2_Sport Shirts.jpg",
      type: "M"
    },
    {
      name: "Blazers, Sport Coats & Vests",
      value: "BB96-Size Chart_3_Blazers, Sport Coats & Vests.jpg",
      type: "M"
    },
    {
      name: "Suits & Tuxedo",
      value: "BB96-Size Chart_4_Suits and Tuxedo.jpg",
      type: "M"
    },
    {
      name: "Outerwear",
      value: "BB96-Size Chart_5_Outerwear.jpg",
      type: "M"
    },
    {
      name: "Sweaters",
      value: "BB96-Size Chart_6_Sweaters.jpg",
      type: "M"
    },
    {
      name: "Dress Trousers",
      value: "BB96-Size Chart_7_Dress Trousers.jpg",
      type: "M"
    },
    {
      name: "Casual Pants",
      value: "BB96-Size Chart_8_Casual Pants.jpg",
      type: "M"
    },
    {
      name: "Polos",
      value: "BB96-Size Chart_9_Polos.jpg",
      type: "M"
    },
    {
      name: "Others & Accessories",
      value: "BB96-Size Chart_10_Others & Accessories.jpg",
      type: "M"
    },
    {
      name: "Apparel",
      value: "BB96-Size Chart_1_ WOMEN APPAREL.jpg",
      type: "F"
    },
    {
      name: "Shoes & Accessories",
      value: "BB96-Size Chart_2_ WOMEN Others & Accessories.jpg",
      type: "F"
    }
  ]

  sizeChartMobileImageList = [
    {
      name: "Dress Shirts",
      value: "Men_Mobile Size Chart 1_Dress Shirts@2x-100.jpg",
      type: "M"
    },
    {
      name: "Sport Shirts",
      value: "Men_Mobile Size Chart 2_Sport Shirts@2x-100.jpg",
      type: "M"
    },
    {
      name: "Blazers, Sport Coats & Vests",
      value: "Men_Mobile Size Chart 3__Blazers@2x-100.jpg",
      type: "M"
    },
    {
      name: "Suits & Tuxedo",
      value: "Men_Mobile Size Chart 4_Suits @2x-100.jpg",
      type: "M"
    },
    {
      name: "Outerwear",
      value: "Men_Mobile Size Chart 5_Outerwear@2x-100.jpg",
      type: "M"
    },
    {
      name: "Sweaters",
      value: "Men_Mobile Size Chart 6_Sweaters@2x-100.jpg",
      type: "M"
    },
    {
      name: "Dress Trousers",
      value: "Men_Mobile Size Chart 7_Dress Trousers@2x-100.jpg",
      type: "M"
    },
    {
      name: "Casual Pants",
      value: "Men_Mobile Size Chart 8_Casual Pants@2x-100.jpg",
      type: "M"
    },
    {
      name: "Polos",
      value: "Men_Mobile Size Chart 9_Polos@2x-100.jpg",
      type: "M"
    },
    {
      name: "Others & Accessories",
      value: "Men_Mobile Size Chart 10_Others & Accessories@2x-100.jpg",
      type: "M"
    },
    {
      name: "Apparel",
      value: "WOMEN - Mobile_Apparel.jpg",
      type: "F"
    },
    {
      name: "Shoes & Accessories",
      value: "WOMEN - MobileOthers & AccessoriesOthers & Accessories WOMEN@2x-100.jpg",
      type: "F"
    }
  ]

  menList = {
      name: "Mens",
      value: "",
      children: [
        {
          name: "Dress Shirts",
          value: "Dress Shirts",
        },
        {
          name: "Sport Shirts",
          value: "Sport Shirts"
        },
        {
          name: "Blazers, Sport Coats & Vests",
          value: "Blazers, Sport Coats & Vests"
        },
        {
          name: "Suits & Tuxedo",
          value: "Suits & Tuxedo"
        },
        {
          name: "Outerwear",
          value: "Outerwear"
        },
        {
          name: "Sweaters",
          value: "Sweaters"
        },
        {
          name: "Dress Trousers",
          value: "Dress Trousers"
        },
        {
          name: "Casual Pants",
          value: "Casual Pants"
        },
        {
          name: "Polos",
          value: "Polos"
        },
        {
          name: "Others & Accessories",
          value: "Others & Accessories"
        }
      ]
  }

  womenList = {
    name: "Womens",
    value: "",
    children: [
        {
          name: "Apparel",
          value: "Apparel"
        },
        {
          name: "Shoes & Accessories",
          value: "Shoes & Accessories"
        }
    ]
  }
  

  constructor() { }

  ngOnInit(): void {
    window.scrollTo(0,0)
  }

  filterByCategory(list: any[]): any[] {
    if(this.selectedCategory) {
      return list.filter(item => item.name === this.selectedCategory)
    }else {
      return list
    }
  }

  selectCategory(category) {
    this.selectedCategory = category.value

    //Close Dropdown
    this.openMen = false
    this.openWomen = false
  }

}
