<div class="coupon-container">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            APPLY PROMO CODE
        </div>
        <div [ngStyle]="{'background-position': expand ? '0 -22px' : ''}" (click)="toggle()" class="expander"></div>
    </div>
    <div [ngStyle]="{'margin-top': expand ? '0.5rem' : ''}">
        <app-input-with-button (onClick)="applyCouponCode($event)" [lock]="lock" [disabled]="cart && cart.isCouponActive" [(value)]="cart.couponCode" *ngIf="expand" type="text" placeholder="Enter Here"></app-input-with-button>
        <div class="error" *ngIf="cart && cart.isCouponInvalid">Promo code is not applicable</div>
        <div class="rule" *ngFor="let rule of rules">{{rule.ruleEavs | getValueByAttribute:'label':'value'}}</div>
        <div class="rule" *ngIf="cart && cart.remark">{{cart.remark}}</div>
    </div>
</div>