<mat-expansion-panel class="category mat-elevation-z0" hideToggle="true" (opened)="open = true" (closed)="open = false">
    <mat-expansion-panel-header class="category-header">
        <mat-panel-title class="category-title">
            <a [href]="'/#/products/' + category.id + (isSale ? '?isSale=1' : '')">{{category.categoryEavs | getValueByAttribute:'displayName':'value'}}<span *ngIf="showSaleSuffix">'S SALE</span></a>
        </mat-panel-title>
        <mat-panel-description class="category-description">
            <img *ngIf="!open; else subtractImage" src="assets/images/icon/add-icon.svg" alt="">
            <ng-template #subtractImage>
                <img src="assets/images/icon/subtract-icon.svg" alt="">
            </ng-template>
        </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="sub-category" *ngFor="let category of filter(category.children)">
        <div class="sub-category-active" *ngIf="category.status === 'ACTIVE'">
            <a [href]="'/#/products/' + category.id + (isSale ? '?isSale=1' : '')">
                {{category.categoryEavs | getValueByAttribute:'displayName':'value'}}
            </a>
        </div>   
    </div>
</mat-expansion-panel>