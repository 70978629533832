<div class="blog-item-container">
    <div class="item-blog-item">
        <a class="item-blog-img" [routerLink]="'/blog/'+post.id" [innerHTML]="post.postEavs | getValueByAttribute : 'thumbnail' : 'value'"></a>
    </div>
    <div class="item-blog-item">
        <div class="blog-create-date">{{getDateString()}}</div>
    </div>
    <div class="item-blog-item">
        <a class="blog-title" [routerLink]="'/blog/'+post.id">{{post.postEavs | getValueByAttribute : 'title' : 'value'}}</a>
    </div>
</div>
