<div *ngIf="!user; else loginBlock" [ngStyle]="{'top': top}" class="account-dropdown">
    <div class="top">
        <div>
            <a href="/#/auth">Sign in / Create an Account</a>
        </div>
        <div>
            <a href="/#/auth">Order History</a>
        </div>
    </div>
    <!-- <div class="bottom">
        <div>Register & Save</div>
        <div>$10 off your next purchase of $50 or more.</div>
        <div>
            <a href="/#/auth">Register Now</a>
        </div>
    </div> -->
</div>

<ng-template #loginBlock>
    <div [ngStyle]="{'top': top}" class="account-dropdown">
        <div class="top">
            <div class="hello-block">
                <a>HELLO, {{user.firstname}}</a>
            </div>
            <div>
                <a href="/#/member-portal">Account</a>
            </div>
            <div>
                <a href="/#/member-portal/order-history">Order History</a>
            </div>
            <div>
                <a (click)="logout()">Sign Out</a>
            </div>
        </div>
    </div>
</ng-template>
