import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-quantity-button',
  templateUrl: './quantity-button.component.html',
  styleUrls: ['./quantity-button.component.scss']
})
export class QuantityButtonComponent implements OnInit {

  @Input() quantity: number = 1;

  @Output() onChange = new EventEmitter<any>()

  constructor() { }

  ngOnInit(): void {
  }

  add() {
    this.quantity++;
    this.onChange.emit(this.quantity)
  }

  substract() {
    if(this.quantity > 1) {
      this.quantity--
      this.onChange.emit(this.quantity)
    }
  }

}
