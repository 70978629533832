import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-input-with-button',
  templateUrl: './input-with-button.component.html',
  styleUrls: ['./input-with-button.component.scss']
})
export class InputWithButtonComponent implements OnInit {

  @Input() type: string;

  @Input() placeholder: string;

  @Input() value: string = ""

  @Input() disabled: boolean = false

  @Input() lock: boolean = false

  @Output() onClick = new EventEmitter<any>()

  constructor() { }

  ngOnInit(): void {
  }

  apply() {
    if(!this.lock) {
      this.onClick.emit(this.value)
    }
  }

  remove() {
    if(!this.lock) {
      this.onClick.emit("")
    }
  }

}
