import { Pipe, PipeTransform } from "@angular/core";
import { ProductSku } from "@shared/models/product-sku.model"

@Pipe({
  name: "getAddressLineByLineNumber",
  pure: false
})
export class GetAddressLineByLineNumber implements PipeTransform {
  transform(address: string, line: number): string {
    if(address) {
      let arr = address.split(" | ")
      if(line === 1) {
        return arr[0]
      }else {
        if(arr.length > 1) {
          return arr[1]
        }else {
          return ""
        }
      }
    }else {
      return ""
    }
  }
}