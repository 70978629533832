import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DisplaySizeService {
  constructor() {}

  isMobileBS = new BehaviorSubject(false);
  isMobile$ = this.isMobileBS.asObservable()
}
