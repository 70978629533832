<ngx-slick-carousel class="carousel desktop" #slickModal="slick-carousel" [config]="slideConfig" (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">
    <div fxLayout="column" fxLayoutAlign="center center" ngxSlickItem *ngFor="let banner of desktopSlider.banners; index as i" class="slide" style="outline: none; box-shadow: none;">
        <div class="content" [ngSwitch]="banner.type">
            <a [ngClass]="{'fixHeight': fixHeightMobile && currentSlide === i}" *ngSwitchCase="'IMAGE'" style="outline: none; box-shadow: none" href="{{ banner.url }}">
                <img [ngStyle]="{'height': fixHeightMobile ? fixHeightMobile : '', 'width': fixHeightMobile ? 'auto' : '100%'}" src="{{ banner.domain + banner.path }}" />
            </a>

            <video *ngSwitchCase="'VIDEO'" width="320" height="240" playsinline loop muted autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true">
                <source [src]="banner.domain + banner.path" type="video/mp4">
                Your browser does not support the video tag.
            </video>

            <app-youtube *ngSwitchCase="'YOUTUBE'" [url]="banner.url"></app-youtube>
        </div>
        <ng-template [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{$implicit: banner}"></ng-template>
    </div>
</ngx-slick-carousel>

<ngx-slick-carousel class="carousel mobile" #slickModal="slick-carousel" [config]="slideConfig" (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">
    <div fxLayout="column" fxLayoutAlign="center center" ngxSlickItem *ngFor="let banner of mobileSlider.banners; index as i" class="slide" style="outline: none; box-shadow: none;">
        <div class="content" [ngSwitch]="banner.type">
            <a [ngClass]="{'fixHeight': fixHeightMobile && currentSlide === i}" *ngSwitchCase="'IMAGE'" style="outline: none; box-shadow: none" href="{{ banner.url }}">
                <img [ngStyle]="{'height': fixHeightMobile ? fixHeightMobile : '', 'width': fixHeightMobile ? 'auto' : '100%'}" src="{{ banner.domain + banner.path }}" />
            </a>
    
            <video *ngSwitchCase="'VIDEO'" width="320" height="240" playsinline loop muted autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true">
                <source [src]="banner.domain + banner.path" type="video/mp4">
                Your browser does not support the video tag.
            </video>
    
            <app-youtube *ngSwitchCase="'YOUTUBE'" [url]="banner.url"></app-youtube>
        </div>
        <ng-template [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{$implicit: banner}"></ng-template>
    </div>
</ngx-slick-carousel>
