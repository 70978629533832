import { Injectable } from "@angular/core";
import { Product } from '@shared/models/product.model';

const ACCEPT = "ACCEPT";
const RECENT_VIEWED_PRODUCTS = "RECENT VIEWED PRODUCTS"

@Injectable({
  providedIn: "root",
})
export class CustomCookieService {

  constructor() {
  }

  // setCookie(name: string, value: any, expires: number) {
  //   this.cookieService.set(name, value, {expires: expires, sameSite: 'Lax'})
  // }

  setLocalStorage(name: string, value: any) {
    localStorage.setItem(name, value)
  }

  acceptCookie() {
    this.setLocalStorage(ACCEPT, "1")
  }

  isCookieAccepted(): boolean {
    return localStorage.getItem(ACCEPT) === "1"
  }

  addRecentViewedProducts(product: Product) {
    if(this.isCookieAccepted()) {
      let products: Product[] = []
      const storage = localStorage.getItem(RECENT_VIEWED_PRODUCTS)
      if(storage) {
        products = JSON.parse(storage)
      }
      //Check product is already exist
      const index = products.findIndex(prod => prod.id === product.id)
      if(index >= 0) {
        products.splice(index, 1)
      }
      products.push(product)
      //FIRST IN FIRST OUT (MAX: 10)
      if(products.length > 10) {
        products = products.slice(1, products.length)
      }
      this.setLocalStorage(RECENT_VIEWED_PRODUCTS, JSON.stringify(products))
    }
  }

  getRecentViewedProducts(): Product[] {
    const storage = localStorage.getItem(RECENT_VIEWED_PRODUCTS)
    if(storage) {
      const products: Product[] = JSON.parse(storage)
      return products.reverse()
    }
    return []
  }

}
