import { Address } from './address.model';
import { CartItem } from "./cart-item.model";

export class Order {
    id: string;
    key: string;
    merchantId: string;
    cart: [];
    orderNo: string;
    currency: string;
    grandTotal: string;
    shippingFee: string;
    shippingMethod: string;
    email: string;
    regionCode: string;
    contact: string;
    firstname: string;
    middlename: string;
    lastname: string;
    shippingAddress: Address;
    billingAddress: Address;
    paymentMethodReferenceNo: string;
    couponCode: string;
    discount: string;
    subTotal: string;
    isCouponActive: boolean;
    status: string;
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    updatedBy: string;
    cartItems?: CartItem[];
    salesOrderItems: [];
    orderDate: any;
    points: string;
    packingMethod: string;
    shoppingBag: string;
    /**
     * Constructor
     *
     * @param saleOrder
     */
    constructor(saleOrder?) {
        saleOrder = saleOrder || {};
        this.id = saleOrder.id || '';
        this.key = saleOrder.key || '';
        this.currency = saleOrder.currency || '';
        this.grandTotal = saleOrder.grandTotal || '';
        this.shippingFee = saleOrder.shippingFee || '';
        this.discount = saleOrder.discount || '';
        this.orderNo = saleOrder.orderNo || '';
        this.shippingMethod = saleOrder.shippingMethod || '';
        this.paymentMethodReferenceNo = saleOrder.paymentMethodReferenceNo || '';
        this.subTotal = saleOrder.subTotal || '';
        this.couponCode = saleOrder.couponCode || '';
        this.isCouponActive = saleOrder.isCouponActive || false;
        this.status = saleOrder.status || '';
        this.createdAt = saleOrder.createdAt || '';
        this.createdBy = saleOrder.createdBy || '';
        this.updatedAt = saleOrder.updatedAt || '';
        this.updatedBy = saleOrder.updatedBy || '';
        this.shippingAddress = saleOrder.shippingAddress || new Address("SHIPPING");
        this.billingAddress = saleOrder.billingAddress || new Address("BILLING");
        this.salesOrderItems = saleOrder.salesOrderItems || [];
        this.orderDate = saleOrder.orderDate || '';
        this.points = saleOrder.points || '';
        this.packingMethod = saleOrder.packingMethod || '';
        this.shoppingBag = saleOrder.shoppingBag || '';
    }
    calDiscount(){
        this.discount = ((+this.subTotal) - (+this.grandTotal) + (+this.shippingFee)) + "";
    }
}
