import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-sorting-select',
  templateUrl: './sorting-select.component.html',
  styleUrls: ['./sorting-select.component.scss']
})
export class SortingSelectComponent implements OnInit {

  open: boolean = false;

  @Input() options: any = [""]

  @Input() selected: string;

  @Input() manualClose: boolean;

  @Output() onChange = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if(this.manualClose) {
      this.open = false;
    }
  }

  toggle() {
    this.open = !this.open
  }

  blur() {
    this.open = false
  }

  select(value: string) {
    this.onChange.emit(value)
  }

}
