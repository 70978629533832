import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '@shared/services/common.service';
import { ProductsService } from '@shared/services/products.service';
import { Product } from '@shared/models/product.model';
import { CartItem } from '@shared/models/cart-item.model';

@Component({
  selector: 'app-product-detail-popup',
  templateUrl: './product-detail-popup.component.html',
  styleUrls: ['./product-detail-popup.component.scss']
})
export class ProductDetailPopupComponent implements OnInit {

  show = true

  product: Product

  item: CartItem = null;

  constructor(private commonService: CommonService, private router: Router, private productService: ProductsService) { }

  ngOnInit(): void {
    this.commonService.showProductDetailPopup.subscribe((show) => {
      this.show = show
    })

    this.productService.productToPopUp$.subscribe(product => {
      this.product = product
    })

    this.productService.cartItemIsUpdating$.subscribe(cartItem => {
      this.item = cartItem
    })
  }

  gotoDetail() {
    this.close()
    this.router.navigate([`${this.router.url.split("?")[0]}/detail/${this.product.id}`])
  }

  close() {
    this.productService.setCartItemToUpdate(null)
    this.commonService.closeProductDetailPopup();
  }

}
