<div *ngIf="show" class="forgot-password-wrapper" fxLayout="row" fxLayoutAlign="center center">
    <div class="forgot-password-container">
        <h4>Forgot your password?</h4>
        <form [formGroup]="forgotPasswordForm" class="content">
            <div class="remark">Please enter your email address to retrieve your password.</div>
            <app-input type="text" placeholder="Email Address *" formControlName="email" [error]="checkError(forgotPasswordForm, 'email', 'A VALID EMAIL IS REQUIRED.')"></app-input>
            <div fxLayout="row" fxLayoutAlign="end center" class="button">
                <div (click)="close()" class="cancel">cancel</div>
                <div class="send">
                    <app-button text="send" color="var(--thm-text-hover-color)" (onClick)="submitForgotPasswordForm()"></app-button>
                </div>
            </div>
        </form>
        <img (click)="close()" src="assets/images/icon/cross-icon-dark.png" alt="">
    </div>
</div>
<app-simple-popup id="reset-password" title="Thank you">
    <div class="popup-content">
        <div>We'll send you an email momentarily<br>with instruction<br>for resetting your password.</div>
        <div (click)="closePopup()">close</div>
    </div>
</app-simple-popup>
