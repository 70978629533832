import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { map, take } from 'rxjs/operators';

import { ProductSkuFilterRequest } from '@shared/models/request/product-sku-filter-request';

import { HttpService } from '@shared/services/http.service';
import {SessionStoreService} from "@shared/services/session-store.service";
import Category from '@shared/models/category.model';
import { NavigationService } from './navigation.service';
import { Product } from '@shared/models/product.model';
import { getEavByAttribute, ProductSku } from '@shared/models/product-sku.model';
import ProductFilterOptionRequest from '@shared/models/request/product-filter-option-request';
import { CartItem } from '@shared/models/cart-item.model';
import { sortSize } from '@shared/helpers/util';

const GET_PRODUCT_LIST = 'catalog/product';
const GET_PRODUCT_LIST_FILTER = 'catalog/product/filter';
const GET_PRODUCT_SKU_LIST = 'catalog/product-sku';
const GET_PRODUCT_CATEGORY = 'catalog/category';
const GET_PRODUCT_FILTER_OPTION = 'catalog/product/filter/options'
const GET_PRODUCT_BY_SKU = 'catalog/product-sku/'
const GET_PRODUCT_LIST_SEARCH_FILTER = 'catalog/product/filter/search';
const GET_CROSS_SALE_PRODUCT = '/cross-sales'

export class ProductAttributeMap {
  value: string
  imgUrl?: string
  children: ProductAttributeMap[]

  constructor(obj?) {
    obj = obj || {}
    this.value = obj.value || ""
    this.imgUrl = obj.imageUrl || ""
    this.children = obj.children || []
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProductsService{

  fullFitList: string[] = []
  fullSizeList: string[] = []
  fullInseamList: string[] = []

  productToPopUpBS: BehaviorSubject<Product> = new BehaviorSubject<any>({})
  productToPopUp$: Observable<Product> = this.productToPopUpBS.asObservable()

  cartItemIsUpdatingBS: BehaviorSubject<CartItem> = new BehaviorSubject<any>(null)
  cartItemIsUpdating$: Observable<CartItem> = this.cartItemIsUpdatingBS.asObservable()

  constructor(private httpService: HttpService, private ss: SessionStoreService) { 
  }

  getProductList(page: number = 0, sortBy: string = 'displayOrder,id', size: string = "6", order: string = 'DESC', filter: ProductSkuFilterRequest = new ProductSkuFilterRequest()): Observable<any> {
    const params = new HttpParams().set('page', page.toString())
      .set('size', size)
      .set('sort', sortBy)
      .set('direction', order);

    return this.httpService.put(GET_PRODUCT_LIST_FILTER, { params: params, body: filter });
  }

  getProductSearchList(page: number = 0, sortBy: string = 'id', size: string = "6", order: string = 'DESC', keyword: string = '', filter: ProductSkuFilterRequest = new ProductSkuFilterRequest()): Observable<any> {
    const params = new HttpParams().set('page', page.toString())
      .set('size', size)
      .set('sort', sortBy)
      .set('direction', order)
      .set('keyword', keyword)

    return this.httpService.put(GET_PRODUCT_LIST_SEARCH_FILTER, { params: params, body: filter });
  }

  getProductDetail(productId: string): Observable<any> {
    const params = new HttpParams().set('id', productId);
    return this.httpService.get(GET_PRODUCT_LIST + "/" + productId, null);
  }

  getProductCategory(categoryId: string): Observable<any> {
    const params = new HttpParams().set('id', categoryId);
    return this.httpService.get(GET_PRODUCT_CATEGORY + "/" + categoryId, null);
  }

  getProductFilterOption(categoryIds: number[], sizeList: string[] = [], colorList: string[] = []) {
    return this.httpService.put(GET_PRODUCT_FILTER_OPTION, {body: new ProductFilterOptionRequest({categoryIds: categoryIds, colors: colorList, sizes: sizeList})})
  }

  getProductBySku(sku: string): Observable<Product> {
    return this.httpService.get(GET_PRODUCT_BY_SKU + sku + "/product", null)
  }

  getCrossSaleProduct(id): Observable<any> {
    return this.httpService.get(GET_PRODUCT_LIST + '/' + id + GET_CROSS_SALE_PRODUCT, null)
  }

  setProductToPopUp(product: Product) {
    this.productToPopUpBS.next(product)
  }

  setCartItemToUpdate(item: CartItem) {
    this.cartItemIsUpdatingBS.next(item)
  }

  extractAllSkuEav(productSkus: ProductSku[]): ProductAttributeMap[] {
    const newResult: ProductAttributeMap[] = []
    this.fullFitList = []
    this.fullSizeList = []
    this.fullInseamList = []
    productSkus.forEach(sku => {
      //New flow
      const colorImg = sku.images.find(img => img.path.indexOf("_SXL") >= 0)
      const newColor = getEavByAttribute(sku, "displayColor")
      const newFit = getEavByAttribute(sku, "fit")
      const newSize = getEavByAttribute(sku, "displaySize")
      const inseam = getEavByAttribute(sku, "inseam")

      //Store all the different fit, size and inseam
      if(this.fullFitList.indexOf(newFit) < 0 && newFit) {
        this.fullFitList.push(newFit)
      }

      if(this.fullSizeList.indexOf(newSize) < 0 && newSize) {
        this.fullSizeList.push(newSize)
      }

      if(this.fullInseamList.indexOf(inseam) < 0 && inseam) {
        this.fullInseamList.push(inseam)
      }

      const colorIndex = newResult.findIndex(color => color.value === newColor)
      if(colorIndex >= 0) {
        const fitIndex = newResult[colorIndex].children.findIndex(fit => fit.value === newFit)
        if(fitIndex >= 0) {
          const sizeIndex = newResult[colorIndex].children[fitIndex].children.findIndex(size => size.value === newSize)
          if(sizeIndex >= 0) {
            //Push new Inseam
            newResult[colorIndex].children[fitIndex].children[sizeIndex].children.push(new ProductAttributeMap({value: inseam}))
          }else {
            //Push new Size, Inseam
            const children: ProductAttributeMap[] = []
            children.push(new ProductAttributeMap({value: inseam}))
            newResult[colorIndex].children[fitIndex].children.push({value: newSize, children: children})
          }
        }else {
          //Push new Fit, Size, Inseam
          const children1: ProductAttributeMap[] = []
          children1.push(new ProductAttributeMap({value: inseam}))
          const children2: ProductAttributeMap[] = []
          children2.push(new ProductAttributeMap({value: newSize, children: children1}))
          newResult[colorIndex].children.push({value: newFit, children: children2})
        }
      }else {
        //Push new Color, Fit, Size, Inseam
        const children1: ProductAttributeMap[] = []
        children1.push(new ProductAttributeMap({value: inseam}))
        const children2: ProductAttributeMap[] = []
        children2.push(new ProductAttributeMap({value: newSize, children: children1}))
        const children3: ProductAttributeMap[] = []
        children3.push(new ProductAttributeMap({value: newFit, children: children2}))
        newResult.push(new ProductAttributeMap({value: newColor, imageUrl: colorImg.domain + colorImg.path, children: children3}))
      }
    })
    this.fullSizeList.sort(sortSize)
    this.fullInseamList.sort(sortSize)
    return newResult;
  }

}
