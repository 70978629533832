import { Component, Input, OnInit } from '@angular/core';
import { Order } from '@shared/models/order.model';

@Component({
  selector: 'app-order-review-summary',
  templateUrl: './order-review-summary.component.html',
  styleUrls: ['./order-review-summary.component.scss']
})
export class OrderReviewSummaryComponent implements OnInit {

  @Input() order: Order

  constructor() { }

  ngOnInit(): void {
  }

}
