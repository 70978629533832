import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './views/home/home.component';
import { AuthComponent } from './views/auth/auth.component';
import { ShoppingCartComponent } from './views/shopping-cart/shopping-cart.component';
import { CheckoutComponent } from './views/checkout/checkout.component';
import { OrderReviewComponent } from './views/order-review/order-review.component';
import { PaymentComponent } from './views/payment/payment.component';
import { ShoppingCartResolver } from './views/shopping-cart/shopping-cart.resolver';
import { OrderReviewResolver } from './views/order-review/order-review.resolver';
import { PaymentResolver } from './views/payment/payment.resolver';
import { OrderConfirmationComponent } from './views/order-confirmation/order-confirmation.component';
import { OrderConfirmationResolver } from './views/order-confirmation/order-confirmation.resolver';
import { ContactUsComponent } from './views/contact-us/contact-us.component';
import { SizeAndFitGuidesComponent } from './views/size-and-fit-guides/size-and-fit-guides.component';


const routes: Routes = [
  {
    path: '', redirectTo: 'home', pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent,
    loadChildren:() => import('./views/home/home.module').then(m=>m.HomeModule),
    data:{preload: true},
  },
  {
    path: 'products',
    loadChildren:() => import('./views/product/product.module').then(m => m.ProductModule),
    data: { preload: true }
  },
  {
    path: 'auth',
    loadChildren:() => import('./views/auth/auth.module').then(m => m.AuthModule),
    data: { preload: true }
  },
  {
    path: 'contact-us',
    component: ContactUsComponent,
    loadChildren:() => import('./views/contact-us/contact-us.module').then(m => m.ContactUsModule),
    data: { preload: true }
  },
  {
    path: 'cart',
    component: ShoppingCartComponent,
    loadChildren:() => import('./views/shopping-cart/shopping-cart.module').then(m => m.ShoppingCartModule),
    data: { preload: true },
    resolve: { 
      cart: ShoppingCartResolver
    }
  },
  {
    path: 'checkout',
    component: CheckoutComponent,
    loadChildren:() => import('./views/checkout/checkout.module').then(m => m.CheckoutModule),
    data: { preload: true }
  },
  {
    path: 'order-review/:orderId',
    component: OrderReviewComponent,
    loadChildren:() => import('./views/order-review/order-review.module').then(m => m.OrderReviewModule),
    data: { preload: true },
    resolve: { 
      order: OrderReviewResolver
    }
  },
  {
    path: 'payment/:orderId',
    component: PaymentComponent,
    loadChildren:() => import('./views/payment/payment.module').then(m => m.PaymentModule),
    data: { preload: true },
    resolve: { 
      order: PaymentResolver
    }
  },
  {
    path: 'order-confirmation/:orderId',
    component: OrderConfirmationComponent,
    loadChildren:() => import('./views/order-confirmation/order-confirmation.module').then(m => m.OrderConfirmationModule),
    data: { preload: true },
    resolve: { 
      order: OrderConfirmationResolver
    }
  },
  {
    path: 'member-portal',
    loadChildren:() => import('./views/member-portal/member-portal.module').then(m => m.MemberPortalModule),
    data: { preload: true },
  },
  {
    path: 'posts',
    loadChildren:() => import('./views/posts/posts.module').then(m => m.PostsModule),
    data: { preload: true }
  },
  {
    path: 'size-and-fit-guides',
    component: SizeAndFitGuidesComponent,
    loadChildren:() => import('./views/size-and-fit-guides/size-and-fit-guides.module').then(m => m.SizeAndFitGuidesModule),
    data: { preload: true }
  },
  {
    path: 'blog',
    loadChildren: () => import('./views/blog/blog.module').then(m => m.BlogModule),
    data: { title: 'Blog', breadcrumb: 'Blog' }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
