import { Component, OnInit, ViewChild } from '@angular/core';
import Slider from '@shared/models/Slider';
import { JwtAuthService } from '@shared/services/auth/jwt-auth.service';

@Component({
  selector: 'app-message-bar',
  templateUrl: './message-bar.component.html',
  styleUrls: ['./message-bar.component.scss']
})
export class MessageBarComponent implements OnInit {
  
  slider: Slider;

  currentIndex = 0

  interval: any;

  showContent: boolean = false

  constructor(private jwtAuthService: JwtAuthService) { }

  ngOnInit(): void {
    this.autoplay()

    this.jwtAuthService.getSlider().subscribe(sliders => {
      this.extractSliders(sliders)
    })

    this.jwtAuthService.sliders$.subscribe(sliders => {
      this.extractSliders(sliders)
    })
  }

  autoplay() {
    this.interval = setInterval(() => {
      if(this.currentIndex === this.slider.banners.length-1) {
        this.currentIndex = 0
      }else {
        this.currentIndex += 1;
      }
    }, 5000)
  }

  clearAutoPlay() {
    clearInterval(this.interval)
  }

  swapTo(index: number) {
    this.currentIndex = index
  }

  next() {
    if(this.currentIndex >= this.slider.banners.length-1) {
      this.currentIndex = 0
    }else {
      this.currentIndex += 1;
    }
  }

  prev() {
    if(this.currentIndex <= 0) {
      this.currentIndex = this.slider.banners.length-1;
    }else {
      this.currentIndex -= 1;
    }
  }

  openLink(){
    if (this.slider.banners[this.currentIndex].url){
      window.open(this.slider.banners[this.currentIndex].url);
    }
  }

  toggle() {
    this.clearAutoPlay()
    this.showContent = true
  }

  closeToggle() {
    this.autoplay()
    this.showContent = false
  }

  extractSliders(sliders: Slider[]) {
    sliders = sliders || []
    this.slider = sliders.filter(slider => slider.name === "NOTIFICATION").length > 0 ? sliders.filter(slider => slider.name === "NOTIFICATION")[0] : new Slider()
  }

}
