export default class ProductFilterOptionRequest {
    categoryIds: number[];
    colors: string[];
    sizes: string[];

    constructor(request?) {
        request = request || {}
        this.categoryIds = request.categoryIds || []
        this.colors = request.colors || []
        this.sizes = request.sizes || []
    }
}