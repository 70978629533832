import Slider from './Slider';

export default class Banner {
    id: number;
    seq: number;
    domain: string;
    path: string;
    domainZh: string;
    pathZh: string;
    name: string;
    nameZh: string;
    title: string;
    titleZh: string;
    url: string;
    urlZh: string;
    type: string;
    newTab: string;
    status: string;
    createdAt: string;
    updatedAt: string;
    startFrom: string;
    startTo: string;
    imageContent: string;
    imageContentZh: string;
    sliders: Slider[];

    constructor(banner?) {
        banner = banner || {}
        this.name = banner.name
        this.nameZh = banner.nameZh
        this.title = banner.title
        this.titleZh = banner.titleZh
        this.url = banner.url
        this.urlZh = banner.urlZh
    }

}