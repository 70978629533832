export class PaymentStripeRequest {
    type: string;
    source: string;
    data: any;

    constructor(orderId: string, token: string) {
        this.type = "card";
        this.source = token;
        this.data = {
            object: {
                metadata: {
                    reference_no: orderId
                }
            }
        }
    }
}
