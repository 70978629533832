<div [ngStyle]="{'margin-bottom': !remark ? '1rem' : ''}"  class="input-container">
    <div class="placeholder" [ngClass]="{'float': value}">{{placeholder}}</div>
    <input matInput 
        [matDatepickerFilter]="dateFilter" 
        [matDatepicker]="picker" 
        [min]="minDate"
        [max]="maxDate"
        (dateChange)="addFormatEvent('change', $event)" 
        [disabled]="disabled" 
        [ngClass]="{'error': error}" 
        [ngStyle]="{'padding-top': value ? '25px': ''}" 
        [(ngModel)]="value"
        readonly
    >
    <mat-datepicker-toggle class="datepicker-icon" [for]="picker">
    </mat-datepicker-toggle>
    <mat-datepicker #picker ></mat-datepicker>
    <div class="remark" *ngIf="remark">{{remark}}</div>
</div>
<span class="error" *ngIf="error">{{error}}</span>