<div class="product-filter-container">
    <h5>{{parentCategory.categoryEavs | getValueByAttribute:'displayName':'value'}}</h5>
    <app-category-expandable-panel *ngFor="let category of parentCategory.children" [isSale]="isSale" [category]="category"></app-category-expandable-panel>
    <h5 class="clear-all-header">FILTER BY<span (click)="onClearFilter()">Clear All</span></h5>
    <div *ngIf="subCategory && subCategory.length > 0">
        <div class="filter-title">
            Product Category
        </div>
        <div class="filter-category-container">
            <div *ngFor="let category of subCategory; index as i">
                <div *ngIf="category.status === 'ACTIVE'">
                    <app-checkbox 
                        [label]="category.categoryEavs | getValueByAttribute:'displayName':'value'" 
                        [value]="category.id" 
                        (onClick)="onClickCategory($event)" 
                        [clicked]="categoryIdMap.get(category.id)">
                    </app-checkbox>
                </div>
            </div>
        </div>
    </div>
    <div class="filter-title">
        Size
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="start" class="filter-size-container">
        <div *ngFor="let size of sizeList; index as i">
            <app-checkbox [label]="size" [value]="size" (onClick)="onClickSize($event)" [clicked]="sizeMap.get(size)"></app-checkbox>
        </div>
    </div>
    <div class="filter-title">
        Color
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="start" class="filter-color-container">
        <app-color-select *ngFor="let color of colorList; index as i" [color]="mapColorToCode(color)" [value]="color" (onClick)="onClickColor($event)" [selected]="colorMap.get(color)"></app-color-select>
    </div>
</div>

<div class="product-filter-container-mobile">
    <div class="navigate">
        <app-product-filter-dropdown [manualClose]="mobileDropDownManualClose" [categories]="parentCategory.children" title="NAVIGATE"></app-product-filter-dropdown>
    </div>
    <div class="filter">
        <app-product-filter-dropdown 
            [manualClose]="mobileDropDownManualClose"
            [filters]="filters" 
            title="FILTER"
            [subCategory]="subCategory"
            [sizeList]="sizeList"
            [colorList]="colorList"
            (onChangeCategory)="onMobileChangeCategory($event)"
            (onChangeSize)="onMobileChangeSize($event)"
            (onChangeColor)="onMobileChangeColor($event)">
        </app-product-filter-dropdown>
    </div>
    <div class="sort">
        <app-sorting-select [manualClose]="mobileDropDownManualClose" (onChange)="selectSorting($event)" [options]="sortBy" [selected]="selectedSort"></app-sorting-select>
    </div>
    <app-button (onClick)="mobileApplyFilter()" text="SHOW RESULTS"></app-button>
</div>
