import { Component, Input, OnInit } from '@angular/core';
import { Order } from '@shared/models/order.model';
import { ProductSku } from '@shared/models/product-sku.model';

@Component({
  selector: 'app-order-review-content',
  templateUrl: './order-review-content.component.html',
  styleUrls: ['./order-review-content.component.scss']
})
export class OrderReviewContentComponent implements OnInit {

  @Input() order: Order

  constructor() { }

  ngOnInit(): void {
  }

  isSalesPrice(productSku: ProductSku): boolean {
    if(productSku.salesFrom && productSku.salesTo && productSku.salesPrice) {
      let today = new Date().getTime() / 1000
      if(today > productSku.salesFrom && today < productSku.salesTo) {
        return true;
      }else {
        return false;
      }
    }else {
      return false;
    }
  }

}
