import { Component, Input, OnInit } from '@angular/core';
import Rule from '@shared/models/rule.model';
import { ShoppingCart } from '@shared/models/shopping-cart.model';
import { CartService } from '@shared/services/cart.service';

@Component({
  selector: 'app-coupon-section',
  templateUrl: './coupon-section.component.html',
  styleUrls: ['./coupon-section.component.scss']
})
export class CouponSectionComponent implements OnInit {

  @Input() cart: ShoppingCart

  @Input() lock: boolean = false

  rules: Rule[] = []

  expand: boolean = true;

  constructor(private cartService: CartService) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.cartService.getCartRule().subscribe(rules => {
      this.rules = rules
    })
  }

  toggle() {
    this.expand = !this.expand
  }

  applyCouponCode(couponCode: string) {
    this.cartService.setCouponCode(couponCode)
  }

}
