<ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">
    <div ngxSlickItem *ngFor="let banner of slider.banners" class="slide" style="outline: none; box-shadow: none;">
        <div fxLayout="column" fxLayoutAlign="center center" style="outline: none; box-shadow: none">
            <div class="fixHeight">
                <img src="{{ banner.domain + banner.path }}" />
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center" class="button-container">
                <a [href]="banner.url">{{banner.title}}</a>
                <a [href]="banner.urlZh">{{banner.titleZh}}</a>
            </div>
        </div>
    </div>
</ngx-slick-carousel>
