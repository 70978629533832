import { Component, OnInit, Input } from '@angular/core';
import { Product } from '@shared/models/product.model';
import { ProductSku } from '@shared/models/product-sku.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product-slider',
  templateUrl: './product-slider.component.html',
  styleUrls: ['./product-slider.component.scss']
})
export class ProductSliderComponent implements OnInit {

  @Input() title1: string = ""

  @Input() title2: string = ""

  @Input() products: Product

  @Input() divison: string = "1px"

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  @Input() slideConfig = {
    "arrows": true,
    "slidesToShow": 3,
    "slidesToScroll": 1,
    "infinite": false,
    "speed": 500,
    "autoplay":false,
    "dots": false,
    "prevArrow": "<img style='height: 80px; left: 5px; z-index: 50; padding-bottom: 40px' class='a-left control-c prev slick-prev' src='assets/images/icon/carousel-prev-arrow-icon.png'>",
    "nextArrow": "<img style='height: 80px; right: 5px; z-index: 50; padding-bottom: 40px' class='a-right control-c next slick-next' src='assets/images/icon/carousel-next-arrow-icon.png'>",
    "responsive": [
      {
        "breakpoint": 991,
        "settings": {
          "slidesToShow": 2,
          "slidesToScroll": 1,
        }
      },
      {
        "breakpoint": 600,
        "settings": {
          "slidesToShow": 1,
          "slidesToScroll": 1,
        }
      },
    ]
  }

  slickInit(e) {
  }

  breakpoint(e) {
  }

  afterChange(e) {
  }

  beforeChange(e) {
  }

}
