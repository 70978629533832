<div class="product-slider-item-container">
    <a (click)="gotoDetail(product.id, product.categories[0].id)" style="outline: none; box-shadow: none">
        <img [src]="product.productSkus[0].images | getImagePathByType:'THUMBNAIL'"/>
    </a>
    <div class="product-info">
        <div class="product-name">
            {{product.productSkus[0].productSkuEavs | getValueByAttribute:'name':'value'}}
        </div>
        <div class="price-container">
            <div class="product-price" [ngClass]="{'deleted': isSalesPrice(product.productSkus[0])}">$ {{product.productSkus[0].price | number}}</div>
            <div *ngIf="isSalesPrice(product.productSkus[0])" class="sale-price">$ {{(product.productSkus[0] | getProductSalesPriceIfAny) | number}}</div>
        </div>
    </div>
</div>