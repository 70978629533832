import { Pipe, PipeTransform } from "@angular/core";
import { ProductSku } from "@shared/models/product-sku.model"

@Pipe({
  name: "getReturnTotalByOrder",
  pure: false
})
export class GetReturnTotalByOrder implements PipeTransform {
  transform(obj: any): string {
    return String(parseInt(obj.grandTotal) - parseInt(obj.shippingFee))
  }
}