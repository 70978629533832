import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SessionStoreService {

  private ss = window.sessionStorage;

  constructor() { }

  public setItem(key, value) {
    value = JSON.stringify(value);
    this.ss.setItem(key, value);
    return true;
  }

  public getItem(key) {
    let value = this.ss.getItem(key);
    try {
      return JSON.parse(value);
    } catch (e) {
      return null;
    }
  }

  public removeitem(key) {
    this.ss.removeItem(key);
  }

  public clear() {
    this.ss.clear();
  }
}