import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Order } from '@shared/models/order.model';
import { ShoppingCart } from '@shared/models/shopping-cart.model';
import { CartService } from '@shared/services/cart.service';
import { OrdersService } from '@shared/services/orders.service';

@Component({
  selector: 'app-order-review',
  templateUrl: './order-review.component.html',
  styleUrls: ['./order-review.component.scss']
})
export class OrderReviewComponent implements OnInit {

  order: Order = new Order()

  cart: ShoppingCart = new ShoppingCart()

  constructor(private router: Router, private cartService: CartService, private orderService: OrdersService, private route: ActivatedRoute) { }

  orderRequest: any

  ngOnInit(): void {
    this.route.data .subscribe((data: { order: Order }) => {
      this.order = data.order
    })

    this.cartService.shoppingCart$.subscribe(cart => {
      this.cart = cart
    })
  }

  continueToPayment() {
    this.router.navigate([`/payment/${this.order.id}`])
  }

}
