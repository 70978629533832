import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '@env/environment';
import { BaseComponent } from '@shared/base/base.component';
import { ContactUsRequest } from '@shared/models/request/contact-us-request.model';
import { ContactUsService } from '@shared/services/contact-us.service';
import { CommonService } from '@shared/services/common.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent extends BaseComponent implements OnInit {

  contactUsForm: FormGroup;

  constructor(private _formBuilder: FormBuilder, 
              private contactUsService: ContactUsService, 
              private commonService: CommonService) { 
    super()
  }

  ngOnInit(): void {
    window.scroll(0, 0)
    this.contactUsForm = this.createContactUsForm()
  }

  createContactUsForm(): FormGroup {
    return this._formBuilder.group({
      firstname: ["", Validators.required],
      lastname: ["", Validators.required],
      email: ["", Validators.required],
      phone: ["", [Validators.required, Validators.minLength(8), Validators.maxLength(8)]],
      subject: ["", Validators.required],
      orderNo: [""],
      message: ["", Validators.required]
    });
  }

  submitContactUsForm() {
    if(this.contactUsForm.valid) {
      const data = this.contactUsForm.getRawValue()
      const request = new ContactUsRequest(data)
      this.contactUsService.sendContactUsEmail(request).subscribe(rsp => {
        this.commonService.openSimplePopup("contact-us")
      })
    }else {
      this.validateAllFormFields(this.contactUsForm)
    }
  }

}
