import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: "getEmbedYoutube",
  pure: false
})
export class GetEmbedYoutube implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) {
  }

  transform(url: string): any {
    return url.split('?v=')[1]
  }
}