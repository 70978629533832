<div class="container-1440">
    <div class="page-container">
        <div class="payment-container">
            <app-progress-bar [activeIndex]="2"></app-progress-bar>
            <div class="content-wrapper" fxLayout="row wrap" fxLayoutAlign="space-between start">
                <div class="left-container">
                    <app-section-title title="Payment"></app-section-title>
                    <form [formGroup]="paymentForm">
                        <mat-expansion-panel hideToggle="true" #creditCardPanel class="payment-expandable-panel"
                            (opened)="selectPaymentMethod('creditCard')" (closed)="unselectPaymentMethod('creditCard')">
                            <mat-expansion-panel-header [collapsedHeight]="'190px'" [expandedHeight]="'190px'">
                                <div fxLayout="row" fxLayoutAlign="space-between center" class="payment-method">
                                    <div>
                                        <app-checkbox1 label="Credit Card" [withMarginBottom]="false"
                                            formControlName="isCreditCard"></app-checkbox1>
                                    </div>
                                    <div class="payment-image">
                                        <img src="/assets/images/checkout/creditcard.png" alt="" />
                                    </div>
                                </div>
                            </mat-expansion-panel-header>
                            <div>
                                <app-input type="text" placeholder="Cardholder Name" formControlName="cardholderName"
                                    [error]="checkError(paymentForm, 'cardholderName', 'PLEASE ENTER A CARDHOLDER NAME')"></app-input>
                                <div class="stripe-container">
                                    <ngx-stripe-card [options]="cardOptions"
                                        [elementsOptions]="elementsOptions"></ngx-stripe-card>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </form>

                    <div>
                        <ngx-stripe-payment-request-button [paymentOptions]="paymentRequestOptions"
                            (paymentMethod)="onPaymentMethod($event)" (notavailable)="onNotAvailable()">
                        </ngx-stripe-payment-request-button>
                    </div>
                </div>
                <div class="right-container">
                    <h3>Order Summary</h3>
                    <app-order-summary [shoppingCart]="order"></app-order-summary>
                    <app-coupon-section [lock]="true" [cart]="cart"></app-coupon-section>
                    <div class="mt-1">
                        <app-hover-button text="SEND PAYMENT & PLACE ORDER" [borderColor]="'var(--thm-gold-color-2)'"
                            [bgBefore]="'var(--thm-white-color)'" [bgAfter]="'var(--thm-gold-color-3)'"
                            [textBefore]="'var(--thm-text-color)'" [textAfter]="'var(--thm-white-color)'"
                            (onClick)="submitPaymentForm()" [disabled]="disabled">
                        </app-hover-button>
                    </div>
                    <!-- <div class="mt-1">
                        <app-need-help-block></app-need-help-block>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>