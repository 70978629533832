import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormControlComponent } from '@shared/base/form-control/form-control.component';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: SelectComponent
    }
  ]
})
export class SelectComponent extends FormControlComponent implements OnInit{

  float: boolean = false;

  open: boolean = false;

  selected: string = "";

  @Input() options: any[] = []

  @Input() placeholder: string;

  @Input() disabled: boolean = false;

  @Input() error: string = ""

  @Output() onSelectChange = new EventEmitter<any>()

  constructor() {
    super()
  }

  ngOnInit(): void {
  }

  writeValue(value: any) {
    super.writeValue(value)
    this.selected = (this.options.find(option => option.value === value) || {text: ""}).text
  }

  toggle() {
    this.open = !this.open
  }

  blur() {
    this.open = false
  }

  select(value: string) {
    this.selected = (this.options.find(option => option.value === value) || {text: ""}).text
    this.value = value
    this.onChange(this.value)
    this.onSelectChange.emit(value)
  }

}
