import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Address } from '@shared/models/address.model';
import { JwtAuthService } from '@shared/services/auth/jwt-auth.service';
import { MemberService } from '@shared/services/member.service';

@Component({
  selector: 'app-address-record',
  templateUrl: './address-record.component.html',
  styleUrls: ['./address-record.component.scss']
})
export class AddressRecordComponent implements OnInit {

  @Input() address: Address = new Address({})

  @Output() onEdit = new EventEmitter<any>()

  constructor(private memberService: MemberService, private jwtAuthService: JwtAuthService) { }

  ngOnInit(): void {
  }

  edit() {
    this.onEdit.emit()
  }

  makeDefault() {
    const address: Address = JSON.parse(JSON.stringify(this.address))
    address.default = true;
    this.memberService.updateAddress(address).subscribe(user => {
      this.jwtAuthService.setUser(user)
    })
  }

  remove() {
    this.memberService.deleteAddress(this.address.id).subscribe(user => {
      this.jwtAuthService.setUser(user)
    })
  }

}
