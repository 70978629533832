<div *ngIf="cart.cartItems && cart.cartItems.length > 0" [ngStyle]="{'top': top}" class="shopping-cart-dropdown">
    <div class="top-container">
        <div *ngFor="let item of cart.cartItems" fxLayout="row" fxLayoutAlign="space-between center" class="cart-item-container">
            <img [src]="item.productSku.images | getImagePathByType:'THUMBNAIL'" alt="">
            <div fxLayout="column" fxLayoutAlign="space-between" class="item-content">
                <div>
                    <div>{{item.productSku.productSkuEavs | getValueByAttribute:'name':'value'}}</div>
                    <div>Color: {{item.productSku.productSkuEavs | getValueByAttribute:'displayColor':'value'}}</div>
                    <div *ngIf="item.productSku.productSkuEavs | getValueByAttribute:'fit':'value'">Fit: {{item.productSku.productSkuEavs | getValueByAttribute:'fit':'value'}}</div>
                    <div>Size: {{item.productSku.productSkuEavs | getValueByAttribute:'displaySize':'value'}} {{item.productSku.productSkuEavs | getValueByAttribute:'inseam':'value'}}</div>
                    <div>Price: ${{(item.productSku | getProductSalesPriceIfAny) | number}}</div>
                    <div>Qty: {{item.quantity}}</div>
                </div>
            </div>
        </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="center" class="bottom-container">
        <h5>Subtotal*: ${{cart.grandTotal | number}}</h5>
        <app-hover-button 
            [text]="'CHECKOUT'" 
            [borderColor]="'var(--thm-gold-color)'"
            [bgBefore]="'var(--thm-gold-color)'"
            [bgAfter]="'var(--thm-gold-color)'" 
            [textBefore]="'var(--thm-white-color)'" 
            [textAfter]="'var(--thm-text-color)'"
            height="40px"
            (onClick)="gotoShoppingCart()">
        </app-hover-button>
    </div>
</div>
