import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { throwError } from 'rxjs';
import { JwtAuthService } from './auth/jwt-auth.service';
import { CommonService } from './common.service';
import { catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class HttpService {
  baseURL: string = environment.apiURL || "";

  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  });

  headerContent = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  }

  constructor(private httpClient: HttpClient, private router: Router, private jwtAuthService: JwtAuthService, private commonService: CommonService) { }

  get<T>(apiURL: string, params: any): Observable<T> {
    let headers: HttpHeaders = new HttpHeaders(this.headerContent)
    const fullURL = `${this.baseURL}${apiURL}`;
    if (this.jwtAuthService.isLoggedIn()) {
      headers = headers.append("Authorization", "Bearer " + this.jwtAuthService.getJwtToken());
    }

    const options = {
      headers: headers,
      params: params,
      withCredentials: true
    };

    console.log('request', "GET", fullURL, options)

    return this.httpClient
      .get<T>(fullURL, options)
      .pipe(catchError((err: HttpErrorResponse) => {
        const errMsg = this.errorHandler(err);
        return throwError(err);
      }));
  }

  post<T>(apiURL: string, requestContent?: HttpRequestContent): Observable<T> {//params: any, responseType: string = 'json', body?: any): Observable<T> {
    let headers: HttpHeaders = new HttpHeaders(this.headerContent)
    const fullURL = `${this.baseURL}${apiURL}`;
    if (this.jwtAuthService.isLoggedIn()) {
      headers = headers.append("Authorization", "Bearer " + this.jwtAuthService.getJwtToken());
    }

    let options = {}

    if (requestContent && requestContent.responseType === 'text') {
      options = {
        headers: headers,
        params: requestContent.params,
        withCredentials: true,
        responseType: 'text'
      };
    } else {
      options = {
        headers: headers,
        params: requestContent.params,
        withCredentials: true
      };
    }

    console.log('request', "POST", fullURL, options, (requestContent.body || ''))

    return this.httpClient
      .post<T>(fullURL, requestContent.body, options)
      .pipe(catchError((err: HttpErrorResponse) => {
        const errMsg = this.errorHandler(err);
        return throwError(err);
      }));
  }

  put<T>(apiURL: string, requestContent: HttpRequestContent): Observable<T> {
    let headers: HttpHeaders = new HttpHeaders(this.headerContent)
    const fullURL = `${this.baseURL}${apiURL}`;
    if (this.jwtAuthService.isLoggedIn()) {
      headers = headers.append("Authorization", "Bearer " + this.jwtAuthService.getJwtToken());
    }

    const options = {
      headers: headers,
      params: requestContent.params,
      withCredentials: true
    };

    console.log('request', "PUT", fullURL, options, (requestContent.body || ''))

    return this.httpClient
      .put<T>(fullURL, requestContent.body, options)
      .pipe(catchError((err: HttpErrorResponse) => {
        const errMsg = this.errorHandler(err);
        return throwError(err);
      }));
  }
  
  delete<T>(apiURL: string, params: any): Observable<T> {
    let headers: HttpHeaders = new HttpHeaders(this.headerContent)
    const fullURL = `${this.baseURL}${apiURL}`;
    if (this.jwtAuthService.isLoggedIn()) {
      headers = headers.append("Authorization", "Bearer " + this.jwtAuthService.getJwtToken());
    }

    const options = {
      headers: headers,
      params: params,
      withCredentials: true
    };

    console.log('request', "DELETE", fullURL, options)

    return this.httpClient
      .delete<T>(fullURL, options)
      .pipe(catchError((err: HttpErrorResponse) => {
        const errMsg = this.errorHandler(err);
        return throwError(err);
      }));
  }

  errorHandler(errRes) {
    console.log(errRes);

    if (errRes.error instanceof ErrorEvent) {
      console.error('An error occurred on the client side', errRes.message);
      this.router.navigate(['error']);
    } else if (errRes.status === 0) {
      this.router.navigate(['error']);
    } else {

      var errStatus = errRes.status;
      var errBody = errRes.error;
      console.error(`Backend returned code ${errStatus}`);
      console.log(errBody);
      console.log(errStatus);
      const errCode = errRes.error.code;
      const errMsg = errRes.error.message;

      if(errStatus == 404){
        if (typeof errBody === 'string' || errBody instanceof String) {
          //type: string
          let errorBody = JSON.parse(errBody.toString());
          if (errorBody["error"] == 'Bad credential') {
            //alert("Wrong email or password")
          } else {
          }
          return errBody;
        } else {
          //type: json
          if (errBody["error"] == 'Bad credential') {
            //alert("Wrong email or password")
          } else {
          }
          return errBody;
        }
      }
      if (errStatus == 400 || errStatus == 500 ) {
        if (typeof errBody === 'string' || errBody instanceof String) {
          //type: string
          let errorBody = JSON.parse(errBody.toString());
          if (errorBody["error"] == 'Bad credential') {
            //alert("Wrong email or password")
          } else {

          }
          return errBody;
        } else {
          //type: json
          if (errBody["error"] == 'Bad credential') {
            //alert("Wrong email or password")
          } else {

          }
          return errBody;
        }
      }

      if (errCode === 'SYS001') {
        this.router.navigateByUrl('/login');
      } else if (errCode === 'SYS003') {
        //500
        this.router.navigateByUrl('/error');
      } else {
        alert(errBody.message);
        return errBody;
      }
    }
    return errRes.message;
  }
}

interface HttpRequestContent {
  params?: any,
  body?: any,
  responseType?: string
}
