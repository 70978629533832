<mat-expansion-panel [expanded]="open" [ngClass]="{'open': open}" class="filter-dropdown mat-elevation-z0" hideToggle="true" (opened)="open = true" (closed)="open = false">
    <mat-expansion-panel-header class="filter-dropdown-header">
        <mat-panel-title class="filter-dropdown-title">
            {{title}}
        </mat-panel-title>
        <mat-panel-description class="filter-dropdown-description">
            <img *ngIf="!open; else subtractImage" src="assets/images/icon/add-icon.svg" alt="">
            <ng-template #subtractImage>
                <img src="assets/images/icon/subtract-icon.svg" alt="">
            </ng-template>
        </mat-panel-description>
    </mat-expansion-panel-header>
    <app-product-filter-sub-dropdown *ngFor="let category of categories; index as i" 
        [title]="category.categoryEavs | getValueByAttribute:'displayName':'value'" 
        [categories]="category.children"
        [isLastChild]="i === categories.length - 1"
        [isSale]="isSale">
    </app-product-filter-sub-dropdown>
    <ng-container *ngFor="let filter of filters; index as i" [ngSwitch]="filter">
        <ng-container *ngIf="subCategory && subCategory.length > 0">
            <app-product-filter-sub-dropdown *ngSwitchCase="'PRODUCT CATEGORY'" 
                [title]="filter" 
                [isLastChild]="i === filters.length - 1" 
                [subCategory]="subCategory"
                (onChangeCategory)="onChangeCategory.emit($event)">
            </app-product-filter-sub-dropdown>
        </ng-container>
        <app-product-filter-sub-dropdown *ngSwitchCase="'SIZE'" 
            [title]="filter" 
            [isLastChild]="i === filters.length - 1" 
            [sizeList]="sizeList"
            (onChangeSize)="onChangeSize.emit($event)">
        </app-product-filter-sub-dropdown>
        <app-product-filter-sub-dropdown *ngSwitchCase="'COLOR'" 
            [title]="filter" 
            [isLastChild]="i === filters.length - 1" 
            [colorList]="colorList"
            (onChangeColor)="onChangeColor.emit($event)">
        </app-product-filter-sub-dropdown>
    </ng-container>
</mat-expansion-panel>