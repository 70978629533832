import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';


import { HttpService } from '@shared/services/http.service';
import {SessionStoreService} from "@shared/services/session-store.service";
import { Customer } from '@shared/models/customer.model';
import { Address } from '@shared/models/address.model';

const UPDATE_CUSTOMER = 'customer/me';
const ADD_ADDRESS = 'customer/add-address'
const UPDATE_ADDRESS = 'customer/change-address'
const DELETE_ADDRESS = 'customer/delete-address'
const SUBSCRIBE_COUPON = 'customize/subscribe-coupon'

@Injectable({
  providedIn: 'root'
})
export class MemberService{

  constructor(private httpService: HttpService, private ss: SessionStoreService) {}

  updateMember(customer: Customer): Observable<any> {
    return this.httpService.put(UPDATE_CUSTOMER, {body: customer});
  }

  addAddress(address: Address, source: string): Observable<any> {
    return this.httpService.post(`${ADD_ADDRESS}/${source}`, {body: address});
  }

  updateAddress(address: Address): Observable<any> {
    return this.httpService.put(`${UPDATE_ADDRESS}/${address.id}`, {body: address})
  }

  deleteAddress(id: number): Observable<any> {
    return this.httpService.delete(`${DELETE_ADDRESS}/${id}`, {})
  }

  subscribeCoupon(request: any): Observable<any> {
    return this.httpService.post(SUBSCRIBE_COUPON, {body: request, responseType: 'text'})
  }
}
