import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '@shared/base/base.component';
import { AuthService } from '@shared/services/auth.service';
import { CommonService } from '@shared/services/common.service';

@Component({
  selector: 'app-forgot-password-popup',
  templateUrl: './forgot-password-popup.component.html',
  styleUrls: ['./forgot-password-popup.component.scss']
})
export class ForgotPasswordPopupComponent extends BaseComponent implements OnInit {

  forgotPasswordForm: FormGroup

  show: boolean = false

  constructor(private commonService: CommonService, private _formBuilder: FormBuilder, private authService: AuthService) { 
    super()
  }

  ngOnInit(): void {
    this.forgotPasswordForm = this.createForgotPasswordForm()

    this.commonService.showForgotPassword$.subscribe(show => {
      this.show = show
    })
  }

  createForgotPasswordForm(): FormGroup {
    return this._formBuilder.group({
      email: ["", Validators.required],
    });
  }
  
  submitForgotPasswordForm() {
    if(this.forgotPasswordForm.valid) {
      const data = this.forgotPasswordForm.getRawValue()
      this.authService.forgotPassword(data).subscribe(rsp => {
        this.close()
        this.commonService.openSimplePopup("reset-password")
      })
    }else {
      this.validateAllFormFields(this.forgotPasswordForm)
    }
  }

  close() {
    this.commonService.closeForgotPasswordPopup()
  }

  closePopup() {
    this.commonService.closeSimplePopup("reset-password")
  }

}
