<div class="container-1440">
    <div class="page-container">
        <div [ngClass]="{'empty-cart': !(cart.cartItems && cart.cartItems.length > 0)}" fxLayout="row wrap" fxLayoutAlign="space-between start" class="checkout-container">
            <div class="left-container">
                <h3>Shopping Bag ({{cart.cartItems.length}})</h3>
                <app-shopping-cart-table [shoppingCart]="cart"></app-shopping-cart-table>
                <div *ngIf="!(cart.cartItems && cart.cartItems.length > 0)" fxLayout="column" fxLayoutAlign="center center" class="empty-cart-container">
                    <h5>You do not have any items in your shopping bag at this time.</h5>
                    <div>
                        <app-button (onClick)="gotoHome()" text="Continue shopping"></app-button>
                    </div>
                </div>
                <div *ngIf="recentlyViewProducts && recentlyViewProducts.length > 0" class="w-100 d-none-m">
                    <app-product-slider [products]="recentlyViewProducts" title1="" title2="Recently Viewed"></app-product-slider>
                </div>
            </div>
            <div class="right-container">
                <div class="top-container">
                    <h3>Order Summary</h3>
                    <app-order-summary [shoppingCart]="cart"></app-order-summary>
                    <app-coupon-section *ngIf="isLoggedIn" [cart]="cart"></app-coupon-section>
                </div>
                <form [formGroup]="loginForm" *ngIf="!isLoggedIn; else loggedInBlock" fxLayout="column" class="bottom-container">
                    <app-input type="text" placeholder="Email Address *" formControlName="email" [error]="checkError(loginForm, 'email', 'A VALID EMAIL IS REQUIRED.')"></app-input>
                    <app-input type="password" placeholder="Password *" formControlName="password" [error]="checkError(loginForm, 'password', 'PLEASE ENTER A VALID PASSWORD.')"></app-input>
                    <app-hover-button 
                        [text]="'SIGN IN TO CHECKOUT'" 
                        [bgBefore]="'var(--thm-text-color)'" 
                        [bgAfter]="'var(--thm-text-hover-color)'" 
                        [textBefore]="'var(--thm-white-color)'" 
                        [textAfter]="'var(--thm-white-color)'"
                        [error]="loginError"
                        (onClick)="submitLoginForm()">
                    </app-hover-button>
                    <div class="button-container">
                        <app-hover-button 
                            [text]="'CREATE AN ACCOUNT'" 
                            [bgBefore]="'var(--thm-text-color)'" 
                            [bgAfter]="'var(--thm-text-hover-color)'" 
                            [textBefore]="'var(--thm-white-color)'" 
                            [textAfter]="'var(--thm-white-color)'"
                            (onClick)="gotoAuth()">
                        </app-hover-button>
                    </div>
                    <a (click)="openForgotPassword()">I forgot my password</a>
                </form>
                <ng-template #loggedInBlock>
                    <div fxLayout="column" class="logged-in-bottom-container">
                        <app-hover-button 
                            [text]="'CHECKOUT'" 
                            [disabled]="isNotAvaible(cart)" 
                            [bgBefore]="'var(--thm-text-color)'" 
                            [bgAfter]="'var(--thm-blue-color-2)'" 
                            [textBefore]="'var(--thm-white-color)'" 
                            [textAfter]="'var(--thm-white-color)'"
                            (onClick)="gotoCheckout()">
                        </app-hover-button>
                    </div>
                </ng-template>
                <a href="/#/posts/delivery-and-returns" target="_blank">VIEW OUR 14-DAY RETURN POLICY</a>
                <!-- <app-need-help-block></app-need-help-block> -->
            </div>
            <div class="w-100 d-none-d">
                <app-product-slider [products]="recentlyViewProducts" title1="" title2="Recently Viewed"></app-product-slider>
            </div>
        </div>
    </div>
</div>