<div class="product-filter-sale-container">
    <div class="breadcrumb-container">
        <a href="/">Home /</a>
        <a href="/#/products/sale/page">Sale</a>
    </div>
    <h5>Sale</h5>
    <app-category-expandable-panel *ngFor="let category of parentCategory.children" [category]="category" [showSaleSuffix]="true" [isSale]="true"></app-category-expandable-panel>
</div>

<div class="product-filter-sale-container-mobile">
    <div class="navigate">
        <app-product-filter-dropdown [categories]="parentCategory.children" title="NAVIGATE" [isSale]="true"></app-product-filter-dropdown>
    </div>
</div>
