<div class="footer-wrapper">
    <div fxLayout="row" fxLayoutAlign="center center" class="footer-top">
        <div fxLayout="row" fxLayoutAlign="start center">
            <img src="assets/images/icon/mail-icon-white.svg" alt="">
            <a href="mailto:ec@brooksbrothers.sg">Email Us</a>
        </div>
    </div>
    <div class="content">
        <div fxLayout="row wrap" fxLayoutAlign="center">
            <div fxLayout="column" fxLayoutAlign="start" class="section">
                <a>HELP CENTER</a>
                <a href="/#/posts/find-a-store">Find a Store</a>
                <a href="/#/size-and-fit-guides" target="_blank">Size & Fit Guides</a>
                <a href="/#/contact-us">Contact Us</a>
                <a href="/#/posts/delivery-and-returns">Delivery & Returns</a>
                <a [href]="isLoggedIn ? '/#/member-portal/order-history' : '/#/auth'">Track Your Order</a>
            </div>
            <div fxLayout="column" fxLayoutAlign="start" class="section-group">
                <div fxLayout="column" fxLayoutAlign="start" class="section">
                    <a>ABOUT US</a>
                    <!-- TODO: 200th Anniversary TBC -->
                    <!-- <a href="#">200th Anniversary</a> -->
                    <!-- <a href="#">Our Heritage</a> -->
                    <a href="/#/posts/the-bb-club">The BB Club</a>
                </div>
                <div fxLayout="column" fxLayoutAlign="start" class="section">
                    <!-- <a>LOYALTY & MEMBERSHIP</a>
                    <a href="#">ICARD Programme</a>
                    <a href="#">Refer a friend</a> -->
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="start" class="section-group">
                <div fxLayout="column" fxLayoutAlign="start" class="section">
                    <a>FOLLOW US</a>
                    <div class="social-icon-container" fxLayout="row" fxLayoutAlign="start center">
                        <a href="https://www.facebook.com/BrooksBrothersSG" target="_blank" class="icon icon-1"></a>
                        <a href="https://www.instagram.com/brooksbrothers.singapore/" target="_blank" class="icon icon-3"></a>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="start" class="section input-section">
                <a href="#">SIGN UP FOR EMAILS</a>
                <form name="subscribeForm" [formGroup]="subscribeForm">
                    <app-input type="email" placeholder="Enter Email Address" formControlName="email" [error]="checkError(subscribeForm, 'email', 'PLEASE ENTER YOUR EMAIL ADDRESS')"></app-input>
                    <!-- <app-select [options]="regions" formControlName="region"></app-select> -->
                    <app-button (onClick)="submit()" text="submit"></app-button>
                </form>
            </div>
        </div>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="footer-bottom-container">
        <div fxLayout="row wrap" fxLayoutAlign="start center" class="footer-bottom-top">
            <span>© 2023 Brooks Brothers. All Rights Reserved</span>
            <a href="/#/posts/privacy-policy">PRIVACY POLICY</a>
            <a href="/#/posts/terms-and-conditions">TERMS OF USE</a>
            <a href="/#/posts/cookies-policy">COOKIES POLICY</a>
        </div>
        <div class="version">v0.1.20_20230803</div>
    </div>
</div>
<app-simple-popup id="subscription" title="Email Subscription" content1="Thank you for your interest!" [content2]="content2"></app-simple-popup>
