<div *ngIf="show" class="simple-popup-wrapper" fxLayout="row" fxLayoutAlign="center center">
    <div class="simple-popup-container">
        <h4>{{title}}</h4>
        <div class="content">
            <div *ngIf="content1">{{content1}}</div>
            <div *ngIf="content2">{{content2}}</div>
            <ng-content></ng-content>
        </div>
        <img (click)="close()" src="assets/images/icon/cross-icon-dark.png" alt="">
    </div>
</div>
