import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {

  @Input() clicked: boolean = false;

  @Input() label: string;

  @Input() value: string;

  @Output() onChange = new EventEmitter<any>()

  @Output() onClick = new EventEmitter<any>()

  constructor() { }

  ngOnInit(): void {
  }

  click() {
    this.clicked = !this.clicked;
    this.onChange.emit(this.clicked)
    this.onClick.emit(this.value)
  }

}
