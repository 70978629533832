import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "removeSymbol",
    pure: true
})
export class RemoveSymbolPipe implements PipeTransform {
    //todo lang
    transform(inputString: string,symbol: string): any {
          return inputString.replace(symbol," ");
    }
}
