<div class="address-record-container">
    <div class="address-type">{{address.type}} Address</div>
    <div fxLayout="row wrap" fxLayoutAlign="space-between center">
        <div class="address-name">
            {{address.firstname}} {{address.lastname}} <span *ngIf="address.default">(DEFAULT)</span>
        </div>
        <div fxLayout="row" class="button">
            <div *ngIf="!address.default" (click)="makeDefault()">Make Default</div>
            <div (click)="remove()">Remove</div>
            <div (click)="edit()">Edit</div>
        </div>
    </div>
    <div class="address">{{address.address | getAddressLineByLineNumber:1}}, <span *ngIf="address.address.split(' | ').length > 1">{{address.address | getAddressLineByLineNumber:2}},</span> {{address.city}}, {{address.country}}</div>
</div>
