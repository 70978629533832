import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "getImagePathByType",
  pure: false
})
export class GetImagePathByAttributePipe implements PipeTransform {
  transform(value: any[], type: string): any {
    const filteredObj = value.find(item => {
      if (item.type !== undefined) {
        return item.type === type;
      }

      return false;
    });

    if (filteredObj) {
      return filteredObj["domain"] + filteredObj["path"];
    }
  }
}