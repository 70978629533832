import { NgModule } from "@angular/core";

import { CommonModule } from "@angular/common";

import { LayoutModule } from '@angular/cdk/layout';

import { TranslateModule } from '@ngx-translate/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// LIBRARY
import { SlickCarouselModule } from "ngx-slick-carousel";
import { CountoModule } from "angular2-counto";
import { FlexLayoutModule } from "@angular/flex-layout";
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { YouTubePlayerModule } from '@angular/youtube-player';

// MATERIAL-UI
import { MaterialModule } from "../../material.module";

// SERVICES
import { HttpService } from './services/http.service';
import { ThemeService } from './services/theme.service';

//COMPONENTS
import { SharedComponentsModule } from './components/shared-components.module';

const classesToInclude = [
];

const modulesToImportExport = [
  SharedComponentsModule,
  MaterialModule,
  SlickCarouselModule,
  CountoModule,
  FlexLayoutModule,
  TranslateModule,
  FormsModule,
  ReactiveFormsModule,
  NgxImageZoomModule,
  YouTubePlayerModule
];

const serviceToProcide = [
  HttpService,
]

@NgModule({
  declarations: classesToInclude,
  imports: [CommonModule, LayoutModule, modulesToImportExport],
  providers: [serviceToProcide, ThemeService],
  exports: [modulesToImportExport, classesToInclude],
})
export class SharedModule {}
