import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Order } from '@shared/models/order.model';

@Component({
  selector: 'app-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.scss']
})
export class OrderConfirmationComponent implements OnInit {

  order: Order = new Order()

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.data .subscribe((data: { order: Order }) => {
      this.order = data.order
    })
  }

}
