import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';

import { LayoutModule } from '@angular/cdk/layout';

import { TranslateModule } from '@ngx-translate/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';

import { MaterialModule } from 'material.module';

import { YouTubePlayerModule } from '@angular/youtube-player';

// COMPONENTS
import { HeaderComponent } from './header/header.component';
import { LoadingPageComponent } from './loading-page/loading-page.component';
import { MessageBarComponent } from './message-bar/message-bar.component';
import { FooterComponent } from './footer/footer.component';
import { ButtonComponent } from './button/button.component';
import { InputComponent } from './input/input.component';
import { TextAreaComponent } from './text-area/text-area.component';
import { SelectComponent } from './select/select.component';
import { SliderComponent } from './slider/slider.component';
import { SortingSelectComponent } from './sorting-select/sorting-select.component';
import { ProductFilterComponent } from './product-filter/product-filter.component';
import { CategoryExpandablePanelComponent } from './category-expandable-panel/category-expandable-panel.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ColorSelectComponent } from './color-select/color-select.component';
import { ProductListingItemComponent } from './product-listing-item/product-listing-item.component';
import { SizeBoxComponent } from './size-box/size-box.component';
import { QuantityButtonComponent } from './quantity-button/quantity-button.component';
import { HoverButtonComponent } from './hover-button/hover-button.component';
import { ProductSliderComponent } from './product-slider/product-slider.component';
import { Checkbox1Component } from './checkbox1/checkbox1.component';
import { CheckboxTooltipComponent } from './checkbox-tooltip/checkbox-tooltip.component';
import { ProductContentComponent } from './product-content/product-content.component';
import { ProductDetailPopupComponent } from './product-detail-popup/product-detail-popup.component';
import { HeaderAccountDropdownComponent } from './header-account-dropdown/header-account-dropdown.component';
import { ShoppingCartTableComponent } from './shopping-cart-table/shopping-cart-table.component';
import { OrderSummaryComponent } from './order-summary/order-summary.component';
import { InputWithButtonComponent } from './input-with-button/input-with-button.component';
import { CouponSectionComponent } from './coupon-section/coupon-section.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { SectionTitleComponent } from './section-title/section-title.component';
import { NeedHelpBlockComponent } from './need-help-block/need-help-block.component';
import { SideNavBarComponent } from './side-nav-bar/side-nav-bar.component';
import { NavigationCategoryExpandablePanelComponent } from './navigation-category-expandable-panel/navigation-category-expandable-panel.component';
import { NavigationSubCategoryExpandablePanelComponent } from './navigation-sub-category-expandable-panel/navigation-sub-category-expandable-panel.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { HeaderShoppingCartDropdownComponent } from './header-shopping-cart-dropdown/header-shopping-cart-dropdown.component';
import { ProductFilterDropdownComponent } from './product-filter-dropdown/product-filter-dropdown.component';
import { ProductFilterSubDropdownComponent } from './product-filter-sub-dropdown/product-filter-sub-dropdown.component';
import { MemberPortalNavBarComponent } from './member-portal-nav-bar/member-portal-nav-bar.component';
import { AddressRecordComponent } from './address-record/address-record.component';
import { AddressUpdateFormComponent } from './address-update-form/address-update-form.component';
import { OrderReviewContentComponent } from './order-review-content/order-review-content.component';
import { ForgotPasswordPopupComponent } from './forgot-password-popup/forgot-password-popup.component';
import { CouponPopupComponent } from './coupon-popup/coupon-popup.component';
import { CookieBlockComponent } from './cookie-block/cookie-block.component';
import { SearchPopupComponent } from './search-popup/search-popup.component';
import { OrderReviewSummaryComponent } from './order-review-summary/order-review-summary.component';
import { ProductSaleFilterComponent } from './product-sale-filter/product-sale-filter.component';
import { SaleSliderComponent } from './sale-slider/sale-slider.component';
import { DateInputComponent } from './date-input/date-input.component';
import { YoutubeComponent } from './youtube/youtube.component';
import { ProductSliderItemComponent } from './product-slider-item/product-slider-item.component';
import { UnderlineLinkTextComponent } from './underline-link-text/underline-link-text.component';
import { InstagramBlockComponent } from './instagram-block/instagram-block.component';
import { SimplePopupComponent } from './simple-popup/simple-popup.component';
import { BlogItemComponent } from "@shared/components/blog-item/blog-item.component";

// LIBRARY
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CountoModule } from 'angular2-counto';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxImageZoomModule } from 'ngx-image-zoom';

//PIPES
import { RelativeTimePipe } from '../pipes/relative-time.pipe';
import { ExcerptPipe } from '../pipes/excerpt.pipe';
import { GetValueByKeyPipe } from '../pipes/get-value-by-key.pipe';
import { GetValueByAttributePipe } from '../pipes/get-value-by-attribute.pipe';
import { GetImagePathByAttributePipe } from '../pipes/get-image-path-by-type.pipe';
import { GetProductSalesPriceIfAny } from '../pipes/get-product-sales-price-if-any';
import { CheckProductIsSale } from '../pipes/check-product-is-sale';
import { GetAddressLineByLineNumber } from '@shared/pipes/get-address-by-line-number';
import { GetDiscountByCartOrOrder } from '@shared/pipes/get-discount-by-cart-or-order';
import { RemoveSymbolPipe } from '@shared/pipes/remove-symbol-pipe';
import { FormatDate } from '@shared/pipes/format-date';
import { GetEmbedYoutube } from '@shared/pipes/get-embed-youtube';
import { GetReturnTotalByOrder } from '@shared/pipes/get-return-total-by-order';
import { RoundPipe } from '@shared/pipes/round.pipe';

//DIRECTIVES
import { InstagramPostDirective } from '@shared/directives/instagram-post.directive';

const classesToInclude = [
  InstagramPostDirective,
  RelativeTimePipe,
  ExcerptPipe,
  GetValueByKeyPipe,
  GetValueByAttributePipe,
  GetImagePathByAttributePipe,
  GetProductSalesPriceIfAny,
  CheckProductIsSale,
  GetAddressLineByLineNumber,
  GetDiscountByCartOrOrder,
  RemoveSymbolPipe,
  FormatDate,
  RoundPipe,
  GetEmbedYoutube,
  GetReturnTotalByOrder,
  LoadingPageComponent,
  HeaderComponent,
  MessageBarComponent,
  FooterComponent,
  ButtonComponent,
  InputComponent,
  TextAreaComponent,
  SelectComponent,
  SliderComponent,
  SortingSelectComponent,
  ProductFilterComponent,
  CategoryExpandablePanelComponent,
  CheckboxComponent,
  ColorSelectComponent,
  ProductListingItemComponent,
  SizeBoxComponent,
  QuantityButtonComponent,
  HoverButtonComponent,
  ProductSliderComponent,
  Checkbox1Component,
  CheckboxTooltipComponent,
  ProductContentComponent,
  ProductDetailPopupComponent,
  HeaderAccountDropdownComponent,
  ShoppingCartTableComponent,
  OrderSummaryComponent,
  InputWithButtonComponent,
  CouponSectionComponent,
  ProgressBarComponent,
  SectionTitleComponent,
  NeedHelpBlockComponent,
  SideNavBarComponent,
  NavigationCategoryExpandablePanelComponent,
  NavigationSubCategoryExpandablePanelComponent,
  PaginatorComponent,
  HeaderShoppingCartDropdownComponent,
  ProductFilterDropdownComponent,
  ProductFilterSubDropdownComponent,
  MemberPortalNavBarComponent,
  AddressRecordComponent,
  AddressUpdateFormComponent,
  OrderReviewContentComponent,
  ForgotPasswordPopupComponent,
  CouponPopupComponent,
  CookieBlockComponent,
  SearchPopupComponent,
  OrderReviewSummaryComponent,
  ProductSaleFilterComponent,
  SaleSliderComponent,
  DateInputComponent,
  YoutubeComponent,
  ProductSliderItemComponent,
  UnderlineLinkTextComponent,
  InstagramBlockComponent,
  SimplePopupComponent,
  BlogItemComponent,
];

const modulesToImportExport = [
  MaterialModule,
  SlickCarouselModule,
  CountoModule,
  FlexLayoutModule,
  TranslateModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  NgxImageZoomModule,
  YouTubePlayerModule,
];

@NgModule({
  declarations: classesToInclude,
  imports: [CommonModule, LayoutModule, modulesToImportExport],
  exports: [classesToInclude, modulesToImportExport],
})
export class SharedComponentsModule {}
