import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import Category from '@shared/models/category.model';

@Component({
  selector: 'app-product-filter-sub-dropdown',
  templateUrl: './product-filter-sub-dropdown.component.html',
  styleUrls: ['./product-filter-sub-dropdown.component.scss']
})
export class ProductFilterSubDropdownComponent implements OnInit, OnChanges {

  @Input() isSale: boolean = false

  @Input() title: string;

  @Input() categories: Category[];

  @Input() isLastChild: boolean = false;

  @Input() subCategory: Category[]

  @Input() sizeList: []

  @Input() colorList: []

  @Output() onChangeCategory = new EventEmitter<any>()

  @Output() onChangeSize = new EventEmitter<any>()

  @Output() onChangeColor = new EventEmitter<any>()

  open: boolean = false

  selectedCategoryList: number[] = []

  selectedSizeList: string[] = []

  selectedColorList: string[] = []

  constructor() { }

  ngOnInit(): void {
    this.resetFilter()
  }

  ngOnChanges(): void {
  }

  selectCategory(id: number) {
    if(this.containCategory(id)) {
      this.selectedCategoryList = this.selectedCategoryList.filter(selectedId => id !== selectedId)
    }else {
      this.selectedCategoryList.push(id)
    }

    this.onChangeCategory.emit(this.selectedCategoryList)
  }

  containCategory(id: number) {
    return this.selectedCategoryList.findIndex(selectedId => id === selectedId) >= 0
  }

  selectSize(size: string) {
    if(this.containSize(size)) {
      this.selectedSizeList = this.selectedSizeList.filter(selectedSize => size !== selectedSize)
    }else {
      this.selectedSizeList.push(size)
    }

    this.onChangeSize.emit(this.selectedSizeList)
  }

  containSize(size: string) {
    return this.selectedSizeList.findIndex(selectedSize => size === selectedSize) >= 0
  }

  selectColor(color: string) {
    if(this.containColor(color)) {
      this.selectedColorList = this.selectedColorList.filter(selectedColor => color !== selectedColor)
    }else {
      this.selectedColorList.push(color)
    }

    this.onChangeColor.emit(this.selectedColorList)
  }

  containColor(color: string) {
    return this.selectedColorList.findIndex(selectedColor => color === selectedColor) >= 0
  }

  resetFilter() {
    this.selectedCategoryList = []
    this.selectedSizeList = []
    this.selectedColorList = []
  }

}
