import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from '@shared/services/navigation.service';

@Component({
  selector: 'app-navigation-sub-category-expandable-panel',
  templateUrl: './navigation-sub-category-expandable-panel.component.html',
  styleUrls: ['./navigation-sub-category-expandable-panel.component.scss']
})
export class NavigationSubCategoryExpandablePanelComponent implements OnInit {

  @Input() category: MenuItem;

  @Input() subCategories: MenuItem[];

  open: boolean = false

  constructor() { }

  ngOnInit(): void {
  }

}
