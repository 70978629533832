import CategoryEav from './categoryEav.model';

export default class Category {
    id: number;
    key: string;
    path: string;
    children: Category[];
    categoryEavs: CategoryEav[];
    parent: any;
    status: string
    displayOrder: string

    constructor(category?) {
        category = category || {}
        this.id = category.id
        this.key = category.key || ''
        this.path = category.path || ''
        this.children = category.children || []
        this.categoryEavs = category.categoryEavs || []
        this.parent = category.parent
        this.status = category.status
        this.displayOrder = category.displayOrder || '0'
    }
}