import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '@shared/services/common.service';

@Component({
  selector: 'app-search-popup',
  templateUrl: './search-popup.component.html',
  styleUrls: ['./search-popup.component.scss']
})
export class SearchPopupComponent implements OnInit {

  @ViewChild("search") search: ElementRef

  show: boolean = false;

  query: string = "";

  constructor(private commonService: CommonService, private router: Router) { }

  ngOnInit(): void {
    this.commonService.showSearch$.subscribe(show => {
      this.show = show
      if(this.show) {
        setTimeout(() => {
          this.search.nativeElement.focus()
        }, 100)
      }
    })
  }

  close() {
    this.query = ""
    this.commonService.closeSearchPopup()
  }
  
  goToSearch() {
    if(this.query) {
      this.router.navigate(["/products/search/" + this.query])
    }
  }

  onKeyPress(event) {
    if(event.keyCode === 13) {
      this.goToSearch()
    }
  }

}
