<mat-expansion-panel [ngStyle]="{'border-bottom': isLastChild ? '' : '1px solid var(--thm-grey-color-16)'}" [ngClass]="{'open': open}" class="filter-sub-dropdown sub mat-elevation-z0" hideToggle="true" (opened)="open = true" (closed)="open = false">
    <mat-expansion-panel-header class="filter-sub-dropdown-header">
        <mat-panel-title class="filter-sub-dropdown-title">
            {{title}}<span *ngIf="isSale">'S SALE</span>
        </mat-panel-title>
        <mat-panel-description class="filter-sub-dropdown-description">
            <img *ngIf="!open; else subtractImage" src="assets/images/icon/add-icon.svg" alt="">
            <ng-template #subtractImage>
                <img src="assets/images/icon/subtract-icon.svg" alt="">
            </ng-template>
        </mat-panel-description>
    </mat-expansion-panel-header>
    <div *ngFor="let category of categories">
        <div class="mobile-category-menu-active" *ngIf="category.status ==='ACTIVE'">
            <a [href]="'/#/products/' + category.id">{{category.categoryEavs | getValueByAttribute:'displayName':'value'}}</a>
        </div>
    </div>   
    <a class="filter" *ngFor="let category of subCategory" [ngClass]="{'selected': containCategory(category.id)}" (click)="selectCategory(category.id)">{{category.categoryEavs | getValueByAttribute:'displayName':'value'}}</a>
    <a class="filter" *ngFor="let size of sizeList" [ngClass]="{'selected': containSize(size)}" (click)="selectSize(size)">{{size}}</a>
    <div *ngIf="colorList && colorList.length > 0" fxLayout="row wrap" fxLayoutAlign="start" class="colors-container">
        <app-color-select *ngFor="let color of colorList; index as i" [color]="color" [value]="color" (onClick)="selectColor($event)"></app-color-select>
    </div>
</mat-expansion-panel>