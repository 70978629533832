<div class="wrapper">
    <app-search-popup></app-search-popup>
    <app-message-bar></app-message-bar>
    <app-header></app-header>
    <router-outlet *ngIf="apiReadyCheck >= 4"></router-outlet>
    <app-loading-page></app-loading-page>
    <app-product-detail-popup></app-product-detail-popup>
    <app-forgot-password-popup></app-forgot-password-popup>
    <app-side-nav-bar></app-side-nav-bar>
    <app-footer></app-footer>
    <app-cookie-block></app-cookie-block>
</div>
