<div [ngClass]="{'show': show}" class="side-nav-bar-container">
    <div>
        <div class="top" fxLayout="row" fxLayoutAlign="space-between center">
            <h5>SHOP</h5>
            <img (click)="closeSideNavBar()" src="assets/images/icon/cross-icon-dark.png" alt="">
        </div>
        <app-navigation-category-expandable-panel *ngFor="let menu of menuList" [category]="menu" [subCategories]="menu.children"></app-navigation-category-expandable-panel>
        <div class="section-2">
            <div class="menu-item">
                <a (click)="goToSale()">SALE</a>
            </div>
            <div class="menu-item">
                <a href="#/blog/all">STORIES</a>
            </div>
            <div class="menu-item">
                <a href="/#/posts/find-a-store">FIND A STORE</a>
            </div>
            <div class="menu-item">
                <a (click)="goToSizeAndFit()">SIZE & FIT GUIDES</a>
            </div>
        </div>
        <div *ngIf="!user; else loginBlock" class="section-3">
            <div (click)="goToAuth()" class="menu-item">
                <a>Sign in / Create an Account</a>
            </div>
        </div>
        <ng-template #loginBlock>
            <div class="section-3">
                <div (click)="goToMember()" class="menu-item">
                    <a>My Account</a>
                </div>
                <div class="menu-item">
                    <a href="/#/member-portal/order-history">Order History</a>
                </div>
                <div (click)="logout()" class="menu-item">
                    <a>SIGN OUT</a>
                </div>
            </div>
        </ng-template>
    </div>
</div>