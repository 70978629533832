import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Category from '@shared/models/category.model';

@Component({
  selector: 'app-product-filter-dropdown',
  templateUrl: './product-filter-dropdown.component.html',
  styleUrls: ['./product-filter-dropdown.component.scss']
})
export class ProductFilterDropdownComponent implements OnInit {

  @Input() isSale: boolean = false

  @Input() title: string;

  @Input() categories: Category[]

  @Input() filters: []

  @Input() subCategory: Category[]

  @Input() sizeList: []

  @Input() colorList: []

  @Input() manualClose: boolean

  @Output() onChangeCategory = new EventEmitter<any>()

  @Output() onChangeSize = new EventEmitter<any>()

  @Output() onChangeColor = new EventEmitter<any>()

  open: boolean = false

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if(this.manualClose) {
      this.open = false;
    }
  }

}
