import Banner from './Banner';

export default class Slider {
	id: number;
	name: string;
	startFrom: string;
	startTo: string;
    status: string;
    banners: Banner[];
    createdAt: string;
    updatedAt: string;

    constructor() {
        this.banners = []
    }
}