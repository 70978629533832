import { HttpClient } from '@angular/common/http';
import { Component, InjectionToken, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@shared/base/base.component';
import { Order } from '@shared/models/order.model';
import { ShoppingCart } from '@shared/models/shopping-cart.model';
import { CartService } from '@shared/services/cart.service';
import { OrdersService } from '@shared/services/orders.service';
import {
  StripeCardElementOptions,
  StripeElementsOptions,
  PaymentRequestPaymentMethodEvent,
  PaymentIntent,
  PaymentRequestShippingAddressEvent,
} from '@stripe/stripe-js';
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  providers: []
})
export class PaymentComponent extends BaseComponent implements OnInit {

  order: Order = new Order()

  cart: ShoppingCart = new ShoppingCart()

  paymentForm: FormGroup;

  disabled: boolean = false;

  paymentRequestOptions: any;

  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  cardOptions: StripeCardElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        iconColor: '#000000',
        color: '#31325F',
        // fontWeight: '300',
        // fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '16px',
        '::placeholder': {
          color: '#797979'
        }
      }
    }
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };

  constructor(private stripeService: StripeService, private _formBuilder: FormBuilder, private cartService: CartService, private route: ActivatedRoute, private orderService: OrdersService, private router: Router) {
    super()
  }

  ngOnInit(): void {
    this.paymentForm = this.createPaymentForm()

    this.route.data.subscribe((data: { order: Order }) => {
      this.order = data.order
      if (this.order.status !== "CREATED") {
        this.router.navigate(["/home"])
      } else {
        this.initialPaymentRequestOptions()
      }
    })

    this.cartService.shoppingCart$.subscribe(cart => {
      this.cart = cart
    })
  }

  createPaymentForm(): FormGroup {
    return this._formBuilder.group({
      isCreditCard: [false],
      cardholderName: ["", Validators.required],
    });
  }

  submitPaymentForm() {
    this.disabled = true;
    if (this.paymentForm.valid) {
      const data = this.paymentForm.getRawValue()

      const name = data.cardholderName;
      this.stripeService.createToken(this.card.element, { name }).subscribe((result) => {

        if (result.token) {
          this.orderService.paymentProcess(this.order.id, result.token.id).subscribe(data => {
            this.order = data;
            this.cartService.clearCart();
            if (this.order.status == 'PAID') {
              this.cartService.clearCart()
              this.router.navigate([`/order-confirmation/${this.order.id}`]);
            } else {
              this.orderService.cancelOrder(this.order.id);
            }
            this.disabled = false;
          }
            , (err) => {
              console.log(err);
              // this.dialog.open(PaymentInternalErrorDialogComponent);
              this.disabled = false;
              alert('Your card issuing bank declined the transaction. Please use a different card or contact your bank. Thank you.');
            })
        } else if (result.error) {
          // this.dialog.open(PaymentTryagainDialogComponent);
          console.log(result.error.message);
          this.disabled = false;
        }
      });
    } else {
      this.disabled = false;
      this.validateAllFormFields(this.paymentForm)
    }
  }

  selectPaymentMethod(method: string) {
    this.paymentForm.controls['isCreditCard'].setValue(true)
  }

  unselectPaymentMethod(method: string) {
    this.paymentForm.controls['isCreditCard'].setValue(false)
  }

  // Apple Pay implementation

  initialPaymentRequestOptions() {
    this.paymentRequestOptions = {
      country: 'HK',
      currency: this.order.currency?.toLowerCase(),
      total: {
        label: 'TOTAL',
        amount: Number(this.order.grandTotal) * 100,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    };
    console.log('initialPaymentRequestOptions::', this.paymentRequestOptions)
  }

  onPaymentMethod(ev: PaymentRequestPaymentMethodEvent) {
    console.log('onPaymentMethod::', ev)
    //walletName: "applePay" || "googlePay"
    //payment method:ev.paymentMethod.type
    this.orderService.createPaymentIntent(this.order.id)
      .pipe(switchMap((pi) => {
        console.log("created payment intent::", pi);

        return this.stripeService.confirmCardPayment(
          pi.client_secret,
          { payment_method: ev.paymentMethod.id },
          { handleActions: false }
        )
      }))
      .subscribe((result) => {
        console.log("payment result::", result);
        const paymentIntent = result.paymentIntent;
        if (paymentIntent && result.paymentIntent.status == 'succeeded') {
          ev.complete('success');
          // call api to complete order and redirect to successful page
          this.orderService.completePayment(this.order.id, ev.walletName, paymentIntent.client_secret, paymentIntent.status).subscribe(response => {
            console.log(response);

            this.cartService.clearCart();
            this.router.navigate([`/order-confirmation/${response.id}`]);
          });

        } else if (paymentIntent && result.paymentIntent.status == 'requires_action') {
          this.stripeService.confirmCardPayment(paymentIntent.client_secret).subscribe((result) => {

            if (paymentIntent && result.paymentIntent.status == 'succeeded') {
              ev.complete('success');
              // call api to complete order and redirect to successful page
              this.orderService.completePayment(this.order.id, ev.walletName, paymentIntent.client_secret, paymentIntent.status).subscribe(response => {
                this.cartService.clearCart();
                this.router.navigate([`/order-confirmation/${this.order.id}`]);
              });

            } else {
              this.cartService.clearCart();
              this.orderService.cancelOrder(this.order.id).subscribe();
              ev.complete('fail');
              alert('Your card issuing bank declined the transaction. Please use a different card or contact your bank. Thank you.');
            }
          });
        } else {
          this.cartService.clearCart();
          this.orderService.cancelOrder(this.order.id);
          ev.complete('fail');
          alert('Your card issuing bank declined the transaction. Please use a different card or contact your bank. Thank you.');
        }
      });
  }

  onNotAvailable() {
    // Subscribe to this event in case you want to act
    // base on availability
    console.log('Payment Request is not Available');
  }
  // End of Apple Pay implementation

}
