<div class="container-1440">
    <div class="page-container">
        <div class="checkout-container">
            <app-progress-bar [activeIndex]="0"></app-progress-bar>
            <div class="content-wrapper" fxLayout="row wrap" fxLayoutAlign="space-between start">
                <div class="left-container">
                    <form [formGroup]="checkoutForm">
                        <app-section-title title="Billing And Shipping" [required]="true"></app-section-title>
                        <div class="form-container">
                            <app-input type="text" placeholder="First Name *" formControlName="firstname" [error]="checkError(checkoutForm, 'firstname', 'PLEASE ENTER A FIRST NAME')"></app-input>
                            <app-input type="text" placeholder="Last Name *" formControlName="lastname" [error]="checkError(checkoutForm, 'lastname', 'PLEASE ENTER A LAST NAME')"></app-input>
                            <app-input type="text" placeholder="Email *" formControlName="email" [error]="checkError(checkoutForm, 'email', 'A VALID EMAIL IS REQUIRED')"></app-input>
                            <app-input type="text" placeholder="Phone Number *" formControlName="contact" [error]="checkError(checkoutForm, 'contact', 'A VALID PHONE NUMBER IS REQUIRED')"></app-input>
                        </div>
                        <app-section-title title="Select Shipping Method" [required]="true"></app-section-title>
                        <div class="form-container">
                            <app-select [options]="deliveryMethods" placeholder="Delivery Method *" formControlName="deliveryMethod"></app-select>
                            <app-input type="text" placeholder="Address Line 1 *" formControlName="shippingAddressLine1" [error]="checkError(checkoutForm, 'shippingAddressLine1', 'Required')"></app-input>
                            <app-input type="text" placeholder="Address Line 2 *" formControlName="shippingAddressLine2" [error]="checkError(checkoutForm, 'shippingAddressLine2', 'Required')"></app-input>
                            <app-input type="text" placeholder="City / District / Postal Code *" formControlName="shippingCity" [error]="checkError(checkoutForm, 'shippingCity', 'PLEASE ENTER A SHIPPING CITY/DISTRICT/POSTAL CODE')"></app-input>
                            <app-select [options]="countries" placeholder="Country *" formControlName="shippingCountry"></app-select>
                            <app-select [options]="packing_methods" placeholder="Select Packing Method *" formControlName="packingMethod"></app-select>
                            <app-checkbox1 label="Please tick the box if you need a Shopping Bag" formControlName="shoppingBag"></app-checkbox1>
                        </div>
                        <app-section-title title="Billing Address"></app-section-title>
                        <div class="form-container">
                            <app-checkbox1 label="Use Shipping Address" formControlName="sameAsShipping" (change)="onChangeSameAsShipping($event)"></app-checkbox1>
                            <div *ngIf="!checkoutForm.get('sameAsShipping').value">
                                <app-input type="text" placeholder="Address Line 1 *" formControlName="billingAddressLine1" [error]="checkError(checkoutForm, 'billingAddressLine1', 'Required')"></app-input>
                                <app-input type="text" placeholder="Address Line 2 *" formControlName="billingAddressLine2" [error]="checkError(checkoutForm, 'billingAddressLine2', 'Required')"></app-input>
                                <app-input type="text" placeholder="City / District / Postal Code *" formControlName="billingCity" [error]="checkError(checkoutForm, 'billingCity', 'PLEASE ENTER A BILLING CITY/DISTRICT/POSTAL CODE')"></app-input>
                                <app-select [options]="countries" placeholder="Country *" formControlName="billingCountry"></app-select>
                            </div>
                        </div>
                        <div *ngIf="errorMsg.length > 0" class="out-of-stock mt-1">{{errorMsg}}</div>
                        <div *ngIf="errorMsg.length > 0" class="mt-1">
                            <app-hover-button 
                                [text]="'BACK TO SHOPPING BAG'" 
                                [bgBefore]="'var(--thm-text-color)'" 
                                [bgAfter]="'var(--thm-blue-color-2)'" 
                                [textBefore]="'var(--thm-white-color)'" 
                                [textAfter]="'var(--thm-white-color)'"
                                (onClick)="backToShoppingcart()">
                            </app-hover-button>
                        </div>
                        <div *ngIf="isValidToOrderReview">
                            <app-hover-button 
                                text="CONTINUE TO ORDER REVIEW" 
                                [borderColor]="'var(--thm-gold-color-2)'" 
                                [bgBefore]="'var(--thm-white-color)'" 
                                [bgAfter]="'var(--thm-gold-color-3)'" 
                                [textBefore]="'var(--thm-text-color)'" 
                                [textAfter]="'var(--thm-white-color)'"
                                (onClick)="submitCheckoutForm()">
                            </app-hover-button>
                        </div>
                    </form>
                </div>
                <div class="right-container">
                    <h3>Order Summary</h3>
                    <app-order-summary [shoppingCart]="cart"></app-order-summary>
                    <app-coupon-section [lock]="true" [cart]="cart"></app-coupon-section>
                    <!-- <div class="mt-1">
                        <app-need-help-block></app-need-help-block>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>