<div class="container-full-width">
    <div class="page-container">
        <div class="size-and-fit-guides-container">
            <div class="content" fxLayout="row" fxLayoutAlign="start">
                <div #filter class="filter-wrapper">
                    <div class="size-and-fit-guides-filter-container">
                        <h5>Size & Fit Guides</h5>
                        <mat-expansion-panel class="category mat-elevation-z0" hideToggle="true" (opened)="openMen = true" (closed)="openMen = false">
                            <mat-expansion-panel-header class="category-header">
                                <mat-panel-title class="category-title">
                                    Mens
                                </mat-panel-title>
                                <mat-panel-description class="category-description">
                                    <img *ngIf="!openMen; else subtractImage" src="assets/images/icon/add-icon.svg" alt="">
                                    <ng-template #subtractImage>
                                        <img src="assets/images/icon/subtract-icon.svg" alt="">
                                    </ng-template>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <a (click)="selectCategory(category)" *ngFor="let category of menList.children">
                                {{category.name}}
                            </a>
                        </mat-expansion-panel>
                        <mat-expansion-panel class="category mat-elevation-z0" hideToggle="true" (opened)="openWomen = true" (closed)="openWomen = false">
                            <mat-expansion-panel-header class="category-header">
                                <mat-panel-title class="category-title">
                                    Womens
                                </mat-panel-title>
                                <mat-panel-description class="category-description">
                                    <img *ngIf="!openWomen; else subtractImage" src="assets/images/icon/add-icon.svg" alt="">
                                    <ng-template #subtractImage>
                                        <img src="assets/images/icon/subtract-icon.svg" alt="">
                                    </ng-template>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <a (click)="selectCategory(category)" *ngFor="let category of womenList.children">
                                {{category.name}}
                            </a>
                        </mat-expansion-panel>
                    </div>
                    <div class="size-and-fit-guides-filter-container-mobile">
                        <mat-expansion-panel [expanded]="openMen" [ngClass]="{'open': openMen}" class="filter-dropdown mat-elevation-z0" hideToggle="true" (opened)="openMen = true" (closed)="openMen = false">
                            <mat-expansion-panel-header class="filter-dropdown-header">
                                <mat-panel-title class="filter-dropdown-title">
                                    Mens
                                </mat-panel-title>
                                <mat-panel-description class="filter-dropdown-description">
                                    <img *ngIf="!openMen; else subtractImage" src="assets/images/icon/add-icon.svg" alt="">
                                    <ng-template #subtractImage>
                                        <img src="assets/images/icon/subtract-icon.svg" alt="">
                                    </ng-template>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <a class="mobile-category" (click)="selectCategory(category)" *ngFor="let category of menList.children">
                                {{category.name}}
                            </a>
                        </mat-expansion-panel>
                        <mat-expansion-panel [expanded]="openWomen" [ngClass]="{'open': openWomen}" class="filter-dropdown mat-elevation-z0" hideToggle="true" (opened)="openWomen = true" (closed)="openWomen = false">
                            <mat-expansion-panel-header class="filter-dropdown-header">
                                <mat-panel-title class="filter-dropdown-title">
                                    Womens
                                </mat-panel-title>
                                <mat-panel-description class="filter-dropdown-description">
                                    <img *ngIf="!openWomen; else subtractImage" src="assets/images/icon/add-icon.svg" alt="">
                                    <ng-template #subtractImage>
                                        <img src="assets/images/icon/subtract-icon.svg" alt="">
                                    </ng-template>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <a class="mobile-category" (click)="selectCategory(category)" *ngFor="let category of womenList.children">
                                {{category.name}}
                            </a>
                        </mat-expansion-panel>
                    </div>                    
                </div>
                <div class="size-image-wrapper">
                    <img class="desktop-image-list" *ngFor="let img of filterByCategory(sizeChartImageList)" [src]="'assets/images/size-and-fit-guides/' + img.value" alt="">
                    <img class="mobile-image-list" *ngFor="let img of filterByCategory(sizeChartMobileImageList)" [src]="'assets/images/size-and-fit-guides/mobile/' + img.value" alt="">
                </div>
            </div>
        </div>
    </div>
</div>
