<form [formGroup]="updateAddressForm " class="update-address-form">
    <h3>Update Address</h3>
    <app-select [options]="types" placeholder="Type" formControlName="type"></app-select>
    <app-input type="text" placeholder="First Name *" formControlName="firstname" [error]="checkError(updateAddressForm, 'firstname', 'PLEASE ENTER A FIRST NAME')"></app-input>
    <app-input type="text" placeholder="Last Name *" formControlName="lastname" [error]="checkError(updateAddressForm, 'lastname', 'PLEASE ENTER A LAST NAME')"></app-input>
    <app-input type="text" placeholder="Address Line 1 *" formControlName="addressLine1" [error]="checkError(updateAddressForm, 'addressLine1', 'Required')"></app-input>
    <app-input type="text" placeholder="Address Line 2" formControlName="addressLine2" [error]="checkError(updateAddressForm, 'addressLine2', 'Required')"></app-input>
    <app-select [options]="countries" placeholder="Country" formControlName="country"></app-select>
    <app-input type="text" placeholder="City / District / Postal Code" formControlName="city" [error]="checkError(updateAddressForm, 'city', 'PLEASE ENTER A BILLING CITY/DISTRICT/POSTAL CODE')"></app-input>
    <app-input type="text" placeholder="Enter Your Phone Number" formControlName="contact" [error]="checkError(updateAddressForm, 'contact', 'A VALID PHONE NUMBER IS REQUIRED.')"></app-input>
    <app-checkbox1 label="Make This My Default Address" [withMarginBottom]="false" formControlName="default"></app-checkbox1>
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div class="w-70">
            <app-button text="Update Address" color="var(--thm-text-hover-color)" (onClick)="submitUpdateAddressForm()"></app-button>
        </div>
        <div class="w-25 cancel" (click)="cancel()">CANCEL</div>
    </div>
</form>