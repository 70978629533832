import { Pipe, PipeTransform } from "@angular/core";
import { ProductSku } from "@shared/models/product-sku.model"

@Pipe({
  name: "formatDate",
  pure: false
})
export class FormatDate implements PipeTransform {
  transform(date: string): string {
    const d = new Date(date.replace(/ /g,"T"))
    return `${d.getFullYear()}-${("0" + (d.getMonth() + 1)).slice(-2)}-${("0" + d.getDate()).slice(-2)}`
  }
}