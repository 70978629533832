<div *ngIf="!isCookieAccepted()" class="cookie-block-wrapper">
    <div class="cookie-block-container">
        <div class="message">
            We use cookies for analytics and advertising and to improve our site. By closing this message box or continuing to use our site,<br>
            you agree to our use of cookies. To find out more, see our <a href="/#/posts/privacy-policy">Privacy Policy</a>.
        </div>
        <div class="button">
            <app-hover-button 
                [text]="'I Accept'" 
                [borderColor]="'var(--thm-white-color)'" 
                [bgBefore]="'var(--thm-text-color)'" 
                [bgAfter]="'var(--thm-text-color)'" 
                [textBefore]="'var(--thm-white-color)'" 
                [textAfter]="'var(--thm-white-color)'"
                [height]="'44px'"
                (onClick)="accept()">
            </app-hover-button>
        </div>
    </div>
</div>