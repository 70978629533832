<div *ngIf="show" class="product-detail-popup-container" fxLayout="row" fxLayoutAlign="center center">
    <div fxLayout="column" fxLayoutAlign="center" class="product-detail-popup-wrapper">
        <div class="top-container">
            <div fxLayout="row" fxLayoutAlign="space-between">
                <h3>{{product.productSkus[0].productSkuEavs | getValueByAttribute:'name':'value'}}</h3>
                <img (click)="close()" src="assets/images/icon/cross-icon-dark.png" alt="">
            </div>
            <a (click)="gotoDetail()">View Full Details<span class="arrow"></span></a>
        </div>
        <div class="content-wrapper">
            <app-product-content [product]="product" [cartItem]="item"></app-product-content>
        </div>
    </div>
</div>