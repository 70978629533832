export class PaymentWalletCompleteRequest {
    id: string;
    wallet_name: string;
    client_secret: string;
    status: string;

    constructor(id: string, wallet_name: string, client_secret: string, status: string) {
        this.id = id;
        this.wallet_name = wallet_name;
        this.client_secret = client_secret;
        this.status = status;
    }
}
