import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '@shared/base/base.component';
import { AuthService } from '@shared/services/auth.service';
import { JwtAuthService } from '@shared/services/auth/jwt-auth.service';
import { CommonService } from '@shared/services/common.service';
import { MemberService } from '@shared/services/member.service';

@Component({
  selector: 'app-coupon-popup',
  templateUrl: './coupon-popup.component.html',
  styleUrls: ['./coupon-popup.component.scss']
})
export class CouponPopupComponent extends BaseComponent implements OnInit {

  genders = [
    {
      text: '',
      value: '',
    },
    {
      text: 'Male',
      value: 'Male',
    },
    {
      text: 'Female',
      value: 'Female',
    },
    {
      text: 'Not applicable',
      value: 'Not applicable',
    },
  ];

  promotionForm: FormGroup

  show: boolean = false

  content2: string = ""

  isLoggedIn: Boolean = false

  closed: boolean = false

  constructor(private commonService: CommonService, private jwtAuthService: JwtAuthService, private _formBuilder: FormBuilder, private memberService: MemberService) { 
    super()
  }

  ngOnInit(): void {
    this.promotionForm = this.createPromotionForm()
    this.commonService.showCoupon$.subscribe(show => {
      //console.log("coupon::" + show);
      this.show = show
    })
  }

  createPromotionForm(): FormGroup {
    return this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      gender: ['', Validators.required],
      marketing: [false],
    });
  }
  
  submitPromotionForm() {
    if(this.promotionForm.valid) {
      const data = this.promotionForm.getRawValue()
      this.memberService.subscribeCoupon(data).subscribe(rsp => {
        this.close()
        this.content2 = `${data.email} is now subscribed to receive Brooks Brothers emails.`
        this.isLoggedIn = this.jwtAuthService.isLoggedIn();
        if (!this.isLoggedIn) {
          console.log("entered");
          this.closed = true;
          this.commonService.openSimplePopup("thank-coupon");
        }
      })
      console.log("data::" + JSON.stringify(data));
      
    }else {
      this.validateAllFormFields(this.promotionForm)
    }
  }

  close() {
    this.commonService.closeCouponPopup()
  }

  closePopup() {
    this.commonService.closeSimplePopup("thank-coupon")
    this.closed = false
    console.log("closed::" + this.closed);
    
  }

}
