import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { JwtAuthService } from '@shared/services/auth/jwt-auth.service';
import { ConfigurationService } from '@shared/services/config.service';
import { SlidersService } from '@shared/services/sliders.service';
import { DisplaySizeService } from '@shared/services/display-size.service';
import * as AOS from 'aos';
import { ProductsService } from '@shared/services/products.service';
import { ProductSkuFilterRequest } from '@shared/models/request/product-sku-filter-request';
import { getRandomItem } from '@shared/helpers/util';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Brooks Brothers Singapore';

  apiReadyCheck: number = 0

  constructor(private displaySizeService: DisplaySizeService,
    private breakpointObserver: BreakpointObserver,
    private translate: TranslateService,
    private sliderService: SlidersService,
    private jwtAuthService: JwtAuthService,
    private configurationService: ConfigurationService,
    private productService: ProductsService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('en');
  }

  ngOnInit() {
    AOS.init();

    //Observe mobile state
    this.breakpointObserver.observe(['(min-width: 992px)']).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.displaySizeService.isMobileBS.next(false)
      } else {
        this.displaySizeService.isMobileBS.next(true)
      }
    });

    this.sliderService.getAllSlider().then(sliders => {
      this.jwtAuthService.setSlider(sliders)
      this.apiReadyCheck++
    })

    this.configurationService.getAllConfig().subscribe(configs => {
      this.jwtAuthService.setConfiguration(configs)
      this.apiReadyCheck++
    })

    //Mens' Products
    let filter = new ProductSkuFilterRequest()
    filter.categoryIds.push(270)
    this.productService.getProductList(0, 'id,displayOrder', "20", 'DESC', filter).subscribe(products => {
      const randomMenNewArrival = [];
      if (products.content.length <= 3) {
        randomMenNewArrival.push(products.content);
      } else {
        while (randomMenNewArrival.length < 3) {
          const item = getRandomItem(products.content);

          if (randomMenNewArrival.indexOf(item) < 0)
            randomMenNewArrival.push(item)
        }
      }

      this.jwtAuthService.setMenNewArrival(randomMenNewArrival)
      this.apiReadyCheck++
    })

    //Womens' Products
    filter = new ProductSkuFilterRequest()
    filter.categoryIds.push(263)
    this.productService.getProductList(0, 'id,displayOrder', "20", 'DESC', filter).subscribe(products => {
      const randomWomenNewArrival = [];
      if (products.content.length <= 3) {
        randomWomenNewArrival.push(products.content);
      } else {
        while (randomWomenNewArrival.length < 3) {
          const item = getRandomItem(products.content);

          if (randomWomenNewArrival.indexOf(item) < 0)
            randomWomenNewArrival.push(item)
        }
      }

      this.jwtAuthService.setWomenNewArrival(randomWomenNewArrival)
      this.apiReadyCheck++
    })
  }
}
