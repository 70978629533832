import { Injectable } from "@angular/core";
import { SessionStoreService } from "../session-store.service";
import { Router, ActivatedRoute } from "@angular/router";
import { map, catchError } from "rxjs/operators";
import { of, BehaviorSubject, throwError, Observable } from "rxjs";

import { Customer } from '@shared/models/customer.model';
import Configuration from '@shared/models/configuration.model';
import Slider from '@shared/models/Slider';
import { Product } from '@shared/models/product.model';

@Injectable({
  providedIn: "root",
})
export class JwtAuthService {
  USER = "USER";
  CONFIG = "CONFIG";
  SLIDER = "SLIDER";
  MEN_NEW_ARRIVAL = "MEN_NEW_ARRIVAL"
  WOMEN_NEW_ARRIVAL = "WOMEN_NEW_ARRIVAL"
  TOP_SELLING = "TOP_SELLING"
  JWT_TOKEN = "JWT_TOKEN";
  GUEST_CART_ID = "GUEST_CART_ID"
  USER_CART_ID = "USER_CART_ID"

  token;
  isAuthenticated: Boolean;
  user: Customer = null;
  user$ = (new BehaviorSubject<Customer>(this.user));
  configs: Configuration[] = null;
  configs$ = (new BehaviorSubject<Configuration[]>(this.configs));
  sliders: Slider[] = null;
  sliders$ = (new BehaviorSubject<Slider[]>(this.sliders));
  menNewArrivals: Product[] = null;
  menNewArrivals$ = (new BehaviorSubject<Product[]>(this.menNewArrivals));
  womenNewArrivals: Product[] = null;
  womenNewArrivals$ = (new BehaviorSubject<Product[]>(this.womenNewArrivals));
  signingIn: Boolean;
  return: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ss: SessionStoreService
  ) {
    this.route.queryParams
      .subscribe(params => this.return = params['return'] || '/');
  }

  public signOut() {
    console.log("signOut::start");
    this.setUserAndToken(null, null, null);
    this.ss.clear();
    this.router.navigateByUrl("auth");
  }

  isLoggedIn(): Boolean {
    return this.getJwtToken() != null ? true : false;
  }

  getJwtToken() {
    return this.ss.getItem(this.JWT_TOKEN);
  }

  getUser(): Observable<Customer> {
    return of(this.ss.getItem(this.USER));
  }

  getConfig(): Observable<Configuration[]> {
    return of(this.ss.getItem(this.CONFIG));
  }

  getSlider(): Observable<Slider[]> {
    return of(this.ss.getItem(this.SLIDER));
  }

  getMenNewArrival(): Observable<Product[]> {
    return of(this.ss.getItem(this.MEN_NEW_ARRIVAL));
  }

  getWomenNewArrival(): Observable<Product[]> {
    return of(this.ss.getItem(this.WOMEN_NEW_ARRIVAL));
  }

  getUserFirstname(): Observable<string>{
    return of(this.ss.getItem(this.USER).firstname);
  }

  setUserAndToken(token: string, user: Customer, isAuthenticated: Boolean) {
    this.token = token;
    this.isAuthenticated = isAuthenticated;
    this.user$.next(user);
    this.ss.setItem(this.JWT_TOKEN, token);
    this.ss.setItem(this.USER, user);
  }

  setUser(user: Customer) {
    this.user$.next(user);
    this.ss.setItem(this.USER, user);
  }

  setConfiguration(configs: Configuration[]) {
    this.configs$.next(configs);
    this.ss.setItem(this.CONFIG, configs);
  }

  setSlider(sliders: Slider[]) {
    this.sliders$.next(sliders);
    this.ss.setItem(this.SLIDER, sliders);
  }

  setMenNewArrival(newArrival: Product[]) {
    this.menNewArrivals$.next(newArrival);
    this.ss.setItem(this.MEN_NEW_ARRIVAL, newArrival);
  }

  setWomenNewArrival(newArrival: Product[]) {
    this.womenNewArrivals$.next(newArrival);
    this.ss.setItem(this.WOMEN_NEW_ARRIVAL, newArrival);
  }

  setToken(token: string, isAuthenticated: Boolean) {
    this.token = token;
    this.isAuthenticated = isAuthenticated;
    this.ss.setItem(this.JWT_TOKEN, token);
  }

  getUpdatedUser(): Observable<Customer> {
    return this.user$.asObservable()
  }
}
