import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-color-select',
  templateUrl: './color-select.component.html',
  styleUrls: ['./color-select.component.scss']
})
export class ColorSelectComponent implements OnInit {

  @Input() color: string;

  @Input() shape: string = "circle"

  @Input() outerSize: string;

  @Input() innerSize: string;

  @Input() value: string = "";

  @Output() onClick = new EventEmitter<any>();

  @Output() onChange = new EventEmitter<any>();

  @Input() selected: boolean = false

  constructor() { }

  ngOnInit(): void {
  }

  click(){
    this.selected = !this.selected
    this.onChange.emit(this.selected)
    this.onClick.emit(this.value)
  }
}
