import { AfterViewInit, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-instagram-block',
  templateUrl: './instagram-block.component.html',
  styleUrls: ['./instagram-block.component.scss']
})
export class InstagramBlockComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  // ngAfterViewInit(): void {
  //   setTimeout(() => {
  //     const element: any = document.getElementsByClassName("eapps-link")[0]
  //     element.style["display"] = "none"
  //   }, 2000)
  // }

}
