import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {

  @Input() currentPage: number;

  @Input() totalPage: number;

  @Output() onPageChange = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  click(pageNum: number) {
    if(pageNum < 0 || pageNum >= this.totalPage) {
      return
    }
    this.onPageChange.emit(pageNum)
  }

}
