import { Component, Input, OnInit } from '@angular/core';
import { CartItem } from '@shared/models/cart-item.model';
import { ProductSku } from '@shared/models/product-sku.model';
import { ShoppingCart } from '@shared/models/shopping-cart.model';
import { CartService } from '@shared/services/cart.service';
import { CommonService } from '@shared/services/common.service';
import { ProductsService } from '@shared/services/products.service';

@Component({
  selector: 'app-shopping-cart-table',
  templateUrl: './shopping-cart-table.component.html',
  styleUrls: ['./shopping-cart-table.component.scss'],
})
export class ShoppingCartTableComponent implements OnInit {
  @Input() shoppingCart: ShoppingCart;

  constructor(
    private commonService: CommonService,
    private cartService: CartService,
    private productService: ProductsService
  ) {}

  ngOnInit(): void {}

  edit(cartItem: CartItem) {
    this.productService
      .getProductBySku(cartItem.productSku.sku)
      .subscribe((product) => {
        this.productService.setProductToPopUp(product);
        this.productService.setCartItemToUpdate(cartItem);
        this.commonService.openProductDetailPopup();
      });
  }

  onChangeQty(qty: number, item: CartItem) {
    if (qty <= 0) {
      this.removeCartItem(item);
      return;
    }
    item.quantity = qty;
    this.cartService.updateCart(item).subscribe((data: ShoppingCart) => {
      this.shoppingCart = data;
      this.cartService.setCart(data);
    });
  }

  removeCartItem(item: CartItem) {
    this.cartService.deleteCartItem(item).subscribe((data: ShoppingCart) => {
      this.shoppingCart = data;
      this.cartService.setCart(data);
    });
  }

  isSalesPrice(productSku: ProductSku): boolean {
    if (productSku.salesFrom && productSku.salesTo && productSku.salesPrice) {
      let today = new Date().getTime() / 1000;
      if (today > productSku.salesFrom && today < productSku.salesTo) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isOutOfStock(productSku: ProductSku, qty: number): boolean {
    let availableStock = productSku.stock - productSku.minStockLevel;
    return availableStock < qty ? true : false;
  }

  isDisabled(productSku: ProductSku): boolean {
    return productSku.status == 'DISABLED' ? true : false;
  }
}
