<div class="container-1440">
    <div class="page-container">
        <div class="order-review-container">
            <app-progress-bar [activeIndex]="1"></app-progress-bar>
            <div class="content-wrapper" fxLayout="row wrap" fxLayoutAlign="space-between start">
                <div class="left-container">
                    <app-order-review-content [order]="order"></app-order-review-content>
                </div>
                <div class="right-container">
                    <h3>Order Summary</h3>
                    <app-order-summary [shoppingCart]="order"></app-order-summary>
                    <app-coupon-section [lock]="true" [cart]="cart"></app-coupon-section>
                    <div class="mt-1">
                        <app-hover-button 
                            text="CONTINUE TO PAYMENT" 
                            [borderColor]="'var(--thm-gold-color-2)'" 
                            [bgBefore]="'var(--thm-white-color)'" 
                            [bgAfter]="'var(--thm-gold-color-3)'" 
                            [textBefore]="'var(--thm-text-color)'" 
                            [textAfter]="'var(--thm-white-color)'"
                            (onClick)="continueToPayment()">
                        </app-hover-button>
                    </div>
                    <!-- <div class="mt-1">
                        <app-need-help-block></app-need-help-block>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>