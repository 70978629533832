<div class="product-content-container" fxLayout="row wrap" fxLayoutAlign="start">
    <div fxLayout="column" fxLayoutAlign="start" class="left-container">
        <div class="d-none-m" fxLayout="row" fxLayoutAlign="start">
            <div class="product-images">
                <div (click)="selectImage(i)" *ngFor="let image of images; index as i">
                    <img [ngClass]="{'selected': i === selectedImageIndex}" [src]="image" alt="">
                </div>
            </div>
            <div #thumbnail (mousemove)="thumbnailHover($event)" [ngStyle]="{'background-image': 'url(' + images[selectedImageIndex] + ')', 'background-position': thumbnailBackgroundPosition}" class="thumb-image">
                <img [src]="images[selectedImageIndex]" alt="">
            </div>
        </div>
        <div class="d-none-d mobile-image-container">
            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">
                <div *ngFor="let image of images" ngxSlickItem class="slide" style="outline: none; box-shadow: none;">
                    <img (click)="viewImage(image)" [src]="image" alt="">
                </div>
            </ngx-slick-carousel>
        </div>
    </div>
    <div class="right-container">
        <h4 class="product-name">{{selectedProductName}}</h4>
        <h4 class="sku">{{itemNo}}</h4>
        <div class="price-container" fxLayout="row">
            <h4 *ngIf="isSalesPrice(sku ? sku : product.productSkus[0])" class="sale-price">$ {{((sku ? sku : product.productSkus[0]) | getProductSalesPriceIfAny) | number}}</h4>
            <h4 [ngClass]="{'deleted': isSalesPrice(sku ? sku : product.productSkus[0])}" class="product-price">$ {{(sku ? sku.price : product.productSkus[0].price) | number}}</h4>
            <div *ngIf="isSalesPrice(sku ? sku : product.productSkus[0])" class="discount-badge"><span>-{{(100 - (((sku ? sku : product.productSkus[0]) | getProductSalesPriceIfAny) / (sku ? sku.price : product.productSkus[0].price) * 100)) | round }}%</span></div>
        </div>
        <div class="product-color">COLOR :<span>{{selectedColor}}</span></div>
        <div class="select-color-container" fxLayout="row wrap" fxLayoutAlign="start">
            <img *ngFor="let color of colorList; index as i" [ngClass]="{'selected': color.value === selectedColor}" (click)="selectColor(color.value)" [src]="color.imgUrl" alt="">
        </div>
        <div [ngClass]="{'empty': sizeIsEmpty || fitIsEmpty || inseamIsEmpty}">
            <div *ngIf="fitList && fitList.length > 0">
                <div class="product-fit">FIT</div>
                <div class="sizing-guide">
                    <a (click)="gotoSizingGuide()">FIT GUIDE</a>
                </div>
                <div class="select-fit-container" fxLayout="row wrap">
                    <div (click)="selectFit(fit)" *ngFor="let fit of fitList; index as i">
                        <app-hover-button 
                            [text]="fit" 
                            [borderColor]="'var(--thm-text-hover-color)'" 
                            [bgBefore]="'var(--thm-white-color)'" 
                            [bgAfter]="'var(--thm-text-hover-color)'" 
                            [textBefore]="'var(--thm-text-color)'" 
                            [textAfter]="'var(--thm-white-color)'"
                            [selected]="fit === selectedFit"
                            [height]="'42px'">
                        </app-hover-button>
                    </div>
                </div>
            </div>
            <div>
                <div class="product-size">SIZE</div>
                <div class="sizing-guide">
                    <a (click)="gotoSizingGuide()">SIZING GUIDE</a>
                </div>
                <div class="select-size-container" fxLayout="row wrap" fxLayoutAlign="start">
                    <app-size-box *ngFor="let size of fullSizeList; index as i" [disabled]="!sizeExist(size)" [size]="size" [selected]="selectedSize === size" (onClick)="selectSize($event)"></app-size-box>
                </div>
            </div>
            <div *ngIf="fullInseamList && fullInseamList.length > 0">
                <div class="product-inseam">INSEAM</div>
                <div class="select-inseam-container" fxLayout="row wrap" fxLayoutAlign="start">
                    <app-size-box *ngFor="let inseam of fullInseamList; index as i" [size]="inseam" [disabled]="!inseamExist(inseam)" [selected]="selectedInseam === inseam" (onClick)="selectInseam($event)"></app-size-box>
                </div>
            </div>
        </div>
        <div class="error">
            Please select above measurements
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" class="quantity-container">
            <div>QUANTITY</div>
            <div>
                <app-quantity-button (onChange)="quantityOnChange($event)" [(quantity)]="quantity"></app-quantity-button>
            </div>
        </div>
        <div class="button-container">
            <app-hover-button 
                [text]="getButtonText()" 
                [borderColor]="'var(--thm-text-hover-color)'" 
                [bgBefore]="'var(--thm-text-hover-color)'" 
                [bgAfter]="'var(--thm-text-hover-color)'" 
                [textBefore]="'var(--thm-white-color)'" 
                [textAfter]="'var(--thm-text-color)'"
                (onClick)="addToCart()"
                [disabled]="sku ? isOutOfStock(sku) : false">
            </app-hover-button>
        </div>
        <div class="information">
            <a href="/#/posts/delivery-and-returns" target="_blank">SHIPPING INFO</a>
            <a href="/#/posts/delivery-and-returns" target="_blank">RETURN POLICY</a>
            <a href="/#/contact-us/home" target="_blank">HELP</a>
        </div>
        <div class="social">
            <div>SHARE</div>
            <div class="social-icon-container" fxLayout="row" fxLayoutAlign="start center">
                <a href="https://www.facebook.com/BrooksBrothersSG" target="_blank" class="icon icon-1"></a>
                <a href="https://www.instagram.com/brooksbrothers.singapore/" target="_blank" class="icon icon-3"></a>
            </div>
        </div>
    </div>
</div>

<div *ngIf="popupImageUrl" class="mobile-image-popup">
    <img class="photo" [src]="popupImageUrl" alt="">
    <img class="cross" (click)="closeViewImage()" src="assets/images/icon/cross-icon-dark.png" alt="">
</div>