<div fxLayout="row" fxLayoutAlign="start center">
    <input [disabled]="disabled || lock" [(ngModel)]="value" [type]="type" [placeholder]="placeholder">
    <div *ngIf="!disabled; else removeBlock" (click)="apply()" fxLayout="column" fxLayoutAlign="center center" class="button">
        APPLY
    </div>
    <ng-template #removeBlock>
        <div (click)="remove()" fxLayout="column" fxLayoutAlign="center center" class="button">
            REMOVE
        </div>
    </ng-template>
    
</div>