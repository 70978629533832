<div tabindex="1" (blur)="blur()" (click)="toggle()" class="select-box" [ngClass]="{'open': open}">
    <span>
        Sort By: {{selected}}
    </span>
    <div class="sign">
        <img *ngIf="!open; else subtractImage" src="assets/images/icon/add-icon.svg" alt="">
        <ng-template #subtractImage>
            <img src="assets/images/icon/subtract-icon.svg" alt="">
        </ng-template>
    </div>
    <div *ngIf="open" class="dropdown">
        <div [class]="selected === option ? 'selected': ''" (click)="select(option)" *ngFor="let option of options">
            {{option}}
        </div>
    </div>
</div>

<mat-expansion-panel [expanded]="open" [ngClass]="{'open': open}" class="sorting-dropdown mat-elevation-z0" hideToggle="true" (opened)="open = true" (closed)="open = false">
    <mat-expansion-panel-header class="sorting-dropdown-header">
        <mat-panel-title class="sorting-dropdown-title">
            {{selected}}
        </mat-panel-title>
        <mat-panel-description class="sorting-dropdown-description">
            <img *ngIf="!open; else subtractImage" src="assets/images/icon/add-icon.svg" alt="">
            <ng-template #subtractImage>
                <img src="assets/images/icon/subtract-icon.svg" alt="">
            </ng-template>
        </mat-panel-description>
    </mat-expansion-panel-header>
    <a *ngFor="let option of options; index as i" [ngClass]="{'selected': selected === option}" (click)="select(option)">{{option}}</a>
</mat-expansion-panel>