import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { map, take } from 'rxjs/operators';

import { ProductSkuFilterRequest } from '@shared/models/request/product-sku-filter-request';

import { HttpService } from '@shared/services/http.service';
import {SessionStoreService} from "@shared/services/session-store.service";

const SEND_CONTACT_US = 'customize/contact-us';
const SUBSCRIBE_US = 'customize/subscribe-us';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService{

  constructor(private httpService: HttpService, private ss: SessionStoreService) { }

  sendContactUsEmail(contactUsRequest: any): Observable<any> {
    return this.httpService.post(SEND_CONTACT_US, { body: contactUsRequest, responseType: "text" });
  }

  sendSubscribeUsEmail(subscribeUsRequest: any): Observable<any> {
    return this.httpService.post(SUBSCRIBE_US, { body: subscribeUsRequest, responseType: "text" });
  }

}
