<div class="container-full-width">
    <div class="page-container">
        <div class="home-container">
            <app-coupon-popup id="coupon-popup" title="Enjoy 10% off"></app-coupon-popup>
            <div *ngIf="slider1" class="home-slider slider1">
                <app-slider [slideConfig]="homeTopSliderConfig" [mobileSlider]="slider1M" [desktopSlider]="slider1">
                    <div class="desc-1">
                        <ng-template let-banner>
                            <div fxLayout="column" fxLayoutAlign="start center" class="desc-1">
                                <h5 *ngIf="banner.name">{{banner.name}}</h5>
                                <h1 *ngIf="banner.nameZh">{{banner.nameZh}}</h1>
                                <div *ngIf="banner.title" class="button-container">
                                    <app-hover-button [text]="banner.title" [borderColor]="'var(--thm-white-color)'"
                                        [bgBefore]="'transparent'" [bgAfter]="'transparent'"
                                        [textBefore]="'var(--thm-white-color)'" [textAfter]="'var(--thm-white-color)'"
                                        [height]="'44px'" [url]="banner.url">
                                    </app-hover-button>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </app-slider>
            </div>
            <div *ngIf="menNewArrivalsProducts && womenNewArrivalsProducts" fxLayout="row wrap"
                fxLayoutAlign="space-between" class="new-arrival p-30">
                <div class="left-container">
                    <h5>New Arrivals</h5>
                    <div class="category">
                        <h1 [ngClass]="{'selected': newArrivalSelected === 'Men'}" (click)="selectNewArrival('Men')"
                            fxLayout="row" fxLayoutAlign="start center">
                            <div class="line"></div>Mens
                        </h1>
                        <h1 [ngClass]="{'selected': newArrivalSelected !== 'Men'}" (click)="selectNewArrival('Women')"
                            fxLayout="row" fxLayoutAlign="start center">
                            <div class="line"></div>Womens
                        </h1>
                    </div>
                </div>
                <div class="right-container">
                    <div class="w-100">
                        <app-product-slider divison='20px' [slideConfig]="newArrivalProductSlideConfig"
                            [products]="newArrivalSelected === 'Men' ? menNewArrivalsProducts : womenNewArrivalsProducts"
                            title1="" title2=""></app-product-slider>
                    </div>
                    <div class="link">
                        <app-underline-link-text (click)="goToNewArrival()" content="Shop {{newArrivalSelected}}'s New Arrivals">
                        </app-underline-link-text>
                    </div>
                </div>
            </div>
            <div *ngIf="isSliderValid(slider2A) && isSliderValid(slider2B)" fxLayout="row"
                fxLayoutAlign="space-between center" class="slider2">
                <app-slider [desktopSlider]="slider2A">
                    <ng-template let-banner>
                        <div class="desc-2">
                            <h5 *ngIf="banner.name">{{banner.name}}</h5>
                            <div *ngIf="banner.nameZh">{{banner.nameZh}}</div>
                            <div *ngIf="banner.title">
                                <app-underline-link-text [url]="banner.url" [content]="banner.title">
                                </app-underline-link-text>
                            </div>
                        </div>
                    </ng-template>
                </app-slider>
                <app-slider [desktopSlider]="slider2B">
                    <ng-template let-banner>
                        <div class="desc-2">
                            <h5 *ngIf="banner.name">{{banner.name}}</h5>
                            <div *ngIf="banner.nameZh">{{banner.nameZh}}</div>
                            <div *ngIf="banner.title">
                                <app-underline-link-text [url]="banner.url" [content]="banner.title">
                                </app-underline-link-text>
                            </div>
                        </div>
                    </ng-template>
                </app-slider>
            </div>
            <div *ngIf="isSliderValid(slider3)" class="home-slider slider3">
                <app-slider [desktopSlider]="slider3"></app-slider>
            </div>
            <div *ngIf="isSliderValid(slider4)" class="home-slider slider4">
                <app-slider [desktopSlider]="slider4"></app-slider>
            </div>
            <div *ngIf="isSliderValid(slider5A) && isSliderValid(slider5B)" fxLayout="row"
                fxLayoutAlign="space-between center" class="slider5">
                <app-slider [desktopSlider]="slider5A"></app-slider>
                <app-slider [desktopSlider]="slider5B"></app-slider>
            </div>
            <div *ngIf="isSliderValid(slider6)" fxLayout="row wrap" fxLayoutAlign="space-between start"
                class="home-slider slider6">
                <div class="slider6-wrapper">
                    <div class="content">
                        <h5 *ngIf="slider6CurrentBanner.name">{{slider6CurrentBanner.name}}</h5>
                        <div class="line"></div>
                        <h1 *ngIf="slider6CurrentBanner.nameZh">{{slider6CurrentBanner.nameZh}}</h1>
                        <div *ngIf="slider6CurrentBanner.title" class="desc">{{slider6CurrentBanner.title}}</div>
                        <div class="button-container">
                            <app-hover-button [text]="slider6CurrentBanner.titleZh"
                                [borderColor]="'var(--thm-text-color)'" [bgBefore]="'transparent'"
                                [bgAfter]="'transparent'" [textBefore]="'var(--thm-text-color)'"
                                [textAfter]="'var(--thm-text-color)'" [height]="'44px'"
                                [url]="slider6CurrentBanner.url">
                            </app-hover-button>
                        </div>
                    </div>
                </div>
                <div class="banner">
                    <app-slider (onSlideChange)="setSlider6Banner($event)" [desktopSlider]="slider6"></app-slider>
                </div>
            </div>
            <div *ngIf="isSliderValid(slider7)" fxLayout="column" fxLayoutAlign="start center"
                class="home-slider slider7">
                <div class="slider7-wrapper">
                    <div class="content">
                        <h5 *ngIf="slider7CurrentBanner.name">{{slider7CurrentBanner.name}}</h5>
                        <div fxLayout="row wrap" fxLayoutAlign="space-between start">
                            <div class="left-container">
                                <div class="line"></div>
                                <h1 *ngIf="slider7CurrentBanner.nameZh">{{slider7CurrentBanner.nameZh}}</h1>
                            </div>
                            <div class="right-container">
                                <div *ngIf="slider7CurrentBanner.title" class="desc">{{slider7CurrentBanner.title}}
                                </div>
                                <div *ngIf="slider7CurrentBanner.titleZh">
                                    <app-underline-link-text [url]="slider7CurrentBanner.url" [content]="slider7CurrentBanner.titleZh">
                                    </app-underline-link-text>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="banner">
                    <app-slider (onSlideChange)="setSlider7Banner($event)" [desktopSlider]="slider7"></app-slider>
                </div>
            </div>
            <div>
                <app-instagram-block></app-instagram-block>
            </div>
        </div>
    </div>
</div>