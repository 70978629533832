<div fxLayout="column" fxLayoutAlign="start center" class="message-bar-wrapper">
    <div fxLayout="row" fxLayoutAlign="center center" class="message-bar-container">
        <div fxLayout="row" fxLayoutAlign="center center" class="message-content">
            <div (click)="prev()" class="arrow-container prev">
                <img src="assets/images/icon/message-bar-prev-arrow-icon.png" alt="">
            </div>
            <div [class]="currentIndex === i ? 'message-wrapper active' : 'message-wrapper'" *ngFor="let banner of slider.banners; index as i">
                <div class="message-container" fxLayout="row" fxLayoutAlign="center center">
                    <span class="message">{{banner.title}}</span>
                    <img *ngIf="!showContent" (click)="toggle()" class="plus" src="assets/images/icon/gold-plus-icon.svg" alt="">
                </div>
            </div>
            <div (click)="next()" class="arrow-container next">
                <img src="assets/images/icon/message-bar-next-arrow-icon.png" alt="">
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" class="right-container">
            <a fxLayout="row" fxLayoutAlign="start center" href="/#/posts/find-a-store">
                <img class="location icon" src="assets/images/icon/location-white.svg"/>
                Store Locator
            </a>
        </div>
    </div>
    <div class="content" *ngIf="slider && slider.banners && slider.banners.length > 0 && showContent">
        <div fxLayout="column" fxLayoutAlign="center" class="content-wrapper">
            <img *ngIf="slider.banners[currentIndex].type === 'IMAGE'; else youtube" [src]="slider.banners[currentIndex].domain + slider.banners[currentIndex].path" (click)="openLink()" alt="message">
            <ng-template #youtube>
                <app-youtube [url]="slider.banners[currentIndex].url"></app-youtube>
            </ng-template>
            <img (click)="closeToggle()" class="cross" src="assets/images/icon/close-icon-white.svg" alt="">
            <img (click)="prev()" class="prev-arrow arrow" src="assets/images/icon/message-bar-prev.svg" alt="">
            <img (click)="next()" class="next-arrow arrow" src="assets/images/icon/message-bar-next.svg" alt="">
        </div>
    </div>
</div>
