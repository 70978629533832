import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  loadingRequest: HttpRequest<any>[] = []

  sideNavbarIsShowSource = new BehaviorSubject(false);
  sideNavbarIsShow = this.sideNavbarIsShowSource.asObservable()

  private showLoadingPageSource = new BehaviorSubject(false);
  showLoadingPage = this.showLoadingPageSource.asObservable()

  private showProductDetailPopupSource = new BehaviorSubject(false);
  showProductDetailPopup = this.showProductDetailPopupSource.asObservable()

  private showSideNavBarSource = new BehaviorSubject(false);
  showSideNavBar = this.showSideNavBarSource.asObservable()

  private showHeaderCartBS = new BehaviorSubject(false);
  showHeaderCart$ = this.showHeaderCartBS.asObservable()

  private showForgotPasswordBS = new BehaviorSubject(false);
  showForgotPassword$ = this.showForgotPasswordBS.asObservable()

  private showCouponBS = new BehaviorSubject(false);
  showCoupon$ = this.showCouponBS.asObservable()

  private showSearchBS = new BehaviorSubject(false);
  showSearch$ = this.showSearchBS.asObservable()

  private showSimplePopupBS = new BehaviorSubject("");
  showSimplePopup$ = this.showSimplePopupBS.asObservable()

  startLoading(request: HttpRequest<any>) {
    //Push request if not exist
    if(this.loadingRequest.findIndex(req => req.url === request.url) < 0) {
      this.loadingRequest.push(request)
    }else if(request.body) {
      let exist: boolean = false
      this.loadingRequest.forEach(req => {
        if(req.body) {
          if(JSON.stringify(req.body) === JSON.stringify(request.body)) {
            exist = true
          }
        }
      })
      if(!exist) {
        this.loadingRequest.push(request)
      }
    }
    this.showLoadingPageSource.next(true)
  }

  stopLoading(request: HttpRequest<any>) {
    window.scroll(0, 0)
    const index = this.loadingRequest.findIndex(req => req.url === request.url);
    this.loadingRequest.splice(index, 1);
    if(this.loadingRequest.length <= 0) {
      this.showLoadingPageSource.next(false)
    }
  }

  openProductDetailPopup() {
    this.showProductDetailPopupSource.next(true)
    this.openPopup()
  }

  closeProductDetailPopup() {
    this.showProductDetailPopupSource.next(false)
    this.closePopup()
  }

  openSideNavBar() {
    this.showSideNavBarSource.next(true)
  }

  closeSideNavBar() {
    this.showSideNavBarSource.next(false)
  }

  openHeaderCart() {
    this.showHeaderCartBS.next(true)
  }

  closeHeaderCart() {
    this.showHeaderCartBS.next(false)
  }

  openForgotPasswordPopup() {
    this.showForgotPasswordBS.next(true)
  }

  closeForgotPasswordPopup() {
    this.showForgotPasswordBS.next(false)
  }

  openCouponPopup() {
    this.showCouponBS.next(true)
  }

  closeCouponPopup() {
    this.showCouponBS.next(false)
  }

  openSearchPopup() {
    this.showSearchBS.next(true)
  }

  closeSearchPopup() {
    this.showSearchBS.next(false)
  }

  openSimplePopup(id: string) {
    this.showSimplePopupBS.next(id)
  }

  closeSimplePopup(id: string) {
    this.showSimplePopupBS.next(id)
  }

  changeSideNavbarIsShow(isShow: boolean) {
    this.sideNavbarIsShowSource.next(isShow)
  }

  private popUpIsShowSource = new BehaviorSubject(false);
  popUpIsShow = this.popUpIsShowSource.asObservable()

  changePopUpIsShow(isShow: boolean) {
    this.popUpIsShowSource.next(isShow)
  }

  openPopup() {
    let body = document.getElementsByTagName('body')[0];
    body.classList.add('isPopup');
  }

  closePopup() {
    let body = document.getElementsByTagName('body')[0];
    body.classList.remove('isPopup');
  }
  

  constructor() {}
}
