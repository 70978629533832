export const config = {
  apiUrl: 'https://bbsg-api-prod.imaginex.com/api/',
  authRoles: {
    sa: ['SA'], // Only Super Admin has access
    admin: ['SA', 'Admin'], // Only SA & Admin has access
    editor: ['SA', 'Admin', 'Editor'], // Only SA & Admin & Editor has access
    user: ['SA', 'Admin', 'Editor', 'User'], // Only SA & Admin & Editor & User has access
    guest: ['SA', 'Admin', 'Editor', 'User', 'Guest'] // Everyone has access
  },
  merchantId: 'bb',
  stripeKey: 'pk_live_51Ml6NsG9L1fGpIjOSVBsIH9PNcaalodx2O7Kfy4pqjA6mWfcYPjZrsg90deV1uW0hmjQWatp6zyVjNTN0qVAXA7l00tDQ3YXaW',
  facebookPixelId: '243727807782345',
  googleAnalyticsTrackingCode: 'G-FC81J0KV3G',
  recaptcha: {
    siteKey: '6LdDLCAlAAAAABEeLGb0PA3W-OKN6wYEPjNsbs7y',
  },
}
