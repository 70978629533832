import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControlComponent } from '@shared/base/form-control/form-control.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-checkbox-tooltip',
  templateUrl: './checkbox-tooltip.component.html',
  styleUrls: ['./checkbox-tooltip.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CheckboxTooltipComponent
    }
  ]
})
export class CheckboxTooltipComponent extends FormControlComponent implements OnInit {

  @Input() label: string;

  @Input() withMarginBottom: boolean = true;

  @Output() change = new EventEmitter<any>()

  constructor() {
    super()
  }

  ngOnInit(): void {
    this.value = false;
  }

  click() {
    this.value = !this.value
    this.change.emit(this.value)
    this.onChange(this.value)
  }

}
