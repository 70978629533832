import { Component, Input, OnInit } from '@angular/core';
import { transformMenuNameToNumber } from '@shared/helpers/util';
import Category from '@shared/models/category.model';
import { MenuItem } from '@shared/services/navigation.service';

@Component({
  selector: 'app-category-expandable-panel',
  templateUrl: './category-expandable-panel.component.html',
  styleUrls: ['./category-expandable-panel.component.scss']
})
export class CategoryExpandablePanelComponent implements OnInit {

  @Input() category: Category

  @Input() isSale: boolean = false

  @Input() showSaleSuffix: boolean = false

  open: boolean = false
  
  constructor() { }

  ngOnInit(): void {
  }

  filter(category: Category[]): Category[] {
    return category.sort((m1, m2) => transformMenuNameToNumber(m1.key) - transformMenuNameToNumber(m2.key))
  }

}
