import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-need-help-block',
  templateUrl: './need-help-block.component.html',
  styleUrls: ['./need-help-block.component.scss']
})
export class NeedHelpBlockComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
