import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BaseComponent } from '@shared/base/base.component';
import { CommonService } from '@shared/services/common.service';
import { ContactUsService } from '@shared/services/contact-us.service';
import { JwtAuthService } from '@shared/services/auth/jwt-auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends BaseComponent implements OnInit {

  subscribeForm: FormGroup;

  today: string = ""

  content2: string = ""

  regions = [
    "Location 1",
    "Location 2",
    "Location 3",
    "Location 4",
    "Location 5",
    "Location 6",
    "Location 7",
    "Location 8",
    "Location 9",
    "Location 10",
    "Location 11",
    "Location 12",
    "Location 13",
    "Location 14",
    "Location 15",
    "Location 16",
    "Location 17",
    "Location 18",
    "Location 19",
    "Location 20",
    "Location 21",
    "Location 22",
    "Location 23",
    "Location 24"
  ]

  isLoggedIn: Boolean = false

  constructor(private _formBuilder: FormBuilder, 
              private contactUsService: ContactUsService, 
              private commonService: CommonService, 
              private jwtAuthService: JwtAuthService) { 
    super()
  }

  ngOnInit(): void {
    const date = new Date()
    this.today = `${date.getFullYear()}${("0" + (date.getMonth() + 1)).slice(-2)}${("0" + date.getDate()).slice(-2)}`
    this.subscribeForm = this.createSubscribeForm()
    this.isLoggedIn = this.jwtAuthService.isLoggedIn();

    this.jwtAuthService.user$.subscribe(user => {
      if(!user) {
        this.isLoggedIn = false
      }else {
        this.isLoggedIn = true
      }
    })
  }

  createSubscribeForm(): FormGroup {
    return this._formBuilder.group({
      email: ["", Validators.required],
      region: [this.regions[0]]
    });
  }

  get email() { return this.subscribeForm.get('email'); }
  get region() { return this.subscribeForm.get('region'); }

  submit() {
    if(this.subscribeForm.valid) {
      const data = this.subscribeForm.getRawValue()
      this.contactUsService.sendSubscribeUsEmail(data).subscribe(response => {
        this.subscribeForm = this.createSubscribeForm()
        this.content2 = `${data.email} is now subscribed to receive Brooks Brothers emails.`
        this.commonService.openSimplePopup("subscription")
      })
    }else {
      this.validateAllFormFields(this.subscribeForm)
    }
  }

}
