import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map, take } from 'rxjs/operators';

import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

import { OrdersService } from '@shared/services/orders.service';

@Injectable({
  providedIn: 'root'
})
export class OrderReviewResolver implements Resolve<any>{

  constructor(private router: Router, private orderService: OrdersService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.orderService.getOrder(route.params.orderId).pipe(
      take(1),
      map(res => {
        if (res) {
          return res;
        } else {
          this.router.navigate(['/home']);
          return null;
        }
      })
    )
  }

}
