import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Product } from '@shared/models/product.model';
import { CommonService } from '@shared/services/common.service';
import { ProductSku } from '@shared/models/product-sku.model';
import { ProductsService } from '@shared/services/products.service';
import { DisplaySizeService } from '@shared/services/display-size.service';

@Component({
  selector: 'app-product-listing-item',
  templateUrl: './product-listing-item.component.html',
  styleUrls: ['./product-listing-item.component.scss']
})
export class ProductListingItemComponent implements OnInit {

  @Input() product: Product

  isMobile: boolean = false

  gallery: string[] = []

  colorMap: Map<string, any> = new Map()

  currentGalleryIndex = 0;

  imageChangeInterval: any;

  selectedSkuIdx: number = 0

  thumbnailSkuIdx: number = 0

  colorCount: number = 0

  isNewArrival: boolean = false

  constructor(private displaySizeService: DisplaySizeService, private router: Router, private commonService: CommonService, private productService: ProductsService) { }

  ngOnInit(): void {
    if(this.product.categories.findIndex(cat => cat.key === "na") >= 0) {
      this.isNewArrival = true;
    }

    this.displaySizeService.isMobile$.subscribe(value => {
      this.isMobile = value
    })

    this.product.productSkus.forEach((sku, index) => {
      const eav = sku.productSkuEavs.find(eav => eav.attribute === "displayColor")
      const img = sku.images.find(img => img.path.indexOf("_SXL") >= 0)
      if(eav) {
        //Check color count > 1 ?
        if(!this.colorMap.get(eav.value)) {
          this.colorCount++
        }
        this.colorMap.set(eav.value, {skuIdx: index, imgUrl: img.domain + img.path})
      }
    })

    //Get the first color sku index
    this.colorMap.forEach((value, key) => {
      this.selectedSkuIdx = value.skuIdx
      this.thumbnailSkuIdx = this.selectedSkuIdx
      return
    })

    this.gallery = this.product.productSkus[this.selectedSkuIdx].images.filter(img => img.path.indexOf("_SXL") < 0).map(img => img.domain + img.path)
  }

  enter() {
    this.imageChangeInterval = setInterval(() => {
      if(this.currentGalleryIndex === this.gallery.length - 1) {
        this.currentGalleryIndex = 0
      }else {
        this.currentGalleryIndex++
      }
    }, 1000)
  }

  leave() {
    this.currentGalleryIndex = 0
    clearInterval(this.imageChangeInterval)
  }

  gotoDetail() {
    //Remove url params
    this.router.navigate([`${this.router.url.split("?")[0]}/detail/${this.product.id}`])
  }

  quickShop() {
    this.productService.setProductToPopUp(this.product)
    this.commonService.openProductDetailPopup();
  }

  isSalesPrice(productSku: ProductSku): boolean {
    if(productSku.salesFrom && productSku.salesTo && productSku.salesPrice) {
      let today = new Date().getTime() / 1000
      if(today > productSku.salesFrom && today < productSku.salesTo) {
        return true;
      }else {
        return false;
      }
    }else {
      return false;
    }
  }

  selectColor(index: number) {
    this.selectedSkuIdx = index
    this.gallery = this.product.productSkus[index].images.filter(img => img.path.indexOf("_SXL") < 0).map(img => img.domain + img.path)
  }

  colorEnter(index: number) {
    this.thumbnailSkuIdx = index
  }

  colorLeave() {
    this.thumbnailSkuIdx = this.selectedSkuIdx
  }

}
