import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-hover-button',
  templateUrl: './hover-button.component.html',
  styleUrls: ['./hover-button.component.scss']
})
export class HoverButtonComponent implements OnInit {

  @Input() text: string;

  @Input() bgBefore: string;

  @Input() bgAfter: string;

  @Input() textBefore: string;

  @Input() textAfter: string;

  @Input() borderColor: string;

  @Input() imageBefore: string;

  @Input() imageAfter: string;

  @Input() selected: boolean = false;

  @Input() height: string = "60px"

  @Input() disabled: boolean = false;

  @Input() url: string = "";

  @Input() error: string = "";

  @Output() onClick = new EventEmitter<any>();

  bgColor: string = "var(--thm-white-color)";

  textColor: string = "var(--thm-text-color)";

  image: string;

  constructor() { }

  ngOnChanges(): void {
    if(!this.selected) {
      this.leave()
    }
  }

  ngOnInit(): void {
    if(this.selected) {
      this.bgColor = this.bgAfter
      this.textColor = this.textAfter
      this.image = this.imageAfter
    }else {
      this.bgColor = this.bgBefore
      this.textColor = this.textBefore
      this.image = this.imageBefore
    }
  }

  enter() {
    if(!this.selected) {
      this.bgColor = this.bgAfter
      this.textColor = this.textAfter
      this.image = this.imageAfter
    }
  }

  leave() {
    if(!this.selected) {
      this.bgColor = this.bgBefore
      this.textColor = this.textBefore
      this.image = this.imageBefore
    }
  }

  click(){
    if(!this.disabled) {
      this.onClick.emit()
    }
  }

}
