<table *ngIf="shoppingCart.cartItems && shoppingCart.cartItems.length > 0" class="shopping-cart-table">
    <tr>
        <th class="text-align-start">PRODUCT</th>
        <th class="text-align-start">DESCRIPTION</th>
        <th>COLOR</th>
        <th>SIZE</th>
        <th>QTY</th>
        <th>PRICE</th>
    </tr>
    <tr *ngFor="let item of shoppingCart.cartItems" class="position-relative">
        <td class="image">
            <img [src]="item.productSku.images | getImagePathByType:'THUMBNAIL'" alt="">
        </td>
        <td class="description">
            <div>
                <a>{{item.productSku.productSkuEavs | getValueByAttribute:'name':'value'}}</a>
            </div>
        </td>
        <td class="color">
            <div>
                {{item.productSku.productSkuEavs | getValueByAttribute:'displayColor':'value'}}
            </div>
        </td>
        <td class="size">
            <div>
                {{item.productSku.productSkuEavs | getValueByAttribute:'displaySize':'value'}} {{item.productSku.productSkuEavs | getValueByAttribute:'inseam':'value'}} {{item.productSku.productSkuEavs | getValueByAttribute:'fit':'value'}}
            </div>
        </td>
        <td class="quantity">
            <div>
                <app-quantity-button (onChange)="onChangeQty($event, item)" [(quantity)]="item.quantity"></app-quantity-button>
            </div>
            <div *ngIf="isOutOfStock(item.productSku, item.quantity)" class="out-of-stock">Out of Stock</div>
            <div *ngIf="isDisabled(item.productSku)" class="out-of-stock">Not Available</div>
        </td>
        <td class="price">
            <div>
                <div [ngClass]="{'text-line-through': isSalesPrice(item.productSku)}" class="subtotal">$ {{item.productSku.price | number}}</div>
                <div *ngIf="isSalesPrice(item.productSku)" class="grandtotal">$ {{(item.productSku | getProductSalesPriceIfAny) | number}}</div>
            </div>
        </td>
        <div fxLayout="column" fxLayoutAlign="start end" class="action-container">
            <a (click)="edit(item)">EDIT</a>
            <div fxLayout="row" fxLayoutAlign="start end">
                <img src="assets/images/icon/remove-icon.svg" alt="">
                <a (click)="removeCartItem(item)">REMOVE</a>
            </div>
        </div>
    </tr>
</table>
<div class="shopping-cart-table-mobile">
    <div class="item-container" *ngFor="let item of shoppingCart.cartItems">
        <div fxLayout="row">
            <div class="left-container">
                <img [src]="item.productSku.images | getImagePathByType:'THUMBNAIL'" alt="">
            </div>
            <div fxLayout="column" fxLayoutAlign="space-between" class="right-container">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="description">
                        <a href="#">{{item.productSku.productSkuEavs | getValueByAttribute:'name':'value'}}</a>
                    </div>
                    <div class="price">
                        <div [ngClass]="{'text-line-through': isSalesPrice(item.productSku)}" class="subtotal">$ {{item.productSku.price | number}}</div>
                        <div *ngIf="isSalesPrice(item.productSku)" class="grandtotal">$ {{(item.productSku | getProductSalesPriceIfAny) | number}}</div>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="color">
                        <span>{{item.productSku.productSkuEavs | getValueByAttribute:'displayColor':'value'}}</span>
                    </div>
                    <div class="size">
                        <span>{{item.productSku.productSkuEavs | getValueByAttribute:'displaySize':'value'}} {{item.productSku.productSkuEavs | getValueByAttribute:'inseam':'value'}} {{item.productSku.productSkuEavs | getValueByAttribute:'fit':'value'}}</span>
                    </div>
                    <div class="quantity">
                        <div fxLayout="row" fxLayoutAlign="end">
                            <app-quantity-button (onChange)="onChangeQty($event, item)" [(quantity)]="item.quantity"></app-quantity-button>
                        </div>
                    </div>
                    <div class="item-out-message">
                        <div *ngIf="isOutOfStock(item.productSku, item.quantity)" class="out-of-stock">
                            Out of Stock
                        </div>
                        <div *ngIf="isDisabled(item.productSku)" class="out-of-stock">Not Available</div>
                    </div>
                    <div *ngIf="isOutOfStock(item.productSku, item.quantity)" class="out-of-stock">Out of Stock</div>
                </div>
            </div>
        </div>
        <div class="action-container" fxLayout="row" fxLayoutAlign="end end">
            <div fxLayout="row" fxLayoutAlign="start end">
                <img src="assets/images/icon/remove-icon.svg" alt="">
                <a (click)="removeCartItem(item)">REMOVE</a>
            </div>
            <a class="edit" (click)="edit(item)">EDIT</a>
        </div>
    </div>
</div>