import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '@shared/services/common.service';

@Component({
  selector: 'app-simple-popup',
  templateUrl: './simple-popup.component.html',
  styleUrls: ['./simple-popup.component.scss']
})
export class SimplePopupComponent implements OnInit {

  @Input() id: string;

  @Input() title: string;

  @Input() content1: string;

  @Input() content2: string;

  show: boolean = false

  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
    this.commonService.showSimplePopup$.subscribe(id => {
      if(this.id === id) {
        this.show = !this.show
      }
    })
  }

  close() {
    this.commonService.closeSimplePopup(this.id)
  }

}
