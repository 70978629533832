import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-underline-link-text',
  templateUrl: './underline-link-text.component.html',
  styleUrls: ['./underline-link-text.component.scss']
})
export class UnderlineLinkTextComponent implements OnInit {

  @Input() url: string = ""
  @Input() content: string = ""

  @Output() onClick = new EventEmitter<any>()

  constructor() { }

  ngOnInit(): void {
  }

  click() {
    this.onClick.emit()
  }

}
