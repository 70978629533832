import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormControlComponent } from '@shared/base/form-control/form-control.component';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: InputComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: InputComponent
    }
  ]
})
export class InputComponent extends FormControlComponent implements OnInit {

  float: boolean = false;

  @Input() disabled: boolean = false;
  
  @Input() type: string;

  @Input() placeholder: string;

  @Input() remark: string;

  @Input() remark2: string;

  @Input() error: string = "";

  constructor() {
    super()
  }

  ngOnInit(): void {
  }

}
