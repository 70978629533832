import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { map, take } from 'rxjs/operators';

import { ProductSkuFilterRequest } from '@shared/models/request/product-sku-filter-request';

import { HttpService } from '@shared/services/http.service';
import {SessionStoreService} from "@shared/services/session-store.service";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import Slider from '@shared/models/Slider';

const GET_ALL_SLIDER = 'slider/allValid';

@Injectable({
  providedIn: 'root'
})
export class SlidersService{

  sliders = new BehaviorSubject<Slider[]>([]);
  sliders$ = this.sliders.asObservable();

  constructor(private httpService: HttpService, private ss: SessionStoreService) { 
  }

  // TODO cache category for a while
  getAllSlider(): Promise<any> {

    return new Promise((resolve, reject) => {
      this.httpService.get(GET_ALL_SLIDER, {})
        .subscribe((response: any) => {

          this.sliders.next(response)

          resolve(response);
        }, reject);
    });
  }

}
