import { Component, OnInit } from '@angular/core';
import { CustomCookieService } from '@shared/services/cookie.service';

@Component({
  selector: 'app-cookie-block',
  templateUrl: './cookie-block.component.html',
  styleUrls: ['./cookie-block.component.scss']
})
export class CookieBlockComponent implements OnInit {

  constructor(public cookieService: CustomCookieService) { }

  ngOnInit(): void {
  }

  accept() {
    this.cookieService.acceptCookie()
  }

  isCookieAccepted(): boolean {
    return this.cookieService.isCookieAccepted()
  }

}
