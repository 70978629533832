import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Observable, throwError } from 'rxjs';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { SessionStoreService } from './session-store.service';
import Category from '@shared/models/category.model';
import { HttpService } from './http.service';

export class MenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  key?: string;
  state?: string; // Router state
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  children?: MenuItem[]; // Dropdown items

  constructor(iMenuItem?) {
    iMenuItem = iMenuItem || {}
    this.type = iMenuItem.type || ""
    this.name = iMenuItem.name || ""
    this.key = iMenuItem.key || ""
    this.state = iMenuItem.state || ""
    this.disabled = iMenuItem.disabled || false
    this.children = iMenuItem.children || []
  }
}

@Injectable({
  providedIn: 'root'
})
export class NavigationService implements Resolve<any> {

  routeParams: any;

  headerMenu: MenuItem[] = [];

  categoryList: Category[] = [];

  categoryItemsBs = new BehaviorSubject<Category[]>([]);
  categoryItems$ = this.categoryItemsBs.asObservable()

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle = "Frequently Accessed";
  // sets iconMenu as default;
  headerItems = new BehaviorSubject<MenuItem[]>([]);
  // navigation component has subscribed to this Observable
  headerItems$ = this.headerItems.asObservable();
  constructor(private httpService: HttpService,
    private ss: SessionStoreService) {
    this.getCategory();
  }

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(menuType: string) {
    this.headerItems.next(this.headerMenu);
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.routeParams = route.params;

    // to get the id from manage-sliders

    return new Promise((resolve, reject) => {

      Promise.all([
        this.getCategory()
      ]).then(
        () => {
          resolve();
        },
        reject
      );
    });
  }

  // TODO cache category for a while
  getCategory(): Promise<any> {

    return new Promise((resolve, reject) => {
      this.httpService.get('catalog/category/root', {})
        .subscribe((response: any) => {
          //TODO: headermenu change to dynamic add

          //Push root category
          this.categoryList.push(response)

          let result = this.categoriesToMenuItem(response, this.categoryList)

          //Store all the category
          this.categoryItemsBs.next(this.categoryList)
          this.ss.setItem("categoryList", this.categoryList)

          this.headerMenu = result.children

          this.headerItems.next(this.headerMenu)

          resolve(response);
        }, reject);
    });
  }

  categoriesToMenuItem(category: Category, categoryList: Category[], parentId?: number, parentPath?: string): MenuItem {

    let menu = this.mapCategoryToMenuItem(category, parentId, parentPath)

    if(category.children && category.children.length > 0) {
        category.children.sort((c1, c2) => parseInt(c1.displayOrder) - parseInt(c2.displayOrder)).map(child => {
            menu.children.push(this.categoriesToMenuItem(child, categoryList, category.id, category.path))
            categoryList.push(child)
        })
    }

    return menu
  }

  mapCategoryToMenuItem(category: Category, parentId: number, parentPath: string): MenuItem {
      return new MenuItem({
          name: (category.categoryEavs.find(eav => eav.attribute === "displayName") || {}).value,
          key: `${category.key.toUpperCase()}`,
          type: "dropDown",
          state: category.id,
          children: [],
          disabled: category.status !== 'ACTIVE'
      })
  }


}
