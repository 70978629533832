import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-size-box',
  templateUrl: './size-box.component.html',
  styleUrls: ['./size-box.component.scss']
})
export class SizeBoxComponent implements OnInit {

  @Input() size: string;

  @Input() selected: boolean = false;

  @Input() disabled: boolean = false;

  @Output() onClick = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  click(){
    if(!this.disabled) {
      this.onClick.emit(this.size)
    }
  }

}
