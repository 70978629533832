import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Customer } from '@shared/models/customer.model';
import { JwtAuthService } from '@shared/services/auth/jwt-auth.service';
import { CartService } from '@shared/services/cart.service';
import { CommonService } from '@shared/services/common.service';
import { MenuItem, NavigationService } from '@shared/services/navigation.service';
import { transformMenuNameToNumber } from '@shared/helpers/util';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(private navigationService: NavigationService, private translate: TranslateService, private router: Router, private commonService: CommonService, private cartService: CartService, private jwtAuthService: JwtAuthService) { }

  sticky: boolean = true

  showSubCategory: boolean = false

  menuList: MenuItem[] = []

  currentSubMenuIndex = -1

  showCart: boolean = false;

  itemNum: number = 0;

  user: Customer;

  ngOnInit(): void {
    this.navigationService.headerItems$.subscribe(rsp => {
      this.menuList = rsp
    })

    this.commonService.showHeaderCart$.subscribe(value => {
      this.showCart = value
    })

    this.cartService.cartItemNum$.subscribe(num => {
      this.itemNum = num
    })

    this.jwtAuthService.user$.subscribe(user => {
      this.user = user
    })

    this.jwtAuthService.getUser().subscribe(user => {
      this.user = user
    })
  }

  enter(index: number, show: boolean = true) {
    this.currentSubMenuIndex = index
    this.showSubCategory = show;
  }

  leave() {
    this.showSubCategory = false
  }

  gotoShoppingCart() {
    this.router.navigate(["/cart"])
  }

  gotoProductListing(id) {
    this.showSubCategory = false
    this.router.navigate(["/products/" + id])
  }

  gotoAllBlogs() {
    this.router.navigate(["/blog/all"])
  }

  search() {
    this.commonService.openSearchPopup()
  }

  showSideNavBar() {
    this.commonService.openSideNavBar()
  }

}
