<div fxLayout="row" fxLayoutAlign="center center" class="progress-bar-container">
    <div fxLayout="column" fxLayoutAlign="center center" class="bullet" [ngClass]="{'active': activeIndex === 0}">
        <span></span>
        <div>
            SHIPPING
        </div>
    </div>
    <div class="line-separator"></div>
    <div fxLayout="column" fxLayoutAlign="center center" class="bullet" [ngClass]="{'active': activeIndex === 1}">
        <span></span>
        <div>
            REVIEW
        </div>
    </div>
    <div class="line-separator"></div>
    <div fxLayout="column" fxLayoutAlign="center center" class="bullet" [ngClass]="{'active': activeIndex === 2}">
        <span></span>
        <div>
            PAYMENT
        </div>
    </div>
</div>