import { Component, OnInit } from '@angular/core';
import Category from '@shared/models/category.model';
import { NavigationService } from '@shared/services/navigation.service';
import { SessionStoreService } from '@shared/services/session-store.service';

@Component({
  selector: 'app-product-sale-filter',
  templateUrl: './product-sale-filter.component.html',
  styleUrls: ['./product-sale-filter.component.scss']
})
export class ProductSaleFilterComponent implements OnInit {

  parentCategory: Category = new Category()

  constructor(private ss: SessionStoreService, private navigationService: NavigationService) { }

  ngOnInit(): void {
    const list: Category[] = this.ss.getItem("categoryList")
    if(list && list.length > 0) {
      this.parentCategory = list.find(cat => cat.key === 'root')
    }

    //For the first time load into product sale
    this.navigationService.categoryItems$.subscribe(list => {
      const sslist: Category[] = this.ss.getItem("categoryList")
      if(!sslist && list && list.length > 0) {
          this.parentCategory = list.find(cat => cat.key === 'root')
      }
    })
  }

}
