import {Component, Input, OnInit} from '@angular/core';

import {Router} from "@angular/router";

@Component({
    selector: 'app-blog-item',
    templateUrl: './blog-item.component.html',
    styleUrls: ['./blog-item.component.scss']
})
export class BlogItemComponent implements OnInit {

    month=['Jan','Feb','Mar','Apr','May','June','July','Aug','Sep','Oct','Nov','Dec']

    @Input() post: any;

    constructor(
        private router: Router) {
    }

    ngOnInit(): void {
    }

    getDateString() {
        if (this.post) {
            const arr = this.post.createdAt.split(/[- :]/);
            const selectMonth = Number(arr[1]-1);
            const date = arr[2].charAt(0) == '0' ? arr[2].charAt(1) : arr[2]
            return this.month[selectMonth] + ' ' + date;
        }
        return '';
    }

}
