import { Pipe, PipeTransform } from "@angular/core";
import { ProductSku } from "@shared/models/product-sku.model"

@Pipe({
  name: "getProductSalesPriceIfAny",
  pure: false
})
export class GetProductSalesPriceIfAny implements PipeTransform {
  transform(value: ProductSku): string {
    if(value.salesFrom && value.salesTo && value.salesPrice) {
      let today = new Date().getTime() / 1000
      if(today > value.salesFrom && today < value.salesTo) {
        return value.salesPrice
      }else {
        return parseFloat(value.price).toFixed(1)
      }
    }else {
      return parseFloat(value.price).toFixed(1)
    }
  }
}