import { Component, Input, OnInit } from '@angular/core';
import Slider from '@shared/models/Slider';

@Component({
  selector: 'app-sale-slider',
  templateUrl: './sale-slider.component.html',
  styleUrls: ['./sale-slider.component.scss']
})
export class SaleSliderComponent implements OnInit {

  @Input() slider: Slider = new Slider()

  slideConfig = {
    "arrows": false,
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "infinite": false,
    "speed": 500,
    "autoplay":false,
    "dots": false
  }

  slickInit(e) {
  }

  breakpoint(e) {
  }

  afterChange(e) {
  }

  beforeChange(e) {
  }

  constructor() { }

  ngOnInit(): void {
  }

}
