<div class="container-1440">
    <div class="page-container">
        <div class="order-confirmation-container">
            <h1>Thank You For Your Purchase!</h1>
            <div class="sub-title">You will receive a confirmation email shortly.</div>
            <div class="content container-1200">
                <div class="order-number">Your Order Number : {{order.orderNo}}</div>
                <div>Order Date : {{order.orderDate}}</div>
                <div>
                    <app-order-review-content [order]="order"></app-order-review-content>
                </div>
                <div>
                    <app-order-review-summary [order]="order"></app-order-review-summary>
                </div>
            </div>
        </div>
    </div>
</div>