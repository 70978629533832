<div class="product-container">
    <div (mouseenter)="enter()" (mouseleave)="leave()" class="image-container">
        <img class="thumbnail" (click)="gotoDetail()" [src]="product.productSkus[thumbnailSkuIdx].images | getImagePathByType:'THUMBNAIL'" onerror="this.src='assets/images/dummy/not-found.jpg'">
        <img class="gallery" (click)="gotoDetail()" [src]="gallery[currentGalleryIndex]" alt="" onerror="this.src='assets/images/dummy/not-found.jpg'">
        <div *ngIf="!isMobile" (click)="quickShop()" class="quick-shop">
            QUICKSHOP
        </div>
        <img *ngIf="isNewArrival" class="new-arrival-tag" src="assets/images/product/new.png" alt="">
    </div>
    <div class="product-information">
        <div class="product-name">{{product.productSkus[0].productSkuEavs | getValueByAttribute:'name':'value'}}</div>
        <div class="price-container">
            <div class="product-price" [ngClass]="{'deleted': isSalesPrice(product.productSkus[0])}">$ {{product.productSkus[0].price | number}}</div>
            <div class="discount-container">
                <div *ngIf="isSalesPrice(product.productSkus[0])" class="sale-price">$ {{(product.productSkus[0] | getProductSalesPriceIfAny) | number}}</div>
                <div *ngIf="isSalesPrice(product.productSkus[0])" class="discount-badge"><span>-{{(100 - ((product.productSkus[0] | getProductSalesPriceIfAny) / product.productSkus[0].price * 100)) | round}}%</span></div>
            </div>
        </div>
        <div *ngIf="colorCount > 1" class="color-container" fxLayout="row wrap" fxLayoutAlign="start">
            <img *ngFor="let color of colorMap | keyvalue" 
                (mouseenter)="colorEnter(color.value.skuIdx)" 
                (mouseleave)="colorLeave()" 
                [ngClass]="{'selected': color.value.skuIdx === selectedSkuIdx}" 
                (click)="selectColor(color.value.skuIdx)" 
                [src]="color.value.imgUrl" alt="">
        </div>
    </div>
</div>