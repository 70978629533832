import { Component, Input, OnInit } from '@angular/core';
import { Customer } from '@shared/models/customer.model';
import { JwtAuthService } from '@shared/services/auth/jwt-auth.service';
import { CartService } from '@shared/services/cart.service';

@Component({
  selector: 'app-header-account-dropdown',
  templateUrl: './header-account-dropdown.component.html',
  styleUrls: ['./header-account-dropdown.component.scss']
})
export class HeaderAccountDropdownComponent implements OnInit {

  @Input() top: string;

  @Input() user: Customer;

  constructor(private jwtAuthService: JwtAuthService, private cartService: CartService) { }

  ngOnInit(): void {
  }

  logout() {
    this.cartService.clearCart()
    this.jwtAuthService.signOut()
  }
 
}
