import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-youtube',
  templateUrl: './youtube.component.html',
  styleUrls: ['./youtube.component.scss']
})
export class YoutubeComponent implements OnInit, AfterViewInit {
  @ViewChild('player') player: any;

  @ViewChild('player', { read: ElementRef }) playerRef: ElementRef;

  @Input() url: string = ""

  constructor() { }

  ngOnInit(): void {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(tag);
  }

  ngAfterViewInit(): void {
    //Set height with scale
    setTimeout(() => {
      const iframe = this.playerRef.nativeElement.children[0]
      const height = (195/347) * iframe.offsetWidth
      iframe.setAttribute('height', `${height}`)
    }, 1000)
  }

  // Autoplay
  onReady(event) {
    this.player.mute();         
    this.player.playVideo();    
  }

  // Loop
  onStateChange(event) {
    if (event.data === 0) {
      this.player.playVideo();  
    }
  }

}
