import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Product } from '@shared/models/product.model';
import { getEavByAttribute, getSizeValue, ProductSku } from '@shared/models/product-sku.model';
import { ProductAttributeMap, ProductsService } from '@shared/services/products.service';
import { sortSize } from '@shared/helpers/util';
import { CartService } from '@shared/services/cart.service';
import { SubmitCartItem } from '@shared/models/submit-cart-item.model';
import { CommonService } from '@shared/services/common.service';
import { CartItem } from '@shared/models/cart-item.model';
import { ShoppingCart } from '@shared/models/shopping-cart.model';
import { mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product-content',
  templateUrl: './product-content.component.html',
  styleUrls: ['./product-content.component.scss']
})
export class ProductContentComponent implements OnInit {

  @Input() product: Product

  @Input() cartItem: CartItem = null

  @ViewChild("thumbnail") thumbnail: ElementRef

  slideConfig = {
    "arrows": false,
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "infinite": false,
    "speed": 500,
    "autoplay": false,
    "dots": true,
  }

  slickInit(e) {
  }

  breakpoint(e) {
  }

  afterChange(e) {
  }

  beforeChange(e) {
  }

  thumbnailBackgroundPosition: string = "0 0"

  selectedImageIndex: number = 0;

  selectedColorIndex: number = 0;

  images: string[] = []

  eavMap: ProductAttributeMap[] = []

  quantity: number = 1;

  colorList: any[] = []
  sizeList: string[] = []
  fitList: string[] = []
  inseamList: string[] = []

  fullSizeList: string[] = []
  fullFitList: string[] = []
  fullInseamList: string[] = []

  //EAV check points
  eavCheckPoints: number = 1

  colorIndex: number = 0

  selectedColor = ""

  fitIndex: number = 0

  selectedFit = ""

  fitIsEmpty: boolean = false

  sizeIndex: number = 0

  selectedSize = ""

  sizeIsEmpty: boolean = false

  inseamIndex: number = 0

  selectedInseam = ""

  inseamIsEmpty: boolean = false

  itemNo: string = ""

  sku: ProductSku;

  selectedProductName = ""

  //Mobile
  popupImageUrl: string = ""

  constructor(private productService: ProductsService,
    private cartService: CartService,
    private commonService: CommonService,
    private router: Router) { }

  ngOnInit(): void {
    this.images = this.product.productSkus[0].images.filter(img => img.path.indexOf("_SXL") < 0).map(img => img.domain + img.path)
    this.selectedColor = getEavByAttribute(this.product.productSkus[0], "displayColor")

    //Set default sku
    this.itemNo = this.product.productSkus[0].sku.split("_")[0]

    this.updateEavMap(this.product.productSkus)

    this.selectedProductName = getEavByAttribute(this.product.productSkus[0], "name")
  }

  ngOnChanges(): void {
    this.images = this.product.productSkus[0].images.filter(img => img.path.indexOf("_SXL") < 0).map(img => img.domain + img.path)
    this.selectedColor = getEavByAttribute(this.product.productSkus[0], "displayColor")
    this.updateEavMap(this.product.productSkus)

    //Set default fit
    if (this.fitList.length === 1) {
      this.selectedFit = this.fitList[0]
      this.fitIndex = 0
    }

    //Set default sku
    this.itemNo = this.product.productSkus[0].sku.split("_")[0]
  }

  thumbnailHover(event) {
    const posX = Math.max(event.layerX, 0)
    const posY = Math.max(event.layerY, 0)
    this.thumbnailBackgroundPosition = `${posX * 100 / this.thumbnail.nativeElement.clientWidth}% ${posY * 100 / this.thumbnail.nativeElement.clientHeight}%`
  }

  selectImage(index: number) {
    this.selectedImageIndex = index
  }

  selectColor(e) {
    const currentSku = this.product.productSkus.find(sku => sku.productSkuEavs.findIndex(eav => eav.attribute === "displayColor" && eav.value === e) >= 0)
    this.selectedImageIndex = 0;
    this.images = currentSku.images.filter(img => img.path.indexOf("_SXL") < 0).map(img => img.domain + img.path)
    this.colorIndex = this.eavMap.findIndex(color => color.value === e)
    this.selectedColor = e
    this.updateAttributeList()
  }

  selectFit(e) {
    this.fitIndex = this.eavMap[this.colorIndex].children.findIndex(fit => fit.value === e)
    this.selectedFit = e
    this.updateAttributeList()
  }

  fitExist(fit: string) {
    return this.fitList.findIndex(value => value === fit) >= 0
  }

  selectSize(e) {
    this.sizeIndex = this.eavMap[this.colorIndex].children[this.fitIndex].children.findIndex(size => size.value === e)
    this.selectedSize = e
    this.updateAttributeList()
  }

  sizeExist(size: string) {
    return this.sizeList.findIndex(value => value === size) >= 0
  }

  selectInseam(e) {
    this.inseamIndex = this.eavMap[this.colorIndex].children[this.fitIndex].children[this.sizeIndex].children.findIndex(inseam => inseam.value === e)
    this.selectedInseam = e
    this.updateAttributeList()
  }

  inseamExist(inseam: string) {
    return this.inseamList.findIndex(value => value === inseam) >= 0
  }

  updateEavMap(productSkus: ProductSku[]) {
    this.eavMap = this.productService.extractAllSkuEav(productSkus)
    this.eavCheckPoints = 1
    this.fullFitList = this.productService.fullFitList
    if (this.fullFitList.length > 0) {
      this.eavCheckPoints++
    }
    this.fullSizeList = this.productService.fullSizeList
    if (this.fullSizeList.length > 0) {
      this.eavCheckPoints++
      //Set default size
      this.selectedSize = this.fullSizeList[0]
    }
    this.fullInseamList = this.productService.fullInseamList
    if (this.fullInseamList.length > 0) {
      this.eavCheckPoints++
    }
    this.updateAttributeList()
  }

  updateAttributeList() {
    this.colorList = this.eavMap.map(attr => {
      return {
        value: attr.value,
        imgUrl: attr.imgUrl
      }
    })
    if (this.fullFitList && this.fullFitList.length > 0) {
      this.fitList = this.eavMap[this.colorIndex].children.filter(attr => attr.value).map(attr => attr.value)
    }
    if (this.fullSizeList && this.fullSizeList.length > 0) {
      this.sizeList = this.eavMap[this.colorIndex].children[this.fitIndex].children.filter(attr => attr.value).map(attr => attr.value)
    }
    if (this.fullInseamList && this.fullInseamList.length > 0) {
      this.inseamList = this.eavMap[this.colorIndex].children[this.fitIndex].children[this.sizeIndex].children.filter(attr => attr.value).map(attr => attr.value)
    }

    if (!this.fitExist(this.selectedFit)) {
      this.selectedFit = ""
    }

    if (!this.sizeExist(this.selectedSize)) {
      this.selectedSize = ""
    }

    if (!this.inseamExist(this.selectedInseam)) {
      this.selectedInseam = ""
    }

    //Change the current sku
    this.sku = this.getCurrentSku()
    if (this.sku) {
      this.itemNo = this.sku.sku
      this.selectedProductName = getEavByAttribute(this.sku, "name")
    }
  }

  isSalesPrice(productSku: ProductSku): boolean {
    if (productSku.salesFrom && productSku.salesTo && productSku.salesPrice) {
      let today = new Date().getTime() / 1000
      if (today > productSku.salesFrom && today < productSku.salesTo) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isOutOfStock(productSku: ProductSku): boolean {
    if ((productSku.stock <= productSku.minStockLevel) || (this.quantity > (this.sku.stock - this.sku.minStockLevel)))
      return true;
    return false;
  }

  validate() {
    if (this.sizeList && this.sizeList.length > 0 && !this.selectedSize) {
      this.sizeIsEmpty = true
    } else {
      this.sizeIsEmpty = false
    }
    if (this.fitList && this.fitList.length > 0 && !this.selectedFit) {
      this.fitIsEmpty = true
    } else {
      this.fitIsEmpty = false
    }
    if (this.inseamList && this.inseamList.length > 0 && !this.selectedInseam) {
      this.inseamIsEmpty = true
    } else {
      this.inseamIsEmpty = false
    }
    return !this.sizeIsEmpty && !this.fitIsEmpty && !this.inseamIsEmpty
  }

  getCurrentSku(): ProductSku {
    return this.product.productSkus.find(sku => {
      let eavChecked = 0
      sku.productSkuEavs.forEach(eav => {
        if (eav.attribute === "displayColor" && this.selectedColor && eav.value === this.selectedColor) {
          eavChecked++
        } else if (eav.attribute === "fit" && this.selectedFit && eav.value === this.selectedFit) {
          eavChecked++
        } else if (eav.attribute === "displaySize" && this.selectedSize && eav.value === this.selectedSize) {
          eavChecked++
        } else if (eav.attribute === "inseam" && this.selectedInseam && eav.value === this.selectedInseam) {
          eavChecked++
        }
      })
      return eavChecked >= this.eavCheckPoints
    })
  }

  getButtonText(): string {
    if (this.sku && this.isOutOfStock(this.sku)) {
      return "OUT OF STOCK"
    } else {
      if (this.cartItem) {
        return 'UPDATE'
      } else {
        return 'ADD TO BAG'
      }
    }
  }

  addToCart() {
    if (!this.validate()) {
      return
    }

    if (this.isOutOfStock(this.sku)) {
      return
    }

    let submitCartItem = new SubmitCartItem()

    submitCartItem.setproductSku(this.sku.id.toString())
    submitCartItem.setquantity(this.quantity.toString())

    if (this.cartItem !== null) {
      this.cartService.deleteCartItem(this.cartItem).pipe(mergeMap(rsp => this.cartService.addToCart(submitCartItem))).subscribe((data: ShoppingCart) => {
        this.cartService.setCart(data)
        this.productService.setCartItemToUpdate(null)
      })
    } else {
      this.cartService.addToCart(submitCartItem).subscribe(data => {
        this.cartService.setCart(data)
        this.commonService.openHeaderCart()
        setTimeout(() => {
          this.commonService.closeHeaderCart()
        }, 5000)
      })
    }
  }

  gotoSizingGuide() {
    this.productService.setCartItemToUpdate(null)
    this.commonService.closeProductDetailPopup();
    this.router.navigate([]).then((result) => {
      window.open('/#/size-and-fit-guides', '_blank');
    });
  }

  //Mobile function
  viewImage(url: string) {
    this.popupImageUrl = url
  }

  closeViewImage() {
    this.popupImageUrl = ''
  }

  quantityOnChange(quantity) {
    this.quantity = quantity;
    this.getButtonText();
  }

}