import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';


import { HttpService } from '@shared/services/http.service';
import { SessionStoreService } from "@shared/services/session-store.service";
import { PaymentStripeRequest } from '@shared/models/request/payment-stripe-request';
import { Order } from '@shared/models/order.model';
import { PaymentWalletCompleteRequest } from '@shared/models/request/payment-wallet-complete-request';

const CREATE_ORDER = 'order/create';
const GET_ORDER = 'order/'
const CANCEL_ORDER = 'order/cancel/';
const POST_PAYMENT_PROCESS = 'payment/stripe/process';
const RETURN_ORDER = 'order/return/'
const INVENTORY_ENQUIRY = 'customize/inventory-enquiry'
const GENERATE_WEB_SALES_FILE = "customize/generate-web-sales-interface/"
const CREATE_PAYMENT_INTENT = "payment/stripe/payment-intent/"
const PAYMENT_COMPLETE = "payment/stripe/complete"

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(private httpService: HttpService, private ss: SessionStoreService) { }

  createOrder(orderRequest): Observable<any> {
    return this.httpService.post(CREATE_ORDER, { body: orderRequest });
  }

  getOrder(orderId: string): Observable<any> {
    return this.httpService.get(GET_ORDER + orderId, {});
  }

  paymentProcess(orderId: string, token: string): Observable<Order> {
    let paymentStripeRequest: PaymentStripeRequest = new PaymentStripeRequest(orderId, token);
    console.log(JSON.stringify(paymentStripeRequest));

    return this.httpService.post(POST_PAYMENT_PROCESS, { body: paymentStripeRequest });
  }

  createPaymentIntent(orderId: string): Observable<any> {
    return this.httpService.post(CREATE_PAYMENT_INTENT + orderId, {});
  }

  completePayment(id: string, wallet_name: string, client_secret: string, status: string): Observable<any> {
    let paymentWalletCompleteRequest: PaymentWalletCompleteRequest = new PaymentWalletCompleteRequest(id, wallet_name, client_secret, status);
    return this.httpService.post(PAYMENT_COMPLETE, { body: paymentWalletCompleteRequest });
  }


  cancelOrder(id): Observable<any> {
    return this.httpService.post(CANCEL_ORDER + id, {});
  }

  getOrderHistory(page = "0", direction = "DESC"): Observable<any> {
    const params = new HttpParams().set('page', page)
      .set('size', "10")
      .set('sort', "id")
      .set('direction', direction);
    return this.httpService.get(GET_ORDER, params);
  }

  returnOrder(request, id): Observable<any> {
    return this.httpService.post(RETURN_ORDER + id, { body: request })
  }

  getReturnOrder(id): Observable<any> {
    return this.httpService.get(RETURN_ORDER + id, null)
  }

  inventoryEnquiry(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpService.post(INVENTORY_ENQUIRY, { responseType: "text" })
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  generateWebSalesInterface(orderId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpService.post(GENERATE_WEB_SALES_FILE + orderId, { responseType: "text" })
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }


}
