import { Component, Input, OnInit } from '@angular/core';
import { ShoppingCart } from '@shared/models/shopping-cart.model';
import { CartService } from '@shared/services/cart.service';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss']
})
export class OrderSummaryComponent implements OnInit {

  @Input() shoppingCart: any;
  
  constructor(private cartService: CartService) { }

  ngOnInit(): void {
  }

}
