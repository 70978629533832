import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShoppingCart } from '@shared/models/shopping-cart.model';
import { CartService } from '@shared/services/cart.service';

@Component({
  selector: 'app-header-shopping-cart-dropdown',
  templateUrl: './header-shopping-cart-dropdown.component.html',
  styleUrls: ['./header-shopping-cart-dropdown.component.scss']
})
export class HeaderShoppingCartDropdownComponent implements OnInit {

  @Input() top: string;

  cart: ShoppingCart = new ShoppingCart();

  constructor(private cartService: CartService, private router: Router) { }

  ngOnInit(): void {
    this.cartService.shoppingCart$.subscribe(cart => {
      this.cart = cart
    })
  }

  gotoShoppingCart() {
    this.router.navigate(["/cart"])
  }

}
