import { Pipe, PipeTransform } from "@angular/core";
import { ProductSku } from "@shared/models/product-sku.model"

@Pipe({
  name: "checkProductIsSale",
  pure: false
})
export class CheckProductIsSale implements PipeTransform {
  transform(value: ProductSku): boolean {
    if(value.salesFrom && value.salesTo && value.salesPrice) {
      let today = new Date().getTime() / 1000
      if(today > value.salesFrom && today < value.salesTo) {
        return true
      }else {
        return false
      }
    }else {
      return false
    }
  }
}