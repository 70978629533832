import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { map, take } from 'rxjs/operators';

import { ProductSkuFilterRequest } from '@shared/models/request/product-sku-filter-request';

import { HttpService } from '@shared/services/http.service';
import {SessionStoreService} from "@shared/services/session-store.service";
import { Customer } from '@shared/models/customer.model';

const ACTIVATE = 'customer/activate';
const REGISTER = 'customer/register';
const LOGIN = 'customer/login';
const FORGOT_PASSWORD = 'customer/forget-password'
const RESET_PASSWORD = 'customer/reset-password'

@Injectable({
  providedIn: 'root'
})
export class AuthService{

  constructor(private httpService: HttpService, private ss: SessionStoreService) {}

  activate(key: string): Observable<any> {
    return this.httpService.post(ACTIVATE + "/" + key, {});
  }

  register(customer: Customer): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpService.post(REGISTER, {body: customer, responseType: 'text'})
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  login(request): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpService.post(LOGIN, {body: request})
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  forgotPassword(request: any): Observable<any> {
    return this.httpService.post(FORGOT_PASSWORD, {body: request, responseType: 'text'})
  }

  resetPassword(request: any): Observable<any> {
    return this.httpService.post(RESET_PASSWORD, {body: request})
  }

}
