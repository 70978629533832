<div class="header-wrapper" [ngClass]="{'sticky': sticky}">
    <div class="header-container">
        <div fxLayout="row" fxLayoutAlign="space-between" class="header-top">
            <div fxLayout="row" fxLayoutAlign="start center" class="header-left">
                <a href="/#/home">
                    <img class="sticky-logo" src="assets/images/logo.svg"/>
                </a>
            </div>
            <div (mouseleave)="showSubCategory = false" fxLayout="row" fxLayoutAlign="center center" class="category">
                <div [ngClass]="{'hover': currentSubMenuIndex === i}" class="parent" *ngFor="let item of menuList; index as i" (mouseenter) = "enter(i)" fxLayout="column" fxLayoutAlign="center center">
                    <a [href]="'/#/products/' + item.state">{{item.name | translate}}</a>
                    <div class="underline"></div>
                </div>
                <div class="parent" (mouseenter) = "enter(-1, false)" fxLayout="column" fxLayoutAlign="center center">
                    <a href="/#/products/sale/page">SALE</a>
                    <div class="underline"></div>
                </div>
                <div class="parent" (mouseenter) = "enter(-1, false)" fxLayout="column" fxLayoutAlign="center center">
                    <a href="/#/blog/all">STORIES</a>
                    <div class="underline"></div>
                </div>
                <!-- <div class="parent" (mouseenter) = "enter(-1, false)" fxLayout="column" fxLayoutAlign="center center">
                    <a href="#">STORIES</a>
                    <div class="underline"></div>
                </div> -->
                <div *ngIf="showSubCategory" fxLayout="row" fxLayoutAlign="start" class="sub-category-container" (mouseleave) = "leave()">
                    <ng-container *ngFor="let menu of menuList[currentSubMenuIndex].children">
                        <div *ngIf="!menu.disabled" style="width: 30%;" fxLayout="column" fxLayoutAlign="start" class="sub-category">
                            <a class="sub-parent" [href]="'/#/products/' + menu.state" [innerHTML]="menu.name | translate"></a>
                            <ng-container *ngFor="let item of menu.children">
                                <a class="children" *ngIf="!item.disabled" [href]="'/#/products/' + item.state" >{{item.name | translate}}</a>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="end center" class="header-right">
                <div class="search" (click)="search()" fxLayout="row" fxLayoutAlign="center center">
                    <img class="location icon" src="assets/images/icon/search-icon.svg"/>
                    Search
                </div>
                <div class="account" fxLayout="column" fxLayoutAlign="center center"> 
                    <div fxLayout="column" fxLayoutAlign="center center">
                        <img class="location icon" src="assets/images/icon/my-account-icon.svg"/>
                        <div class="underline"></div>
                    </div>
                    <app-header-account-dropdown [user]="user" top="70px"></app-header-account-dropdown>
                </div>
                <div [ngClass]="{'showCart': showCart}" class="shopping-cart" fxLayout="column" fxLayoutAlign="center center">
                    <div class="image-container" fxLayout="row" fxLayoutAlign="center center">
                        <img (click)="gotoShoppingCart()" class="location icon" src="assets/images/icon/bag-icon.svg"/>
                        {{itemNum}}
                        <div class="underline"></div>
                    </div>
                    <app-header-shopping-cart-dropdown top="70px"></app-header-shopping-cart-dropdown>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="header-wrapper-mobile">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="header-container">
        <div class="header-left">
            <div (click)="showSideNavBar()" class="hamburger"></div>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center" class="header-center">
            <a href="/#/home">
                <img class="sticky-logo" src="assets/images/sticky-logo.svg"/>
            </a>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center" class="header-right">
            <div fxLayout="column" fxLayoutAlign="center center">
                <img (click)="search()" class="location icon" src="assets/images/icon/search-icon.svg"/>
            </div>
            <div class="account" fxLayout="column" fxLayoutAlign="center center">
                <a href="/#/posts/find-a-store">
                    <img class="location icon" src="assets/images/icon/location-navy.svg"/>
                </a>
            </div>
            <div class="shopping-cart" (click)="gotoShoppingCart()" fxLayout="row" fxLayoutAlign="center center">
                <img class="location icon" src="assets/images/icon/bag-icon.svg"/>
                {{itemNum}}
            </div>
        </div>
    </div>
</div>
