<div class="container-full-width">
    <div class="page-container">
        <div fxLayout="column" fxLayoutAlign="start start" class="contact-us-container">
            <h1>Contact Us</h1>
            <div class="info">Send us an email using the form below.</div>
            <form class="form-container" fxLayout="column" fxLayoutAlign="start start" [formGroup]="contactUsForm">
                <div fxLayout="row wrap" fxLayoutAlign="space-between" class="input-container">
                    <div class="w-49">
                        <app-input type="text" placeholder="First Name *" formControlName="firstname" [error]="checkError(contactUsForm, 'firstname', 'PLEASE ENTER A FIRST NAME')"></app-input>
                    </div>
                    <div class="w-49">
                        <app-input type="text" placeholder="Last Name *" formControlName="lastname" [error]="checkError(contactUsForm, 'lastname', 'PLEASE ENTER A LAST NAME')"></app-input>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="space-between" class="input-container">
                    <div class="w-49">
                        <app-input type="email" placeholder="Email Address *" formControlName="email" [error]="checkError(contactUsForm, 'email', 'PLEASE ENTER YOUR EMAIL ADDRESS')"></app-input>
                    </div>
                    <div class="w-49">
                        <app-input type="text" placeholder="Phone Number *" formControlName="phone" [error]="checkError(contactUsForm, 'phone', 'A VALID PHONE NUMBER IS REQUIRED.')"></app-input>
                    </div>
                </div>
                <app-input type="text" placeholder="Subject *" formControlName="subject" [error]="checkError(contactUsForm, 'subject', 'PLEASE ENTER A SUBJECT')"></app-input>
                <app-input type="text" placeholder="Order Number *" formControlName="orderNo"></app-input>
                <app-text-area placeholder="Your Message *" formControlName="message" [error]="checkError(contactUsForm, 'message', 'PLEASE ENTER A MESSAGE')"></app-text-area>
            </form>
            <div>For your protection, please do not include your credit card number when emailing Brooks Brothers.</div>
            <div class="button-container">
                <app-button (onClick)="submitContactUsForm()" text="send"></app-button>
            </div>
        </div>
    </div>
</div>
<app-simple-popup id="contact-us" title="Thank you">
    <div class="popup-content">
        <div>Your message has been sent to<br>Brooks Brothers!</div>
    </div>
</app-simple-popup>