export class ProductSkuEav
{
    id: number;
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    updatedBy: string;
    attribute: string;
    value: string;
    lang:string;


    /**
     * Constructor
     *
     * @param ShoppingCart
     */
    constructor(productSkuEavs?)
    {
        productSkuEavs = productSkuEavs || {};
        this.id = productSkuEavs.id || 0;
        this.createdAt = productSkuEavs.createdAt || '';
        this.createdBy = productSkuEavs.createdBy || '';
        this.updatedAt = productSkuEavs.updatedAt || '';
        this.updatedBy = productSkuEavs.attribute || '';
        this.value = productSkuEavs.value || '';
        this.lang = productSkuEavs.lang || '';
        this.attribute = productSkuEavs.attribute || '';

    }

}
