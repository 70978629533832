<div fxLayout="row" fxLayoutAlign="start center" class="quantity-button-container">
    <div [ngClass]="{'disabled': quantity === 1}" (click)="substract()" class="quantity-icon" fxLayout="column" fxLayoutAlign="center center">
        <svg id="quantity-subtract-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 4" role="presentation" aria-hidden="true" tabindex="-1" disabled="disabled" clickable="false"><polygon class="cls-1" points="10 4 0 4 0 0 10 0 14 0 24 0 24 4 14 4 10 4"></polygon></svg>
    </div>
    <div class="quantity" fxLayout="column" fxLayoutAlign="center center">
        <span>{{quantity}}</span>
    </div>
    <div (click)="add()" class="quantity-icon" fxLayout="column" fxLayoutAlign="center center">
        <svg id="quantity-add-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="presentation" aria-hidden="true" tabindex="-1" disabled="disabled" clickable="false"><polygon class="cls-1" points="0 14 0 10 10 10 10 0 14 0 14 10 24 10 24 14 14 14 14 24 10 24 10 14 0 14"></polygon></svg>
    </div>
</div>