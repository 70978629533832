import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import Slider from '@shared/models/Slider';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {

  @ContentChild(TemplateRef) templateRef: TemplateRef<any>;

  @Input() desktopSlider: Slider = new Slider()
  @Input() mobileSlider: Slider = new Slider()

  @Input() fixHeightMobile: string;

  @Output() onSlideChange = new EventEmitter<any>()

  currentSlide: number = 0; 

  @Input() slideConfig = {
    "arrows": false,
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "infinite": true,
    "speed": 500,
    "autoplay":true,
    "autoplaySpeed": 5000,
    "dots": false
  }

  slickInit(e) {
    this.currentSlide = e.slick.currentSlide
    this.onSlideChange.emit(this.desktopSlider.banners[e.slick.currentSlide])
  }

  breakpoint(e) {
  }

  afterChange(e) {
    this.currentSlide = e.slick.currentSlide
    this.onSlideChange.emit(this.desktopSlider.banners[e.currentSlide])
  }

  beforeChange(e) {
  }

  constructor() { }

  ngOnInit(): void {
    //Check mobile exist or not
    if(this.mobileSlider.banners && this.mobileSlider.banners.length > 0) {

    }else {
      this.mobileSlider = this.desktopSlider
    }
  }

}
